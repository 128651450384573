import React from "react";
import Section from "./../Section";
import SectionHeader from "./../SectionHeader";
import Testimonials from "./../Testimonials";
import "./styles.scss";


function TestimonialsSection(props) {
  return (
    <Section color={props.color} size={props.size}>
      <div className="container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          centered={true}
          size={3}
        />



        <Testimonials
          items={[
            {
              avatar: "https://cdn1.iconfinder.com/data/icons/social-messaging-productivity-1-1/128/gender-male2-512.png",
              name: "Edison C.",
              bio:
                "Synk has become an indispensible tool for our team. I can easily create and assign field routes for my associates and track the success of my sales process.",
              company: "Sales Manager"
            },
            {
              avatar: "https://cdn1.iconfinder.com/data/icons/social-messaging-productivity-1-1/128/gender-male2-512.png",
              name: "Elias B.",
              role: "",
              bio:
                "I love how Synk helps me complete my stops faster and notifies my customers when I'm nearby- I rarely have missed deliveries anymore.",
              company: "Driver"
            },
            {
              avatar: "https://cdn1.iconfinder.com/data/icons/social-messaging-productivity-1-1/128/gender-male2-512.png",
              name: "Eddie Z.",
              role: "",
              bio:
                "With Synk, I've been able to significantly increase my order size by allowing customers to discover my full catalog on their own time.",
              company: "Owner"
            }
          ]}
        />
      </div>
    </Section>
  );
}

export default TestimonialsSection;
