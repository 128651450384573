import React from "react";
import ContentSection from "./../../components/ContentSection";
import FeaturesSectionFull from "./../../components/FeaturesSectionFull";
import { Link } from "./../../util/router.js";
import "./styles.scss";
// import CSVReader from 'react-csv-reader';
// import Dropzone from 'react-dropzone';
import MdSearch from 'react-ionicons/lib/MdSearch';
//import { CSVLink, CSVDownload } from "react-csv";
import firebase from 'firebase';
//function CatalogAdmin(props) {
class CatalogAdmin extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      errorMessage:'',
      dateValue:'',
      searchLimitValue:25,
      dateFormatted:'',
      companyId:'',
      searchTerm:'',
      query: "",
      data: [],
      filteredData: [],
      totalData:[],
    };
  }


  componentDidMount(){

    var today = new Date();
    var start = new Date();
    start.setHours(0,0,0,0);

    var end = new Date();
    end.setHours(23,59,59,999);

    var todayStartValue = Math.floor(start / 1000);
    var todayEndValue = Math.floor(end / 1000);


    var dd = today.getDate();
    var mm = today.getMonth()+1;
    var yyyy = today.getFullYear();
    if(dd<10){
        dd='0'+dd;
    }
    if(mm<10){
        mm='0'+mm;
    }
    var dateValue = yyyy.toString() + mm.toString() + dd.toString();
    var dateVal = yyyy.toString() + mm.toString();
    var dateToday = mm.toString() + '/' + dd.toString() + '/' + yyyy.toString();

    this.setState({dateValue:dateValue});
    this.setState({dateFormatted:dateToday});


    var setUser = '';
    firebase.auth().onAuthStateChanged((user) => {
      if (user){
        this.setState({isSignedIn:true});
        this.setState({email:user.email});

        this.getCompanyData()
        console.log(user);
      } else {
        this.setState({isSignedIn:false});

      }
    });

  }


  getCompanyData(){

    var user = firebase.auth().currentUser;
    console.log("OPEN USER " + user);

    if (user){
    firebase.database().ref('users/' + user.uid).once('value', (dataSnapshot) => {
        console.log("datasnap " + JSON.stringify(dataSnapshot));
        if (dataSnapshot.val().companyId!==undefined){
          this.setState({companyId: dataSnapshot.val().companyId});
        }
    }).then(() => {

        if (this.state.companyId!==undefined && this.state.companyId.trim()!==""){
          console.log("this.state.companyId " + this.state.companyId);
          var data = [];
           firebase.database().ref('company/' + this.state.companyId + '/catalog/').once('value', (snap) => {
             snap.forEach((item) => {

               // var itemName = 'undefined';
               // if (item.hasChild("itemName")){ itemName = item.val().itemName; }
               //
               // var casePrice = 'undefined';
               // if (item.hasChild("casePrice")){ casePrice = '$' + item.val().casePrice.toString(); }
               //
               // var caseSize = 'undefined'
               // if (item.hasChild("caseSize")){ caseSize = item.val().caseSize; }
               //
               // var isPiece = false;
               // if (item.hasChild("isPiece")){ isPiece = item.val().isPiece; }

               var itemId = item.key.toString();
               var itemName = item.val().itemName.toString();
               var caseCost = item.val().caseCost.toString();
               var casePrice = item.val().casePrice.toString();
               var caseSize = item.val().caseSize.toString();
               var isPiece = item.val().isPiece.toString().toUpperCase();
               var isHidden = item.val().isHidden.toString().toUpperCase();
               var hasPromo = item.val().hasPromo.toString().toUpperCase();
               var promoPrice = '';
               if (item.hasChild("promoPrice")){ promoPrice = item.val().promoPrice.toString()}
               var caseHeight = '';
               if (item.hasChild("caseHeight")){ caseHeight = item.val().caseHeight.toString()}
               var caseDepth = '';
               if (item.hasChild("caseDepth")){ caseDepth = item.val().caseDepth.toString()}
               var caseWidth = '';
               if (item.hasChild("caseWidth")){ caseWidth = item.val().caseWidth.toString()}
               var itemAisle = '';
               if (item.hasChild("itemAisle")){ itemAisle = item.val().itemAisle.toString()}
               var itemBrand = '';
               if (item.hasChild("itemBrand")){ itemBrand = item.val().itemBrand.toString()}
               var itemCategory = '';
               if (item.hasChild("itemCategory")){ itemCategory = item.val().itemCategory.toString()}
               var itemDescription = '';
               if (item.hasChild("itemDescription")){ itemDescription = item.val().itemDescription.toString()}
               var itemImage = '';
               if (item.hasChild("itemImage")){ itemImage = item.val().itemImage.toString()}
               var itemSupplier = '';
               if (item.hasChild("itemSupplier")){ itemSupplier = item.val().itemSupplier.toString()}
               var itemWeight = '';
               if (item.hasChild("itemWeight")){ itemWeight = item.val().itemWeight.toString()}
               var keywords = '';
               if (item.hasChild("keywords")){ keywords = item.val().keywords.toString()}
               var palletSize = '';
               if (item.hasChild("palletSize")){ palletSize = item.val().palletSize.toString()}
               var tierSize = '';
               if (item.hasChild("tierSize")){ tierSize = item.val().tierSize.toString()}
               var sortId = '';
               if (item.hasChild("sortId")){ sortId = item.val().sortId.toString()}
               var supplierItemNumber = '';
               if (item.hasChild("supplierItemNumber")){ supplierItemNumber = item.val().supplierItemNumber.toString()}
               var upc = '';
               if (item.hasChild("upc")){ upc = item.val().upc.toString()}
               var upcType = '';
               if (item.hasChild("upcType")){ upcType = item.val().upcType.toString()}


               data.push({
                 key:itemId,
                 itemId:itemId,
                 itemName:itemName,
                 casePrice:casePrice,
                 caseSize:caseSize,
                 caseCost:caseCost,
                 isPiece:isPiece,
                 isHidden:isHidden,
                 hasPromo:hasPromo,
                 promoPrice:promoPrice,
                 caseHeight:caseHeight,
                 caseWidth:caseWidth,
                 caseDepth:caseDepth,
                 itemAisle:itemAisle,
                 itemBrand:itemBrand,
                 itemCategory:itemCategory,
                 itemDescription:itemDescription,
                 itemImage:itemImage,
                 itemSupplier:itemSupplier,
                 itemWeight:itemWeight,
                 keywords:keywords,
                 palletSize:palletSize,
                 tierSize:tierSize,
                 sortId:sortId,
                 supplierItemNumber:supplierItemNumber,
                 upc:upc,
                 upcType:upcType,


               });



             });

             this.setState({totalData:data});

             const { query } = this.state;
             const filteredData = data.filter(element => {
               // if (element.email.toLowerCase().includes(query.toLowerCase())){
               //   return element.email.toLowerCase().includes(query.toLowerCase());
               // } else if (element.name.toLowerCase().includes(query.toLowerCase())){
                 return element.itemName.toLowerCase().includes(query.toLowerCase())
               //}


             });

             this.setState({
               data,
               filteredData
             });

           });
        }
      });
    }
  }

  // search = (searchText) => {
  //   this.setState({searchTerm: searchText});
  //
  //   let filteredData = this.state.totalData.filter(function (item) {
  //     return item.email.toLowerCase().includes(searchText.toLowerCase());
  //   });
  //
  //
  //   console.log("FILTERED DATA " + JSON.stringify(filteredData));
  //   this.setState({filteredData: filteredData});
  //
  //   //this._updateFlatLists(filteredData);
  //
  // }


  handleInputChange = event => {
     const query = event.target.value;

     this.setState(prevState => {
       const filteredData = prevState.data.filter(element => {
         //return element.name.toLowerCase().includes(query.toLowerCase());
         if (element.itemName.toLowerCase().includes(query.toLowerCase())){
           return element.itemName.toLowerCase().includes(query.toLowerCase());
         } else if (element.itemId.toLowerCase().includes(query.toLowerCase())){
           return element.itemId.toLowerCase().includes(query.toLowerCase())
         }
         else if (element.casePrice.toLowerCase().includes(query.toLowerCase())){
           return element.casePrice.toLowerCase().includes(query.toLowerCase())
         }
         else if (element.caseSize.toLowerCase().includes(query.toLowerCase())){
           return element.caseSize.toLowerCase().includes(query.toLowerCase())
         }

       });

       return {
         query,
         filteredData
       };
     });
   };

  render(){



    return (



        <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
          <p style={{margin:'20px', fontSize:'30px', fontWeight:'bold', }}>Catalog Admin</p>

          <div  className="searchForm">
             <form  style={{display:'flex',  width:'1200px', marginBottom:'10px', marginTop:'10px', backgroundColor:'#eee', flexDirection:'row', justifyContent:'flex-start', borderRadius:'5px'}}>
              <MdSearch style={{justifyContent:'center', alignSelf:'center', alignItems:'center', margin:'5px'}} fontSize="25px" color="gray" />
               <input
                 id="searchInput"
                 style={{backgroundColor:'#eee', borderWidth:'0px', fontSize:'16px', padding:'5px', width:'1000px'}}
                 autocomplete="off"
                 placeholder="Search"
                 value={this.state.query}
                 onChange={this.handleInputChange}
               />
             </form>


             <div style={{padding:'5px', width:'1200px'}}>
             <div style={{display:'flex', height:'30px', flexDirection:'row', alignItems:'center', marginTop:'20px', textDecoration:'none', backgroundColor:'none', color:'black'}}>
              <p style={{marginLeft:'50px', width:'300px', fontWeight:'bold', color:'black'}}>Name</p><p style={{ marginLeft:'50px', width:'200px', fontWeight:'bold', color:'black'}}>Case Size</p><p style={{marginLeft:'50px', width:'200px', fontWeight:'bold', color:'black'}}>Price</p><p style={{marginLeft:'50px', width:'200px', fontWeight:'bold', color:'black'}}>Item ID</p>
              </div>
              </div>

             <div style={{padding:'5px', width:'1200px'}}>{this.state.filteredData.slice(0, this.state.searchLimitValue).map(i =>
               <Link to={{pathname:"/catalogAdminItem", state:{item:i} }}   >
               <a>

                {(i.isPiece === true) &&
                  <div id="userLink" style={{display:'flex', height:'30px', flexDirection:'row', alignItems:'center', marginTop:'20px', textDecoration:'none', backgroundColor:'none', color:'black'}}>
                    <p style={{marginLeft:'50px', width:'300px'}}>{i.itemName}</p><p style={{ marginLeft:'50px', width:'200px'}}>{i.caseSize}</p>
                    <div style={{marginLeft:'50px', width:'200px', display:'flex', flexDirection:'row', justifyContent:'flex-start'}}>
                    <p>{i.casePrice}</p><p style={{marginLeft:'5px',fontsize:'10px', color:'gray'}}> / 1</p></div>
                    <p style={{marginLeft:'50px', width:'200px'}}>{i.itemId}</p>
                  </div>
                }
                {(i.isPiece !== true) &&
                  <div id="userLink" style={{display:'flex', height:'30px', flexDirection:'row', alignItems:'center', marginTop:'20px', textDecoration:'none', backgroundColor:'none', color:'black'}}>
                    <p style={{marginLeft:'50px', width:'300px'}}>{i.itemName}</p><p style={{ marginLeft:'50px', width:'200px'}}>{i.caseSize}</p>
                    <div style={{marginLeft:'50px', width:'200px', display:'flex', flexDirection:'row', justifyContent:'flex-start'}}>
                    <p>{i.casePrice}</p><p style={{marginLeft:'5px',fontsize:'10px', color:'gray'}}> / {i.caseSize}</p></div>
                    <p style={{marginLeft:'50px', width:'200px'}}>{i.itemId}</p>
                  </div>
                }

                </a></Link>)}
                </div>

           </div>




        </div>


    );
  }

}

export default CatalogAdmin;
