import React from "react";
import Section from "./../Section";
import SectionHeader from "./../SectionHeader";
import Pricing from "./../Pricing";
import { useRouter } from "./../../util/router.js";
import "./styles.scss";

function PricingSection(props) {

  const router = useRouter();


  return (
    <Section color={props.color} size={props.size} id="pricing">
      <div className="PricingSection__container container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          centered={true}
          size={3}
        />
        <Pricing
          buttonText="Get Started"
          onChoosePlan={planId => {
            router.push("/signup");
          }}
          items={[
            {
              id: "free",
              timespan: "CRM",
              price: "FREE FOREVER",
              description:
                "Stop paying for CRMs! Get our business development and sales tools for free + unlimited use."
            },
            {
              id: "monthly",
              timespan: "ECOMMERCE",
              price: "0.25%",
              description:
                "Instantly boost your sales by 20% with mobile ordering. Guaranteed to pay for itself or it's free."
            }

          ]}
        />
      </div>
    </Section>
  );
}

export default PricingSection;
