import React from "react";
import Section from "./../Section";
import { Link } from "./../../util/router.js";
import "./styles.scss";
//import LogoSlack from 'react-ionicons/lib/LogoSlack';
import LogoWhatsapp from 'react-ionicons/lib/LogoWhatsapp';
import LogoLinkedin from 'react-ionicons/lib/LogoLinkedin';
import LogoAndroid from 'react-ionicons/lib/LogoAndroid';
import LogoApple from 'react-ionicons/lib/LogoApple';

function Footer(props) {
  return (
    <Section color={props.color} size={props.size} style={{backgroundColor:'white'}}>
      <div className="FooterComponent__container container">
        <div className="brand left">
          <Link to="/">
            <img
            //src={props.logo}
            src={"https://firebasestorage.googleapis.com/v0/b/osmo-a9de7.appspot.com/o/appGraphics%2FGroup.svg?alt=media&token=1b8acb8f-584e-4320-bd20-eaef567cf3a9"}
            alt="Logo" />
          </Link>
        </div>
        <div className="links right">
          <Link to="/about">About</Link>
          <Link to="/faq">FAQ</Link>
          <Link to="/contact">Contact</Link>
        </div>
        <div className="social right">



<a
  href="https://play.google.com/store/apps/details?id=app.synk.www"
  target="_blank"
  rel="noopener noreferrer"
>
    <LogoAndroid fontSize="35px" color="#000" />
</a>

<a
  href="https://apps.apple.com/us/app/synk-mobile-commerce/id1443651578?ls=1"
  target="_blank"
  rel="noopener noreferrer"
>
    <LogoApple fontSize="35px" color="#000" />
</a>




        </div>
        <div className="copyright left">{"© 2019 Exparent, Inc."}</div>
      </div>
    </Section>
  );
}

export default Footer;




/*
<a
  href="https://twitter.com"
  target="_blank"
  rel="noopener noreferrer"
>
  <span className="icon">
    <i className="fab fa-twitter" />
  </span>
</a>

<a
  href="https://facebook.com"
  target="_blank"
  rel="noopener noreferrer"
>
  <span className="icon">
    <i className="fab fa-facebook-f" />
  </span>
</a>


<a
  href="https://linkedin.com"
  target="_blank"
  rel="noopener noreferrer"
>
  <span className="icon">
    <i className="fab fa-linkedin" />
  </span>
</a>
*/
