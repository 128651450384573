import React from "react";
import ContentSection from "./../../components/ContentSection";
import FeaturesSectionFull from "./../../components/FeaturesSectionFull";
import "./styles.scss";
import DatePicker from "react-datepicker";
// import CSVReader from 'react-csv-reader';
// import Dropzone from 'react-dropzone';
import MdSearch from 'react-ionicons/lib/MdSearch';
//import TextField from '@material-ui/core/TextField';
//import Button from '@material-ui/core/Button';

//import { CSVLink, CSVDownload } from "react-csv";
import firebase from 'firebase';
//function CatalogAdmin(props) {
class ApprovalAdminItem extends React.Component {

  constructor(props){
    super(props);


    this.state = {
      errorMessage:'',
      searchLimitValue:25,
      dateValue:'',
      dateFormatted:'',
      companyId:'',
      searchTerm:'',
      query: "",
      data: [],
      filteredData: [],
      totalData:[],
      name:'',
      phone:'',
      group:'',
      routeName:'',
      item:[],
      status:'',
      newCatalogSize:'',
      oldCatalogSize:'',
      changeData:[],
      addedData:[]
    };
  }


  componentDidMount(){

    let item = this.props.location.state.item;

    let catalog = this.props.location.state.item.catalog;
    //console.log("catalog  " + JSON.stringify(catalog) + " catalog");
    //console.log("catalog  " + JSON.stringify(catalog) + " catalog");
    this.setState({newCatalogSize:catalog.length});
    console.log("catalog size " + catalog.length);

    // let name = this.props.location.state.name;
    // let phone = this.props.location.state.phone;

    this.setState({item:item});
    this.setState({status:item.status});
    // this.setState({phone:phone});
    // this.setState({group:group});


    var today = new Date();
    this.setState({selectedDate:today});
    var start = new Date();
    start.setHours(0,0,0,0);

    var end = new Date();
    end.setHours(23,59,59,999);

    var todayStartValue = Math.floor(start / 1000);
    var todayEndValue = Math.floor(end / 1000);


    var dd = today.getDate();
    var mm = today.getMonth()+1;
    var yyyy = today.getFullYear();
    if(dd<10){
        dd='0'+dd;
    }
    if(mm<10){
        mm='0'+mm;
    }
    var dateValue = yyyy.toString() + mm.toString() + dd.toString();
    var dateVal = yyyy.toString() + mm.toString();
    var dateToday = mm.toString() + '/' + dd.toString() + '/' + yyyy.toString();

    this.setState({dateValue:dateValue});
    this.setState({dateFormatted:dateToday});


    var setUser = '';
    firebase.auth().onAuthStateChanged((user) => {
      if (user){
        this.setState({isSignedIn:true});
        this.setState({email:user.email});

        this.getCompanyData()
        console.log(user);
      } else {
        this.setState({isSignedIn:false});

      }
    });

  }



  getCompanyData(){

    var user = firebase.auth().currentUser;
    console.log("OPEN USER " + user);

    if (user){
    firebase.database().ref('users/' + user.uid).once('value', (dataSnapshot) => {
        //console.log("datasnap " + JSON.stringify(dataSnapshot));
        if (dataSnapshot.val().companyId!==undefined){
          this.setState({companyId: dataSnapshot.val().companyId});
        }
    }).then(() => {

        if (this.state.companyId!==undefined && this.state.companyId.trim()!==""){
          console.log("this.state.companyId " + this.state.companyId);
          var data = [];
          if (this.state.item.action === "New Items"){
            this.listNewItems();
          } else {
            this.compareCatalogs();
          }

        }


      });
    }
  }

  updateCatalog(){
    if (this.state.item.action === "New Items"){

      let catalog = this.props.location.state.item.catalog;

      console.log("new items update");
      for (let position in catalog) {

        for (let key in catalog[position]){
          if ( key.toString().trim()!=="" && key!==undefined && key!==null){
              var isPiece = false;
              var hasPromo = false;
              var isHidden = false;
              if (catalog[position][key]["isPiece"].toString().toUpperCase() === "TRUE"){
                isPiece = true;
              }
              if (catalog[position][key]["hasPromo"].toString().toUpperCase() === "TRUE"){
                hasPromo = true;
              }
              if (catalog[position][key]["isHidden"].toString().toUpperCase() === "TRUE"){
                isHidden = true;
              }
              //&& key.toString()==="1220205"
              //if (catalog[position][key]["itemCategory"].toUpperCase()==="WATER"){

                firebase.database().ref('company/' + this.state.companyId + '/catalog/' + key).update({
                  caseCost:catalog[position][key]["caseCost"],
                  caseDepth:catalog[position][key]["caseDepth"],
                  caseHeight:catalog[position][key]["caseHeight"],
                  casePrice:catalog[position][key]["casePrice"],
                  caseSize:catalog[position][key]["caseSize"],
                  caseWidth:catalog[position][key]["caseWidth"],
                  hasPromo:hasPromo,
                  isHidden:isHidden,
                  isPiece:isPiece,
                  itemAisle:catalog[position][key]["itemAisle"],
                  itemBrand:catalog[position][key]["itemBrand"],
                  itemCategory:catalog[position][key]["itemCategory"],
                  itemDescription:catalog[position][key]["itemDescription"],
                  itemImage:catalog[position][key]["itemImage"],
                  itemInventory:"0",
                  itemName:catalog[position][key]["itemName"],
                  itemSupplier:catalog[position][key]["itemSupplier"],
                  itemWeight:catalog[position][key]["itemWeight"],
                  keywords:catalog[position][key]["keywords"],
                  palletSize:catalog[position][key]["palletSize"],
                  promoPrice:catalog[position][key]["promoPrice"],
                  sortId:catalog[position][key]["sortId"],
                  supplierItemNumber:catalog[position][key]["supplierItemNumber"],
                  tierSize:catalog[position][key]["tierSize"],
                  upc:catalog[position][key]["upc"],
                  upcType:catalog[position][key]["upcType"],

                }).then(() => {
                    // loading finished when count matches total
                });

                firebase.database().ref('company/' + this.state.companyId + '/inventory/' + key).update({
                  caseCost:catalog[position][key]["caseCost"],
                  caseDepth:catalog[position][key]["caseDepth"],
                  caseHeight:catalog[position][key]["caseHeight"],
                  casePrice:catalog[position][key]["casePrice"],
                  caseSize:catalog[position][key]["caseSize"],
                  caseWidth:catalog[position][key]["caseWidth"],
                  hasPromo:hasPromo,
                  isHidden:isHidden,
                  isPiece:isPiece,
                  itemAisle:catalog[position][key]["itemAisle"],
                  itemBrand:catalog[position][key]["itemBrand"],
                  itemCategory:catalog[position][key]["itemCategory"],
                  itemDescription:catalog[position][key]["itemDescription"],
                  itemImage:catalog[position][key]["itemImage"],
                  itemInventory:"0",
                  itemName:catalog[position][key]["itemName"],
                  itemSupplier:catalog[position][key]["itemSupplier"],
                  itemWeight:catalog[position][key]["itemWeight"],
                  keywords:catalog[position][key]["keywords"],
                  palletSize:catalog[position][key]["palletSize"],
                  promoPrice:catalog[position][key]["promoPrice"],
                  sortId:catalog[position][key]["sortId"],
                  supplierItemNumber:catalog[position][key]["supplierItemNumber"],
                  tierSize:catalog[position][key]["tierSize"],
                  upc:catalog[position][key]["upc"],
                  upcType:catalog[position][key]["upcType"],

                });
              //}


          }



      }
     }

    }
  }

  listNewItems(){
    let catalog = this.props.location.state.item.catalog;
    var addedData = []
    //var count = 0;
    console.log("new items push");
    for (let position in catalog) {
        console.log("position " + position)
      for (let key in catalog[position]){
        //console.log(" key " + JSON.stringify(catalog[position][key]) );
        //console.log(" caseCost " + JSON.stringify(catalog[position][key]["caseCost"]) );
        //if (position<10){

          console.log("key " + key)
          firebase.database().ref('company/' + this.state.companyId + '/catalog/' + key +'/itemName').once('value', (snap) => {


            if (snap.val()!==null){
              console.log("existing snap.val() " + snap.val());
              //console.log("BAD NOT UNDEFINED " + key);
              //alert("Upload failed on existing ID " + key + ". Please change this ID so it is a unique number");
              // this.state.addedData.push({
              //   key:key.toString(),
              //   itemId:key,
              //   itemName:catalog[position][key]["itemName"],
              //   casePrice:catalog[position][key]["casePrice"],
              //   caseCost:catalog[position][key]["caseCost"],
              //   caseSize:catalog[position][key]["caseSize"],
              //   isPiece:catalog[position][key]["isPiece"],
              //   upc:catalog[position][key]["upc"],
              //   category:catalog[position][key]["itemCategory"],
              // });

            } else {
              console.log("absent snap.val() " + snap.val());
              //console.log("correct - undefined");
              this.state.addedData.push({
                key:key.toString(),
                itemId:key,
                itemName:catalog[position][key]["itemName"],
                casePrice:catalog[position][key]["casePrice"],
                caseCost:catalog[position][key]["caseCost"],
                caseSize:catalog[position][key]["caseSize"],
                isPiece:catalog[position][key]["isPiece"],
                upc:catalog[position][key]["upc"],
                category:catalog[position][key]["itemCategory"],
              });
            }
            //console.log("finished processing " + catalog[position][key]["itemName"]);
            this.setState({addedData:addedData});
          });
        }
      }

  }




  compareCatalogs(){
    let catalog = this.props.location.state.item.catalog;
    var changeData = []
    if (this.state.companyId!==undefined && this.state.companyId.trim()!==""){
      // catalog.forEach((item) => {
      //   console.log("item.val().itemName " + item.itemName);
      // })
      if (this.props.location.state.item.action === "Update Items"){


      for (let position in catalog) {

        for (let key in catalog[position]){
          //console.log(" key " + JSON.stringify(catalog[position][key]) );
          //console.log(" caseCost " + JSON.stringify(catalog[position][key]["caseCost"]) );
          //if (position<10){
            firebase.database().ref('company/' + this.state.companyId + '/catalog/' + key).once('value', (snap) => {

              // csvSample:[ ["itemId", "itemName", "caseCost", "casePrice", "caseSize", "isPiece", "isHidden", "hasPromo", "promoPrice",
              //  "caseHeight", "caseDepth", "caseWidth", "itemAisle", "itemBrand", "itemCategory", "itemDescription", "itemImage", "itemSupplier", "itemWeight",
              //  "keywords", "palletSize", "tierSize", "sortId", "supplierItemNumber", "upc", "upcType" ] ];


              var itemName = '';
              if(snap.hasChild("itemName")){
                itemName = snap.val().itemName.toString();
              }
              if (itemName === catalog[position][key]["itemName"] ) { console.log("CASE SIZE MATCH"); }
              else {
                this.state.changeData.push({
                  key:key.toString() + "itemName",
                  itemId:key,
                  itemName:catalog[position][key]["itemName"],
                  field:"itemName",
                  oldData:itemName,
                  newData:catalog[position][key]["itemName"],
                });
              }

              var caseCost = '';
              if(snap.hasChild("caseCost")){
                caseCost = snap.val().caseCost.toString();
              }
              if (caseCost === catalog[position][key]["caseCost"] ) { console.log("CASE SIZE MATCH"); }
              else {
                this.state.changeData.push({
                  key:key.toString() + "caseCost",
                  itemId:key,
                  itemName:catalog[position][key]["itemName"],
                  field:"caseCost",
                  oldData:caseCost,
                  newData:catalog[position][key]["caseCost"],
                });
              }

              var casePrice = '';
              if(snap.hasChild("casePrice")){
                casePrice = snap.val().casePrice.toString();
              }
              if (casePrice === catalog[position][key]["casePrice"] ) { console.log("CASE SIZE MATCH"); }
              else {
                this.state.changeData.push({
                  key:key.toString() + "casePrice",
                  itemId:key,
                  itemName:catalog[position][key]["itemName"],
                  field:"casePrice",
                  oldData:casePrice,
                  newData:catalog[position][key]["casePrice"],
                });
              }


              var caseSize = '';
              if(snap.hasChild("caseSize")){
                caseSize = snap.val().caseSize.toString();
              }
              if (caseSize === catalog[position][key]["caseSize"] ) { console.log("CASE SIZE MATCH"); }
              else {
                this.state.changeData.push({
                  key:key.toString() + "caseSize",
                  itemId:key,
                  itemName:catalog[position][key]["itemName"],
                  field:"caseSize",
                  oldData:caseSize,
                  newData:catalog[position][key]["caseSize"],
                });
              }

              var isPiece = '';
              if(snap.hasChild("isPiece")){
                isPiece = snap.val().isPiece.toString().toUpperCase();
              }
              if (isPiece === catalog[position][key]["isPiece"].toUpperCase() ) { console.log("CASE SIZE MATCH"); }
              else {
                this.state.changeData.push({
                  key:key.toString() + "isPiece",
                  itemId:key,
                  itemName:catalog[position][key]["itemName"],
                  field:"isPiece",
                  oldData:isPiece,
                  newData:catalog[position][key]["isPiece"],
                });
              }

              var isHidden = '';
              if(snap.hasChild("isHidden")){
                isHidden = snap.val().isHidden.toString().toUpperCase();
              }
              if (isHidden === catalog[position][key]["isHidden"].toUpperCase() ) { console.log("CASE SIZE MATCH"); }
              else {
                this.state.changeData.push({
                  key:key.toString() + "isHidden",
                  itemId:key,
                  itemName:catalog[position][key]["itemName"],
                  field:"isHidden",
                  oldData:isHidden,
                  newData:catalog[position][key]["isHidden"],
                });
              }

              var hasPromo = '';
              if(snap.hasChild("hasPromo")){
                hasPromo = snap.val().hasPromo.toString().toUpperCase();
              }
              if (hasPromo === catalog[position][key]["hasPromo"].toUpperCase() ) { console.log("CASE SIZE MATCH"); }
              else {
                this.state.changeData.push({
                  key:key.toString() + "hasPromo",
                  itemId:key,
                  itemName:catalog[position][key]["itemName"],
                  field:"hasPromo",
                  oldData:hasPromo,
                  newData:catalog[position][key]["hasPromo"],
                });
              }


              var promoPrice = '';
              if(snap.hasChild("promoPrice")){
                promoPrice = snap.val().promoPrice.toString();
              }
              if (promoPrice === catalog[position][key]["promoPrice"] ) { console.log("CASE SIZE MATCH"); }
              else {
                this.state.changeData.push({
                  key:key.toString() + "promoPrice",
                  itemId:key,
                  itemName:catalog[position][key]["itemName"],
                  field:"promoPrice",
                  oldData:promoPrice,
                  newData:catalog[position][key]["promoPrice"],
                });
              }

              var caseHeight = '';
              if(snap.hasChild("caseHeight")){
                caseHeight = snap.val().caseHeight.toString();
              }
              if (caseHeight === catalog[position][key]["caseHeight"] ) { console.log("CASE SIZE MATCH"); }
              else {
                this.state.changeData.push({
                  key:key.toString() + "caseHeight",
                  itemId:key,
                  itemName:catalog[position][key]["itemName"],
                  field:"caseHeight",
                  oldData:caseHeight,
                  newData:catalog[position][key]["caseHeight"],
                });
              }


              var caseWidth = '';
              if(snap.hasChild("caseWidth")){
                caseWidth = snap.val().caseWidth.toString();
              }
              if (caseWidth === catalog[position][key]["caseWidth"] ) { console.log("CASE SIZE MATCH"); }
              else {
                this.state.changeData.push({
                  key:key.toString() + "caseWidth",
                  itemId:key,
                  itemName:catalog[position][key]["itemName"],
                  field:"caseWidth",
                  oldData:caseWidth,
                  newData:catalog[position][key]["caseWidth"],
                });
              }


              var caseDepth = '';
              if(snap.hasChild("caseDepth")){
                caseDepth = snap.val().caseDepth.toString();
              }
              if (caseDepth === catalog[position][key]["caseDepth"] ) { console.log("CASE SIZE MATCH"); }
              else {
                this.state.changeData.push({
                  key:key.toString() + "caseDepth",
                  itemId:key,
                  itemName:catalog[position][key]["itemName"],
                  field:"caseDepth",
                  oldData:caseDepth,
                  newData:catalog[position][key]["caseDepth"],
                });
              }

              var itemAisle = '';
              if(snap.hasChild("itemAisle")){
                itemAisle = snap.val().itemAisle.toString();
              }
              if (itemAisle === catalog[position][key]["itemAisle"] ) { console.log("CASE SIZE MATCH"); }
              else {
                this.state.changeData.push({
                  key:key.toString() + "itemAisle",
                  itemId:key,
                  itemName:catalog[position][key]["itemName"],
                  field:"itemAisle",
                  oldData:itemAisle,
                  newData:catalog[position][key]["itemAisle"],
                });
              }

              var itemBrand = '';
              if(snap.hasChild("itemBrand")){
                itemBrand = snap.val().itemBrand.toString();
              }
              if (itemBrand === catalog[position][key]["itemBrand"] ) { console.log("CASE SIZE MATCH"); }
              else {
                this.state.changeData.push({
                  key:key.toString() + "itemBrand",
                  itemId:key,
                  itemName:catalog[position][key]["itemName"],
                  field:"itemBrand",
                  oldData:itemBrand,
                  newData:catalog[position][key]["itemBrand"],
                });
              }

              var itemCategory = '';
              if(snap.hasChild("itemCategory")){
                itemCategory = snap.val().itemCategory.toString();
              }
              if (itemCategory === catalog[position][key]["itemCategory"] ) { console.log("CASE SIZE MATCH"); }
              else {
                this.state.changeData.push({
                  key:key.toString() + "itemCategory",
                  itemId:key,
                  itemName:catalog[position][key]["itemName"],
                  field:"itemCategory",
                  oldData:itemCategory,
                  newData:catalog[position][key]["itemCategory"],
                });
              }

              var itemDescription = '';
              if(snap.hasChild("itemDescription")){
                itemDescription = snap.val().itemDescription.toString();
              }
              if (itemDescription === catalog[position][key]["itemDescription"] ) { console.log("CASE SIZE MATCH"); }
              else {
                this.state.changeData.push({
                  key:key.toString() + "itemDescription",
                  itemId:key,
                  itemName:catalog[position][key]["itemName"],
                  field:"itemDescription",
                  oldData:itemDescription,
                  newData:catalog[position][key]["itemDescription"],
                });
              }

              var itemImage = '';
              if(snap.hasChild("itemImage")){
                itemImage = snap.val().itemImage.toString();
              }
              if (itemImage === catalog[position][key]["itemImage"] ) { console.log("CASE SIZE MATCH"); }
              else {
                this.state.changeData.push({
                  key:key.toString() + "itemImage",
                  itemId:key,
                  itemName:catalog[position][key]["itemName"],
                  field:"itemImage",
                  oldData:itemImage,
                  newData:catalog[position][key]["itemImage"],
                });
              }

              var itemSupplier = '';
              if(snap.hasChild("itemSupplier")){
                itemSupplier = snap.val().itemSupplier.toString();
              }
              if (itemSupplier === catalog[position][key]["itemSupplier"] ) { console.log("CASE SIZE MATCH"); }
              else {
                this.state.changeData.push({
                  key:key.toString() + "itemSupplier",
                  itemId:key,
                  itemName:catalog[position][key]["itemName"],
                  field:"itemSupplier",
                  oldData:itemSupplier,
                  newData:catalog[position][key]["itemSupplier"],
                });
              }

              var itemWeight = '';
              if(snap.hasChild("itemWeight")){
                itemWeight = snap.val().itemWeight.toString();
              }
              if (itemWeight === catalog[position][key]["itemWeight"] ) { console.log("CASE SIZE MATCH"); }
              else {
                this.state.changeData.push({
                  key:key.toString() + "itemWeight",
                  itemId:key,
                  itemName:catalog[position][key]["itemName"],
                  field:"itemWeight",
                  oldData:itemWeight,
                  newData:catalog[position][key]["itemWeight"],
                });
              }

              var keywords = '';
              if(snap.hasChild("keywords")){
                keywords = snap.val().keywords.toString();
              }
              if (keywords === catalog[position][key]["keywords"] ) { console.log("CASE SIZE MATCH"); }
              else {
                this.state.changeData.push({
                  key:key.toString() + "keywords",
                  itemId:key,
                  itemName:catalog[position][key]["itemName"],
                  field:"keywords",
                  oldData:keywords,
                  newData:catalog[position][key]["keywords"],
                });
              }

              var palletSize = '';
              if(snap.hasChild("palletSize")){
                palletSize = snap.val().palletSize.toString();
              }
              if (palletSize === catalog[position][key]["palletSize"] ) { console.log("CASE SIZE MATCH"); }
              else {
                this.state.changeData.push({
                  key:key.toString() + "palletSize",
                  itemId:key,
                  itemName:catalog[position][key]["itemName"],
                  field:"palletSize",
                  oldData:palletSize,
                  newData:catalog[position][key]["palletSize"],
                });
              }

              var tierSize = '';
              if(snap.hasChild("tierSize")){
                tierSize = snap.val().tierSize.toString();
              }
              if (tierSize === catalog[position][key]["tierSize"] ) { console.log("CASE SIZE MATCH"); }
              else {
                this.state.changeData.push({
                  key:key.toString() + "tierSize",
                  itemId:key,
                  itemName:catalog[position][key]["itemName"],
                  field:"tierSize",
                  oldData:tierSize,
                  newData:catalog[position][key]["tierSize"],
                });
              }

              var sortId = '';
              if(snap.hasChild("sortId")){
                sortId = snap.val().sortId.toString();
              }
              if (sortId === catalog[position][key]["sortId"] ) { console.log("CASE SIZE MATCH"); }
              else {
                this.state.changeData.push({
                  key:key.toString() + "sortId",
                  itemId:key,
                  itemName:catalog[position][key]["itemName"],
                  field:"sortId",
                  oldData:sortId,
                  newData:catalog[position][key]["sortId"],
                });
              }

              var supplierItemNumber = '';
              if(snap.hasChild("supplierItemNumber")){
                supplierItemNumber = snap.val().supplierItemNumber.toString();
              }
              if (supplierItemNumber === catalog[position][key]["supplierItemNumber"] ) { console.log("CASE SIZE MATCH"); }
              else {
                this.state.changeData.push({
                  key:key.toString() + "supplierItemNumber",
                  itemId:key,
                  itemName:catalog[position][key]["itemName"],
                  field:"supplierItemNumber",
                  oldData:supplierItemNumber,
                  newData:catalog[position][key]["supplierItemNumber"],
                });
              }

              var upc = '';
              if(snap.hasChild("upc")){
                upc = snap.val().upc.toString();
              }
              if (upc === catalog[position][key]["upc"] ) { console.log("CASE SIZE MATCH"); }
              else {
                this.state.changeData.push({
                  key:key.toString() + "upc",
                  itemId:key,
                  itemName:catalog[position][key]["itemName"],
                  field:"upc",
                  oldData:upc,
                  newData:catalog[position][key]["upc"],
                });
              }

              var upcType = '';
              if(snap.hasChild("upcType")){
                upcType = snap.val().upcType.toString();
              }
              if (upcType === catalog[position][key]["upcType"] ) { console.log("CASE SIZE MATCH"); }
              else {
                this.state.changeData.push({
                  key:key.toString() + "upcType",
                  itemId:key,
                  itemName:catalog[position][key]["itemName"],
                  field:"upcType",
                  oldData:upcType,
                  newData:catalog[position][key]["upcType"],
                });
              }

                console.log("finished processing " + catalog[position][key]["itemName"]);
                this.setState({changeData:changeData});



            });
          //}

          //console.log(" key " + JSON.stringify(catalog[position][key]) );
        }

        //console.log("JSON.stringify(catalog[i][key]) " + JSON.stringify(catalog[i][key]));
      }

    //   firebase.database().ref('company/' + this.state.companyId + '/catalog/').once('value', (snap) => {
    //
    //     snap.forEach((item) => {
    //
    //       if (item.key === "894700010120"){
    //         for (var i = 0; i < catalog.length; i++) {
    //           //console.log("catalog[i].itemName " + JSON.stringify(catalog[i]["894700010120"]) );
    //           //if (catalog[i][item.key.toString() === item.key){}
    //           console.log("catalog[i].itemName " + JSON.stringify(catalog[i][item.key]) );
    //           console.log("-------------" );
    //           console.log("-------------" );
    //           console.log("-------------" );
    //
    //
    //           //console.log("catalog[i].itemName " + catalog[i].length );
    //         }
    //       }
    //
    //     });
    //
    //
    // });

  }
  }
}






    updateUser(){
      alert("Feature is still in progress.");
    }


  render(){


    return (



        <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', marginBottom:'300px'}}>
          <p style={{margin:'20px', fontSize:'30px', fontWeight:'bold', }}>Approve Catalog Updates</p>



          <form noValidate autoComplete="off">

          <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>


          <div style={{width:'1100px', marginTop:'20px'}}>

          <div style={{marginTop:'10px', display:'flex', flexDirection:'row'}}>
          <label class="label">Type</label><p style={{marginLeft:'10px'}}>{this.state.item.action}</p>
          </div>

          <div style={{marginTop:'10px', display:'flex', flexDirection:'row'}}>
          <label class="label">Description</label><p style={{marginLeft:'10px'}}>{this.state.item.message}</p>
          </div>

            <div style={{marginTop:'10px', display:'flex', flexDirection:'row'}}>

            <label class="label">Uploaded By</label><p style={{marginLeft:'10px'}}>{this.state.item.email}</p>
            </div>
            <div style={{marginTop:'10px', display:'flex', flexDirection:'row'}}>
            <label class="label">Uploaded Time</label><p style={{marginLeft:'10px'}}>{this.state.item.timestamp}</p>
            </div>
            { (this.state.item.action === "New Items") &&
              <div style={{marginTop:'10px', display:'flex', flexDirection:'row'}}>
              <label class="label">New Items</label><p style={{marginLeft:'10px'}}>{this.state.newCatalogSize}</p>
              </div>

            }


            <div style={{marginTop:'10px', marginBottom:'20px', display:'flex', flexDirection:'row'}}>
              <label class="label" style={{color:'#004baf'}}>Please Review Incoming Changes</label><p style={{marginLeft:'10px'}}></p>
            </div>
          </div>


          <div style={{padding:'5px', width:'1200px'}}>{this.state.changeData.map(i =>

             <div id="userLink" style={{display:'flex',  flexDirection:'column', justifyContent:'flex-start', marginTop:'10px', marginBottom:'10px', textDecoration:'none', backgroundColor:'none', color:'black'}}>
             <div style={{display:'flex', height:'30px', alignItems:'center', flexDirection:'row', marginTop:'5px', marginBottom:'5px', textDecoration:'none', backgroundColor:'none', color:'black'}}>
               <p style={{marginLeft:'50px', width:'200px'}}>{i.itemId}</p><p style={{marginLeft:'50px', width:'300px'}}>{i.itemName}</p><p style={{marginLeft:'50px', width:'200px', }}>{i.field}</p><p style={{marginLeft:'50px', width:'200px', color:'red'}}>{i.oldData} =></p><p style={{marginLeft:'50px', width:'200px', color:'green'}}>{i.newData}</p>
             </div>
             </div>
            )}
             </div>


             <div style={{padding:'5px', width:'1200px'}}>{this.state.addedData.map(i =>

                <div id="userLink" style={{display:'flex',  flexDirection:'column', justifyContent:'flex-start', marginTop:'10px', marginBottom:'10px', textDecoration:'none', backgroundColor:'none', color:'black'}}>
                <div style={{display:'flex', height:'30px', alignItems:'center', flexDirection:'row', marginTop:'5px', marginBottom:'5px', textDecoration:'none', backgroundColor:'none', color:'black'}}>
                  <p style={{marginLeft:'50px', width:'200px'}}>{i.itemId}</p><p style={{marginLeft:'50px', width:'300px'}}>{i.itemName}</p><p style={{marginLeft:'50px', width:'200px', }}>COST {i.caseCost}</p><p style={{marginLeft:'50px', width:'200px', }}>PRICE {i.casePrice}</p><p style={{marginLeft:'50px', width:'200px'}}>{i.category}</p>
                </div>
                </div>
               )}
                </div>


            </div>


          </form>


            <div style={{width:'1200px', display:'flex', marginTop:'20px', flexDirection:'row', justifyContent:'flex-end'}}>
              <button
                //onClick={() => this.updateCatalog()}
                className={"button"} style={{color:'black', backgroundColor:'#C4EEDE', width:'150px'}}>
                Activate
              </button>
           </div>



        </div>


    );
  }


}

export default ApprovalAdminItem;
