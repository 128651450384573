import React from "react";
import ContentSection from "./../../components/ContentSection";
import FeaturesSectionFull from "./../../components/FeaturesSectionFull";
import "./styles.scss";
import CSVReader from 'react-csv-reader';
import Dropzone from 'react-dropzone';
import { CSVLink, CSVDownload } from "react-csv";
import firebase from 'firebase';


//function CatalogAdmin(props) {
class CatalogUpload extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      errorMessage:'',
      csvSample:[ ["itemId", "itemName", "caseCost", "casePrice", "caseSize", "isPiece", "isHidden", "hasPromo", "promoPrice", "caseHeight", "caseDepth", "caseWidth", "itemAisle", "itemBrand", "itemCategory", "itemDescription", "itemImage", "itemSupplier", "itemWeight", "keywords", "palletSize", "tierSize", "sortId", "supplierItemNumber", "upc", "upcType" ] ],
      csvCatalog:[],
      updateCatalogData:[],
      uploadCatalogData:[],
      dateValue:'',
      dateFormatted:'',
      companyId:'',
      updateMessage:'',
      uploadMessage:'',
      isUploading:false,
      isUpdating:false,
      isCatalogDownloaded:false,
      loading:false,
    };
  }


  componentDidMount(){

    var today = new Date();
    var start = new Date();
    start.setHours(0,0,0,0);

    var end = new Date();
    end.setHours(23,59,59,999);

    var todayStartValue = Math.floor(start / 1000);
    var todayEndValue = Math.floor(end / 1000);


    var dd = today.getDate();
    var mm = today.getMonth()+1;
    var yyyy = today.getFullYear();
    if(dd<10){
        dd='0'+dd;
    }
    if(mm<10){
        mm='0'+mm;
    }
    var dateValue = yyyy.toString() + mm.toString() + dd.toString();
    var dateVal = yyyy.toString() + mm.toString();
    var dateToday = mm.toString() + '/' + dd.toString() + '/' + yyyy.toString();

    this.setState({dateValue:dateValue});
    this.setState({dateFormatted:dateToday});


    var setUser = '';
    firebase.auth().onAuthStateChanged((user) => {
      if (user){
        this.setState({isSignedIn:true});
        this.setState({email:user.email});

        this.getCompanyData()
        console.log(user);
      } else {
        this.setState({isSignedIn:false});

      }
    });

  }


  getCompanyData(){

    var user = firebase.auth().currentUser;
    console.log("OPEN USER " + user);

    if (user){
    firebase.database().ref('users/' + user.uid).once('value', (dataSnapshot) => {
        console.log("datasnap " + JSON.stringify(dataSnapshot));
        if (dataSnapshot.val().companyId!==undefined){
          this.setState({companyId: dataSnapshot.val().companyId});
        }
    }).then(() => {


      });
    }
  }

  updateCSV(data){
    //console.log(data);


    var now = Date();
    var nowValue = Math.floor(Date.now() / 1000);
    var jsonFormattedCatalog = [];
    var user = firebase.auth().currentUser;
    this.setState({loading:true});

    console.log("data[0]" + data[0]);
    console.log("data[0].length" + data[0].length);

    if (data[0].length!==26){
      //alert("Invalid file. Download the latest template.");
      //this.setState({errorMessage:"Error processing file. Check the latest template format and make sure all required fields are filled."});
      alert("Error processing file. Check the latest template format and make sure all required fields are filled. ");
      this.setState({loading:false});
    } else {
      //alert("uploading file");

      //// update /catalogStaging endpoint for approval

      // Async / await usage
      //const jsonArray=await csv().fromFile(csvFilePath);
      // var itemDetails = [itemId, itemName, caseCost, casePrice, caseSize, isPiece
      //   , isHidden, hasPromo, promoPrice, caseHeight, caseDepth, caseWidth
      //   , itemAisle, itemBrand, itemCategory, itemDescription, itemImage, itemSupplier, itemWeight
      // , keywords, palletSize, tierSize, sortId, supplierItemNumber, upc, upcType];


      var isValid = true;
      if (data[0][0].toString() !== "itemId"){ isValid = false; }
      if (data[0][1].toString() !== "itemName"){ isValid = false; }
      if (data[0][2].toString() !== "caseCost"){ isValid = false; }
      if (data[0][3].toString() !== "casePrice"){ isValid = false; }
      if (data[0][4].toString() !== "caseSize"){ isValid = false; }
      if (data[0][5].toString() !== "isPiece"){ isValid = false; }
      if (data[0][6].toString() !== "isHidden"){ isValid = false; }
      if (data[0][7].toString() !== "hasPromo"){ isValid = false; }
      if (data[0][8].toString() !== "promoPrice"){ isValid = false; }
      if (data[0][9].toString() !== "caseHeight"){ isValid = false; }
      if (data[0][10].toString() !== "caseDepth"){ isValid = false; }
      if (data[0][11].toString() !== "caseWidth"){ isValid = false; }
      if (data[0][12].toString() !== "itemAisle"){ isValid = false; }
      if (data[0][13].toString() !== "itemBrand"){ isValid = false; }
      if (data[0][14].toString() !== "itemCategory"){ isValid = false; }
      if (data[0][15].toString() !== "itemDescription"){ isValid = false; }
      if (data[0][16].toString() !== "itemImage"){ isValid = false; }
      if (data[0][17].toString() !== "itemSupplier"){ isValid = false; }
      if (data[0][18].toString() !== "itemWeight"){ isValid = false; }
      if (data[0][19].toString() !== "keywords"){ isValid = false; }
      if (data[0][20].toString() !== "palletSize"){ isValid = false; }
      if (data[0][21].toString() !== "tierSize"){ isValid = false; }
      if (data[0][22].toString() !== "sortId"){ isValid = false; }
      if (data[0][23].toString() !== "supplierItemNumber"){ isValid = false; }
      if (data[0][24].toString() !== "upc"){ isValid = false; }
      if (data[0][25].toString() !== "upcType"){ isValid = false; }

      if (isValid){
        for (var i = 1; i < data.length; i++) {
        //for (var i = 1; i < 10; i++) {

          // console.log("field " + data[0][0] + ' value ' + data[i][0]);
          // console.log("field " + data[0][1] + ' value ' + data[i][1]);
          // console.log("field " + data[0][2] + ' value ' + data[i][2]);
          // console.log("field " + data[0][3] + ' value ' + data[i][3]);
          // console.log("field " + data[0][4] + ' value ' + data[i][4]);
          // console.log("field " + data[0][5] + ' value ' + data[i][5]);
          // console.log("field " + data[0][6] + ' value ' + data[i][6]);
          // console.log("field " + data[0][7] + ' value ' + data[i][7]);
          // console.log("field " + data[0][8] + ' value ' + data[i][8]);
          // console.log("field " + data[0][9] + ' value ' + data[i][9]);
          // console.log("field " + data[0][10] + ' value ' + data[i][10]);
          // console.log("field " + data[0][11] + ' value ' + data[i][11]);
          // console.log("field " + data[0][12] + ' value ' + data[i][12]);
          // console.log("field " + data[0][13] + ' value ' + data[i][13]);
          // console.log("field " + data[0][14] + ' value ' + data[i][14]);
          // console.log("field " + data[0][15] + ' value ' + data[i][15]);
          // console.log("field " + data[0][16] + ' value ' + data[i][16]);
          // console.log("field " + data[0][17] + ' value ' + data[i][17]);
          // console.log("field " + data[0][18] + ' value ' + data[i][18]);
          // console.log("field " + data[0][19] + ' value ' + data[i][19]);
          // console.log("field " + data[0][20] + ' value ' + data[i][20]);
          // console.log("field " + data[0][21] + ' value ' + data[i][21]);
          // console.log("field " + data[0][22] + ' value ' + data[i][22]);
          // console.log("field " + data[0][23] + ' value ' + data[i][23]);
          // console.log("field " + data[0][24] + ' value ' + data[i][24]);
          // console.log("field " + data[0][25] + ' value ' + data[i][25]);

          jsonFormattedCatalog.push({
            [data[i][0]]:{
              [data[0][0]]:data[i][0],
              [data[0][1]]:data[i][1],
              [data[0][2]]:data[i][2],
              [data[0][3]]:data[i][3],
              [data[0][4]]:data[i][4],
              [data[0][5]]:data[i][5],
              [data[0][6]]:data[i][6],
              [data[0][7]]:data[i][7],
              [data[0][8]]:data[i][8],
              [data[0][9]]:data[i][9],
              [data[0][10]]:data[i][10],
              [data[0][11]]:data[i][11],
              [data[0][12]]:data[i][12],
              [data[0][13]]:data[i][13],
              [data[0][14]]:data[i][14],
              [data[0][15]]:data[i][15],
              [data[0][16]]:data[i][16],
              [data[0][17]]:data[i][17],
              [data[0][18]]:data[i][18],
              [data[0][19]]:data[i][19],
              [data[0][20]]:data[i][20],
              [data[0][21]]:data[i][21],
              [data[0][22]]:data[i][22],
              [data[0][23]]:data[i][23],
              [data[0][24]]:data[i][24],
              [data[0][25]]:data[i][25],
            }
          });

        }

        //console.log("jsonFormattedCatalog "  + JSON.stringify(jsonFormattedCatalog));
        this.setState({updateCatalogData: jsonFormattedCatalog});
        this.setState({isUpdating:true});

        // firebase.database().ref('company/' + this.state.companyId + '/catalogStaging/').push({
        //   catalog:jsonFormattedCatalog,
        //   timestamp:now,
        //   timestampValue:nowValue,
        //   action:'Update Items',
        //   message:this.state.updateMessage,
        //   uploadedBy:user.email,
        // }).then(() => {
        //   this.setState({loading:false});
        //   alert("Upload Successful. Catalog must be approved in staging area before active.")
        // }).catch(() => {
        //   this.setState({loading:false});
        //   //this.setState({errorMessage:"Error processing file. Check the latest template format and make sure all required fields are filled."});
        //   alert("Error processing file. Check the latest template format and make sure all required fields are filled.");
        // });

      } else {
        this.setState({loading:false});
        //this.setState({errorMessage:"Error processing file. Check the latest template format and make sure all required fields are filled."});
        alert("Error processing file. Check the latest template format and make sure all required fields are filled.");
      }


    }



  }

  submitUpdate(){
    var user = firebase.auth().currentUser;
    var now = Date();
    var nowValue = Math.floor(Date.now() / 1000);
    if (this.state.updateMessage.trim()===""){
      alert("ERROR a description is required.");
    } else {

      firebase.database().ref('company/' + this.state.companyId + '/catalogStaging/').push({
        catalog:this.state.updateCatalogData,
        timestamp:now,
        timestampValue:nowValue,
        action:'Update Items',
        message:this.state.updateMessage,
        uploadedBy:user.email,
      }).then(() => {
        this.setState({loading:false});
        this.setState({updateMessage:''});
        alert("Upload Successful. Catalog must be approved in staging area before active.")
      }).catch(() => {
        this.setState({loading:false});
        //this.setState({errorMessage:"Error processing file. Check the latest template format and make sure all required fields are filled."});
        alert("Error processing file. Check the latest template format and make sure all required fields are filled.");
      });

    }
  }


  uploadCSV(data){
    //console.log(data);


    var now = Date();
    var nowValue = Math.floor(Date.now() / 1000);
    var jsonFormattedCatalog = [];
    var user = firebase.auth().currentUser;
    this.setState({loading:true});

    console.log("data[0]" + data[0]);
    console.log("data[0].length" + data[0].length);

    if (data[0].length!==26){

      alert("Error processing file. Check the latest template format and make sure all required fields are filled. ");
      this.setState({loading:false});
    } else {



      var isValid = true;
      if (data[0][0].toString() !== "itemId"){ isValid = false; }
      if (data[0][1].toString() !== "itemName"){ isValid = false; }
      if (data[0][2].toString() !== "caseCost"){ isValid = false; }
      if (data[0][3].toString() !== "casePrice"){ isValid = false; }
      if (data[0][4].toString() !== "caseSize"){ isValid = false; }
      if (data[0][5].toString() !== "isPiece"){ isValid = false; }
      if (data[0][6].toString() !== "isHidden"){ isValid = false; }
      if (data[0][7].toString() !== "hasPromo"){ isValid = false; }
      if (data[0][8].toString() !== "promoPrice"){ isValid = false; }
      if (data[0][9].toString() !== "caseHeight"){ isValid = false; }
      if (data[0][10].toString() !== "caseDepth"){ isValid = false; }
      if (data[0][11].toString() !== "caseWidth"){ isValid = false; }
      if (data[0][12].toString() !== "itemAisle"){ isValid = false; }
      if (data[0][13].toString() !== "itemBrand"){ isValid = false; }
      if (data[0][14].toString() !== "itemCategory"){ isValid = false; }
      if (data[0][15].toString() !== "itemDescription"){ isValid = false; }
      if (data[0][16].toString() !== "itemImage"){ isValid = false; }
      if (data[0][17].toString() !== "itemSupplier"){ isValid = false; }
      if (data[0][18].toString() !== "itemWeight"){ isValid = false; }
      if (data[0][19].toString() !== "keywords"){ isValid = false; }
      if (data[0][20].toString() !== "palletSize"){ isValid = false; }
      if (data[0][21].toString() !== "tierSize"){ isValid = false; }
      if (data[0][22].toString() !== "sortId"){ isValid = false; }
      if (data[0][23].toString() !== "supplierItemNumber"){ isValid = false; }
      if (data[0][24].toString() !== "upc"){ isValid = false; }
      if (data[0][25].toString() !== "upcType"){ isValid = false; }

      if (isValid){
        for (var i = 1; i < data.length; i++) {


          jsonFormattedCatalog.push({
            [data[i][0]]:{
              [data[0][0]]:data[i][0],
              [data[0][1]]:data[i][1],
              [data[0][2]]:data[i][2],
              [data[0][3]]:data[i][3],
              [data[0][4]]:data[i][4],
              [data[0][5]]:data[i][5],
              [data[0][6]]:data[i][6],
              [data[0][7]]:data[i][7],
              [data[0][8]]:data[i][8],
              [data[0][9]]:data[i][9],
              [data[0][10]]:data[i][10],
              [data[0][11]]:data[i][11],
              [data[0][12]]:data[i][12],
              [data[0][13]]:data[i][13],
              [data[0][14]]:data[i][14],
              [data[0][15]]:data[i][15],
              [data[0][16]]:data[i][16],
              [data[0][17]]:data[i][17],
              [data[0][18]]:data[i][18],
              [data[0][19]]:data[i][19],
              [data[0][20]]:data[i][20],
              [data[0][21]]:data[i][21],
              [data[0][22]]:data[i][22],
              [data[0][23]]:data[i][23],
              [data[0][24]]:data[i][24],
              [data[0][25]]:data[i][25],
            }
          });

        }

        //console.log("jsonFormattedCatalog "  + JSON.stringify(jsonFormattedCatalog));
        this.setState({uploadCatalogData: jsonFormattedCatalog});
        this.setState({isUploading:true});
        // firebase.database().ref('company/' + this.state.companyId + '/catalogStaging/').push({
        //   catalog:jsonFormattedCatalog,
        //   timestamp:now,
        //   timestampValue:nowValue,
        //   action:'New Items',
        //   message:this.state.uploadMessage,
        //   uploadedBy:user.email,
        // }).then(() => {
        //   this.setState({loading:false});
        //   alert("Upload Successful. Catalog must be approved in staging area before active.")
        // }).catch(() => {
        //   this.setState({loading:false});
        //   //this.setState({errorMessage:"Error processing file. Check the latest template format and make sure all required fields are filled."});
        //   alert("Error processing file. Check the latest template format and make sure all required fields are filled.");
        // });

      } else {
        this.setState({loading:false});
        //this.setState({errorMessage:"Error processing file. Check the latest template format and make sure all required fields are filled."});
        alert("Error processing file. Check the latest template format and make sure all required fields are filled.");
      }


    }



  }

  submitUpload(){
    var now = Date();
    var nowValue = Math.floor(Date.now() / 1000);
    var user = firebase.auth().currentUser;
    if (this.state.uploadMessage.trim()===""){
      alert("ERROR a description is required.");
    } else {
      firebase.database().ref('company/' + this.state.companyId + '/catalogStaging/').push({
        catalog:this.state.uploadCatalogData,
        timestamp:now,
        timestampValue:nowValue,
        action:'New Items',
        message:this.state.uploadMessage,
        uploadedBy:user.email,
      }).then(() => {
        this.setState({loading:false});
        this.setState({uploadMessage:''});
        alert("Upload Successful. Catalog must be approved in staging area before active.")
      }).catch(() => {
        this.setState({loading:false});
        //this.setState({errorMessage:"Error processing file. Check the latest template format and make sure all required fields are filled."});
        alert("Error processing file. Check the latest template format and make sure all required fields are filled.");
      });

    }



  }


  downloadCatalog(){

    if (this.state.companyId!==undefined && this.state.companyId.trim()!==""){
      console.log("this.state.companyId " + this.state.companyId);


      firebase.database().ref('company/' + this.state.companyId + '/catalog/').once('value', (snap) => {

        // ItemId	caseCost	caseDepth	caseHeight	casePrice	caseSize
        // caseWidth	hasPromo	isHidden	isPiece	itemAisle	itemBrand	itemCategory
        // itemDescription	itemImage	itemInventory	itemName	itemSupplier	itemWeight
        // keywords	palletSize	promoPrice	sortId	supplierItemNumber	tierSize	upc	upcTypea

        var data = [ ["itemId", "itemName", "caseCost", "casePrice", "caseSize", "isPiece", "isHidden", "hasPromo", "promoPrice", "caseHeight", "caseDepth", "caseWidth", "itemAisle", "itemBrand", "itemCategory", "itemDescription", "itemImage", "itemSupplier", "itemWeight", "keywords", "palletSize", "tierSize", "sortId", "supplierItemNumber", "upc", "upcType" ] ];

        //console.log("item details " + snap.key + ', ' + snap.val().itemName); // + ', ' + itemSnap.val().casePrice + ', ' + itemSnap.val().caseSize);
        snap.forEach((item) => {
          var itemId = item.key.toString();
          var itemName = item.val().itemName.toString();
          var caseCost = item.val().caseCost.toString();
          var casePrice = item.val().casePrice.toString();
          var caseSize = item.val().caseSize.toString();
          var isPiece = item.val().isPiece.toString().toUpperCase();
          var isHidden = item.val().isHidden.toString().toUpperCase();
          var hasPromo = item.val().hasPromo.toString().toUpperCase();
          var promoPrice = '';
          if (item.hasChild("promoPrice")){ promoPrice = item.val().promoPrice.toString()}
          var caseHeight = '';
          if (item.hasChild("caseHeight")){ caseHeight = item.val().caseHeight.toString()}
          var caseDepth = '';
          if (item.hasChild("caseDepth")){ caseDepth = item.val().caseDepth.toString()}
          var caseWidth = '';
          if (item.hasChild("caseWidth")){ caseWidth = item.val().caseWidth.toString()}
          var itemAisle = '';
          if (item.hasChild("itemAisle")){ itemAisle = item.val().itemAisle.toString()}
          var itemBrand = '';
          if (item.hasChild("itemBrand")){ itemBrand = item.val().itemBrand.toString()}
          var itemCategory = '';
          if (item.hasChild("itemCategory")){ itemCategory = item.val().itemCategory.toString()}
          var itemDescription = '';
          if (item.hasChild("itemDescription")){ itemDescription = item.val().itemDescription.toString()}
          var itemImage = '';
          if (item.hasChild("itemImage")){ itemImage = item.val().itemImage.toString()}
          var itemSupplier = '';
          if (item.hasChild("itemSupplier")){ itemSupplier = item.val().itemSupplier.toString()}
          var itemWeight = '';
          if (item.hasChild("itemWeight")){ itemWeight = item.val().itemWeight.toString()}
          var keywords = '';
          if (item.hasChild("keywords")){ keywords = item.val().keywords.toString()}
          var palletSize = '';
          if (item.hasChild("palletSize")){ palletSize = item.val().palletSize.toString()}
          var tierSize = '';
          if (item.hasChild("tierSize")){ tierSize = item.val().tierSize.toString()}
          var sortId = '';
          if (item.hasChild("sortId")){ sortId = item.val().sortId.toString()}
          var supplierItemNumber = '';
          if (item.hasChild("supplierItemNumber")){ supplierItemNumber = item.val().supplierItemNumber.toString()}
          var upc = '';
          if (item.hasChild("upc")){ upc = item.val().upc.toString()}
          var upcType = '';
          if (item.hasChild("upcType")){ upcType = item.val().upcType.toString()}



          var itemDetails = [itemId, itemName, caseCost, casePrice, caseSize, isPiece
            , isHidden, hasPromo, promoPrice, caseHeight, caseDepth, caseWidth
            , itemAisle, itemBrand, itemCategory, itemDescription, itemImage, itemSupplier, itemWeight
          , keywords, palletSize, tierSize, sortId, supplierItemNumber, upc, upcType];



          //var itemDetails = [item.key.toString(), item.val().itemName.toString(), item.val().caseCost.toString(), item.val().casePrice.toString(), item.val().caseSize.toString(), item.val().isPiece.toString().toUpperCase()];
            // var itemDetails = [item.key.toString(), item.val().itemName, item.val().caseCost.toString(), item.val().casePrice.toString(), item.val().caseSize.toString(), item.val().isPiece.toString().toUpperCase()
            //   , item.val().isHidden.toString().toUpperCase(), item.val().hasPromo.toString().toUpperCase(), item.val().promoPrice.toString(), item.val().caseHeight.toString(), item.val().caseDepth.toString(), item.val().caseWidth.toString()
            //   , item.val().itemAisle.toString(), item.val().itemBrand.toString(), item.val().itemCategory.toString(), item.val().itemDescription.toString(), item.val().itemImage.toString(), item.val().itemSupplier.toString(), item.val().itemWeight.toString()
            // , item.val().keywords.toString(), item.val().palletSize.toString(), item.val().tierSize.toString(), item.val().sortId.toString(), item.val().supplierItemNumber.toString(), item.val().upc.toString(), item.val().upcType.toString()];
            data.push( itemDetails );
        });


        // console.log("data from inv " + JSON.stringify(data));
        // console.log("data from format " + JSON.stringify(csvData));

        this.setState({csvCatalog:data});
        this.setState({isCatalogDownloaded:true});

      }).catch((err) => {
        //this.setState({errorMessage:'Error retrieving catalog data. Contact support.'});
        alert("Error retrieving catalog data. Contact support.");
        this.setState({isCatalogDownloaded:false});
      });
    }


  }

  render(){



    return (



        <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
          <p style={{margin:'20px', fontSize:'30px', fontWeight:'bold', }}>Catalog Upload</p>

          <div style={{display:'flex', width:'500px', height:'50px', marginTop:'50px', flexDirection:'row', justifyContent:'flex-start',}}>
          <CSVLink
          data={this.state.csvSample}
          filename={"synk-catalog-template.csv"}
          asyncOnClick={true}
           onClick={(event, done) => {
             console.log('event ' + event);
             console.log('done ' + done);
             done(true);

           }}
          //className="download-template"
          target="_blank">Download Upload Template</CSVLink>
          </div>

          { (this.state.isCatalogDownloaded === true) &&
            <div style={{display:'flex', width:'500px', margin:'5px',height:'50px', flexDirection:'row', justifyContent:'flex-start',}}>
            <CSVLink
            data={this.state.csvCatalog}
            filename={"synk-catalog.csv"}
            asyncOnClick={true}
             onClick={(event, done) => {

               console.log('event ' + event);
               console.log('done ' + done);
               done(true);

             }}
            //className="download-template"
            target="_blank">Download Current Catalog</CSVLink>
            </div>
          }

          { (this.state.isCatalogDownloaded !== true) &&
            <div style={{display:'flex', width:'500px', margin:'5px',height:'50px', flexDirection:'row', justifyContent:'flex-start',}}>
            <CSVLink
            data={this.state.csvCatalog}
            filename={"synk-catalog.csv"}
            asyncOnClick={true}
             onClick={(event, done) => {
               this.downloadCatalog();
               // console.log('event ' + event);
               // console.log('done ' + done);
               done(false);

             }}
            //className="download-template"
            target="_blank">Get Current Catalog Data</CSVLink>
            </div>
          }

          <div style={{borderWidth:.5, border:'dotted', borderColor:'#999', paddingLeft:'50px', paddingRight:'50px'}}>

          <div style={{display:'flex',  width:'500px', marginBottom:'50px', marginTop:'10px', flexDirection:'column', justifyContent:'flex-start',}}>
            <p style={{color:'black', fontSize:'22px', marginTop:'15px'}}>Upload New Items</p>
            <p style={{color:'gray', fontSize:'18px' , marginTop:'15px'}}>1) Download the catalog template</p>
            <p style={{color:'gray', fontSize:'18px' , marginTop:'15px'}}>2) Add new records with all required fields</p>
            <p style={{color:'gray', fontSize:'18px' , marginTop:'15px'}}>3) Set Item Id as either the UPC or leave blank to generate a random ID.</p>
            <p style={{color:'gray', fontSize:'18px' , marginTop:'15px'}}>3) Save as .csv file</p>
            <p style={{color:'gray', fontSize:'18px' , marginTop:'15px'}}>4) Upload using the file selector below</p>
            <p style={{color:'gray', fontSize:'18px' , marginTop:'15px'}}>5) Submit the new catalog from the 'Approver' function</p>
            <p style={{color:'gray', fontSize:'14px' , marginTop:'25px'}}>Required fields are itemId, caseCost, caseSize, casePrice, itemName, isPiece, hasPromo, and isHidden.</p>
          </div>


                <input
                style={{width:'500px', marginTop:'20px'}}
                  className="input is-medium"
                  //type="tel"
                  value={this.state.uploadMessage}
                  placeholder={"Describe additions"}
                  onChange={this.handleUploadMessageChange}
                />

                    <div style={{display:'flex',  width:'500px', marginBottom:'20px', marginTop:'20px', flexDirection:'column', justifyContent:'flex-start',}}>
                    <p style={{marginBottom:'5px', fontSize:'22px', color:'black',}}></p>
                      <div className="border" style={{display:'flex', alignItems:'center', justifyContent:'center', borderRadius:'10px', width:'500px', height:'50px', backgroundColor:'#CEE'}}>
                        <CSVReader style={{marginLeft:'10px',}}
                          onFileLoaded={data => {
                              this.uploadCSV(data);
                          }}
                          />
                      </div>
                    </div>


                { (this.state.isUploading!==true) &&
                  <div style={{width:'500px', display:'flex', marginTop:'20px', flexDirection:'row', justifyContent:'flex-end', marginBottom:'20px', }}>
                    <button  className={"button"} style={{color:'black', backgroundColor:'#EEE', width:'150px'}}>
                      Submit
                    </button>
                 </div>
                }
                { (this.state.isUploading===true) &&
                  <div style={{width:'500px', display:'flex', marginTop:'20px', flexDirection:'row', justifyContent:'flex-end', marginBottom:'20px', }}>
                    <button onClick={() => this.submitUpload()} className={"button"} style={{color:'black', backgroundColor:'#C4EEDE', width:'150px'}}>
                      Submit
                    </button>
                 </div>
                }
                    </div>



                    <div style={{borderWidth:.5, marginTop:'20px', border:'dotted', borderColor:'#999', paddingLeft:'50px', paddingRight:'50px'}}>

                    <div style={{display:'flex',  width:'500px', marginBottom:'50px', marginTop:'50px', flexDirection:'column', justifyContent:'flex-start',}}>
                      <p style={{color:'black', fontSize:'22px', marginTop:'15px'}}>Update Existing Items</p>
                      <p style={{color:'gray', fontSize:'18px' , marginTop:'15px'}}>1) Download your latest catalog</p>
                      <p style={{color:'gray', fontSize:'18px' , marginTop:'15px'}}>2) Overwrite your updates to matching Item ID</p>
                      <p style={{color:'gray', fontSize:'18px' , marginTop:'15px'}}>3) Save as .csv file</p>
                      <p style={{color:'gray', fontSize:'18px' , marginTop:'15px'}}>4) Upload using the file selector below</p>
                      <p style={{color:'gray', fontSize:'18px' , marginTop:'15px'}}>5) Submit the new catalog from the 'Approver' function</p>


                    </div>

                    <input
                    style={{width:'500px', marginTop:'20px'}}
                      className="input is-medium"
                      //type="tel"
                      value={this.state.updateMessage}
                      placeholder={"Describe updates"}
                      onChange={this.handleUpdateMessageChange}
                    />

                    <div style={{display:'flex',  width:'500px', marginBottom:'20px', marginTop:'20px', flexDirection:'column', justifyContent:'flex-start',}}>
                    <p style={{marginBottom:'5px', fontSize:'22px', color:'black',}}></p>
                      <div className="border" style={{display:'flex', alignItems:'center', justifyContent:'center', borderRadius:'10px', width:'500px', height:'50px', backgroundColor:'#CEE'}}>
                        <CSVReader style={{marginLeft:'10px',}}
                          onClick={() => alert("xyz")}
                          onFileLoaded={data => {
                            this.updateCSV(data);
                          }}
                          />
                      </div>
                    </div>

                    { (this.state.isUpdating!==true) &&
                      <div style={{width:'500px', display:'flex', marginTop:'20px', flexDirection:'row', justifyContent:'flex-end', marginBottom:'20px', }}>
                        <button className={"button"} style={{color:'black', backgroundColor:'#EEE', width:'150px'}}>
                          Submit
                        </button>
                     </div>
                    }
                    { (this.state.isUpdating===true) &&
                      <div style={{width:'500px', display:'flex', marginTop:'20px', flexDirection:'row', justifyContent:'flex-end', marginBottom:'20px', }}>
                        <button onClick={() => this.submitUpdate()} className={"button"} style={{color:'black', backgroundColor:'#C4EEDE', width:'150px'}}>
                          Submit
                        </button>
                     </div>
                    }




                    </div>











        </div>


    );
  }


  handleUpdateMessageChange = option => {
      this.setState({updateMessage:option.target.value});
  }

  handleUploadMessageChange = option => {
      this.setState({uploadMessage:option.target.value});
  }

}

export default CatalogUpload;


/*



          <Dropzone onDrop={acceptedFiles => console.log(acceptedFiles)}>
  {({getRootProps, getInputProps}) => (
    <section>
      <a>
      <div className="border" style={{width:'450px', height:'250px', backgroundColor:'#CEE'}} {...getRootProps()}>
        <input {...getInputProps()} />
        <p className="link"  style={{padding:'10px'}}>Drag 'n' drop your file here, or click to select.</p>
      </div>
      </a>
    </section>
  )}
</Dropzone>

<CSVReader style={{margin:'20px',}} onFileLoaded={data => console.log(data)} />

<TeamBiosSection
  color="white"
  size="medium"
  title="Meet the Team"
  subtitle=""
/>

#C4EEDE
#8befd6

updateCSV(data){
  //console.log(data);
  var now = Date();
  var nowValue = Math.floor(Date.now() / 1000);
  var jsonFormattedCatalog = [];
  var user = firebase.auth().currentUser;
  this.setState({loading:true});

  console.log("data[0]" + data[0]);
  console.log("data[0].length" + data[0].length);

  var errorCount = 0;
  var isValid = false;
  var fieldList = '';
  var fieldData = [];
  for (var i = 0; i < data[0].length; i++) {

    if (errorCount>0){

    } else {

      let itemIdPosition = null;
      let itemNamePosition = null;
      let caseCostPosition = null;
      let casePricePosition = null;
      let caseSizePosition = null;
      let isPiecePosition = null;
      let isHiddenPosition = null;
      let hasPromoPosition = null;
      let promoPricePosition = null;
      let caseHeightPosition = null;
      let caseDepthPosition = null;
      let caseWidthPosition = null;
      let itemAislePosition = null;
      let itemBrandPosition = null;
      let itemCategoryPosition = null;
      let itemDescriptionPosition = null;
      let itemImagePosition = null;
      let itemSupplierPosition = null;
      let itemWeightPosition = null;
      let keywordsPosition = null;
      let palletSizePosition = null;
      let tierSizePosition = null;
      let sortIdPosition = null;
      let supplierItemNumberPosition = null;
      let upcPosition = null;
      let upcTypePosition = null;


      if (data[0][i].toString() !== "itemId"){ itemIdPosition=i; isValid = true; fieldList+= " " +  data[0][i].toString(); }
      else if (data[0][i].toString() !== "itemName"){ itemNamePosition=i; isValid = true; fieldList+= " " +  data[0][i].toString(); }
      else if (data[0][i].toString() !== "caseCost"){ caseCostPosition=i; isValid = true; fieldList+= " " +  data[0][i].toString(); }
      else if (data[0][i].toString() !== "casePrice"){ casePricePosition=i; isValid = true; fieldList+= " " +  data[0][i].toString(); }
      else if (data[0][i].toString() !== "caseSize"){ caseSizePosition=i; isValid = true; fieldList+= " " +  data[0][i].toString(); }
      else if (data[0][i].toString() !== "isPiece"){ isPiecePosition=i; isValid = true;  fieldList+= " " +  data[0][i].toString();}
      else if (data[0][i].toString() !== "isHidden"){ isHiddenPosition=i; isValid = true; fieldList+= " " +  data[0][i].toString(); }
      else if (data[0][i].toString() !== "hasPromo"){ hasPromoPosition=i; isValid = true; fieldList+= " " +  data[0][i].toString(); }
      else if (data[0][i].toString() !== "promoPrice"){ promoPricePosition=i; isValid = true; fieldList+= " " +  data[0][i].toString(); }
      else if (data[0][i].toString() !== "caseHeight"){ caseHeightPosition=i; isValid = true; fieldList+= " " +  data[0][i].toString(); }
      else if (data[0][i].toString() !== "caseDepth"){ caseDepthPosition=i; isValid = true; fieldList+= " " +  data[0][i].toString(); }
      else if (data[0][i].toString() !== "caseWidth"){ caseWidthPosition=i; isValid = true; fieldList+= " " +  data[0][i].toString(); }
      else if (data[0][i].toString() !== "itemAisle"){ itemAislePosition=i; isValid = true; fieldList+= " " +  data[0][i].toString(); }
      else if (data[0][i].toString() !== "itemBrand"){ itemBrandPosition=i; isValid = true; fieldList+= " " +  data[0][i].toString(); }
      else if (data[0][i].toString() !== "itemCategory"){ itemCategoryPosition=i; isValid = true; fieldList+= " " +  data[0][i].toString(); }
      else if (data[0][i].toString() !== "itemDescription"){ itemDescriptionPosition=i; isValid = true; fieldList+= " " +  data[0][i].toString(); }
      else if (data[0][i].toString() !== "itemImage"){ itemImagePosition=i; isValid = true; fieldList+= " " +  data[0][i].toString(); }
      else if (data[0][i].toString() !== "itemSupplier"){ itemSupplierPosition=i; isValid = true; fieldList+= " " +  data[0][i].toString(); }
      else if (data[0][i].toString() !== "itemWeight"){ itemWeightPosition=i; isValid = true; fieldList+= " " +  data[0][i].toString(); }
      else if (data[0][i].toString() !== "keywords"){ keywordsPosition=i; isValid = true; fieldList+= " " +  data[0][i].toString(); }
      else if (data[0][i].toString() !== "palletSize"){ palletSizePosition=i; isValid = true; fieldList+= " " +  data[0][i].toString(); }
      else if (data[0][i].toString() !== "tierSize"){ tierSizePosition=i; isValid = true; fieldList+= " " +  data[0][i].toString(); }
      else if (data[0][i].toString() !== "sortId"){ sortIdPosition=i; isValid = true; fieldList+= " " +  data[0][i].toString(); }
      else if (data[0][i].toString() !== "supplierItemNumber"){ supplierItemNumberPosition=i; isValid = true; fieldList+= " " +  data[0][i].toString(); }
      else if (data[0][i].toString() !== "upc"){ upcPosition=i; isValid = true; fieldList+= " " +  data[0][i].toString(); }
      else if (data[0][i].toString() !== "upcType"){ upcTypePosition=i; isValid = true; fieldList+= " " +  data[0][i].toString(); }
      else {
        isValid = false;
        errorCount++;
        alert("Invalid field: " + data[0][i].toString() + ". " + "Re-upload the file with the correct field name.");
      }

      if (i === data.[0].length){
        //3
          for (var i = 1; i < data.length; i++) {

            // fieldData.push({
            //   //[data[0][0]]:data[i][0],
            //   [data[0][i]]:
            // });

            jsonFormattedCatalog.push({
              [data[i][0]]:{
                [data[0][0]]:data[i][0],
                [data[0][1]]:data[i][1],
                [data[0][2]]:data[i][2],
                [data[0][3]]:data[i][3],
                [data[0][4]]:data[i][4],
                [data[0][5]]:data[i][5],
                [data[0][6]]:data[i][6],
                [data[0][7]]:data[i][7],
                [data[0][8]]:data[i][8],
                [data[0][9]]:data[i][9],
                [data[0][10]]:data[i][10],
                [data[0][11]]:data[i][11],
                [data[0][12]]:data[i][12],
                [data[0][13]]:data[i][13],
                [data[0][14]]:data[i][14],
                [data[0][15]]:data[i][15],
                [data[0][16]]:data[i][16],
                [data[0][17]]:data[i][17],
                [data[0][18]]:data[i][18],
                [data[0][19]]:data[i][19],
                [data[0][20]]:data[i][20],
                [data[0][21]]:data[i][21],
                [data[0][22]]:data[i][22],
                [data[0][23]]:data[i][23],
                [data[0][24]]:data[i][24],
                [data[0][25]]:data[i][25],
              }
            });

          }
      }
    }

  }

    // var isValid = true;
    // if (data[0][0].toString() !== "itemId"){ isValid = false; }
    // if (data[0][1].toString() !== "itemName"){ isValid = false; }
    // if (data[0][2].toString() !== "caseCost"){ isValid = false; }
    // if (data[0][3].toString() !== "casePrice"){ isValid = false; }
    // if (data[0][4].toString() !== "caseSize"){ isValid = false; }
    // if (data[0][5].toString() !== "isPiece"){ isValid = false; }
    // if (data[0][6].toString() !== "isHidden"){ isValid = false; }
    // if (data[0][7].toString() !== "hasPromo"){ isValid = false; }
    // if (data[0][8].toString() !== "promoPrice"){ isValid = false; }
    // if (data[0][9].toString() !== "caseHeight"){ isValid = false; }
    // if (data[0][10].toString() !== "caseDepth"){ isValid = false; }
    // if (data[0][11].toString() !== "caseWidth"){ isValid = false; }
    // if (data[0][12].toString() !== "itemAisle"){ isValid = false; }
    // if (data[0][13].toString() !== "itemBrand"){ isValid = false; }
    // if (data[0][14].toString() !== "itemCategory"){ isValid = false; }
    // if (data[0][15].toString() !== "itemDescription"){ isValid = false; }
    // if (data[0][16].toString() !== "itemImage"){ isValid = false; }
    // if (data[0][17].toString() !== "itemSupplier"){ isValid = false; }
    // if (data[0][18].toString() !== "itemWeight"){ isValid = false; }
    // if (data[0][19].toString() !== "keywords"){ isValid = false; }
    // if (data[0][20].toString() !== "palletSize"){ isValid = false; }
    // if (data[0][21].toString() !== "tierSize"){ isValid = false; }
    // if (data[0][22].toString() !== "sortId"){ isValid = false; }
    // if (data[0][23].toString() !== "supplierItemNumber"){ isValid = false; }
    // if (data[0][24].toString() !== "upc"){ isValid = false; }
    // if (data[0][25].toString() !== "upcType"){ isValid = false; }
    //
    // if (isValid){
    //   for (var i = 1; i < data.length; i++) {
    //
    //     jsonFormattedCatalog.push({
    //       [data[i][0]]:{
    //         [data[0][0]]:data[i][0],
    //         [data[0][1]]:data[i][1],
    //         [data[0][2]]:data[i][2],
    //         [data[0][3]]:data[i][3],
    //         [data[0][4]]:data[i][4],
    //         [data[0][5]]:data[i][5],
    //         [data[0][6]]:data[i][6],
    //         [data[0][7]]:data[i][7],
    //         [data[0][8]]:data[i][8],
    //         [data[0][9]]:data[i][9],
    //         [data[0][10]]:data[i][10],
    //         [data[0][11]]:data[i][11],
    //         [data[0][12]]:data[i][12],
    //         [data[0][13]]:data[i][13],
    //         [data[0][14]]:data[i][14],
    //         [data[0][15]]:data[i][15],
    //         [data[0][16]]:data[i][16],
    //         [data[0][17]]:data[i][17],
    //         [data[0][18]]:data[i][18],
    //         [data[0][19]]:data[i][19],
    //         [data[0][20]]:data[i][20],
    //         [data[0][21]]:data[i][21],
    //         [data[0][22]]:data[i][22],
    //         [data[0][23]]:data[i][23],
    //         [data[0][24]]:data[i][24],
    //         [data[0][25]]:data[i][25],
    //       }
    //     });
    //
    //   }
    //
    //   console.log("jsonFormattedCatalog "  + JSON.stringify(jsonFormattedCatalog));
    //
    //   firebase.database().ref('company/' + this.state.companyId + '/catalogStaging/').push({
    //     catalog:jsonFormattedCatalog,
    //     timestamp:now,
    //     timestampValue:nowValue,
    //     uploadedBy:user.email,
    //   }).then(() => {
    //     this.setState({loading:false});
    //     alert("Upload Successful. Catalog must be approved in staging area before active.")
    //   }).catch(() => {
    //     this.setState({loading:false});
    //     this.setState({errorMessage:"Error processing file. Check the latest template format and make sure all required fields are filled."});
    //   });
    //
    // } else {
    //   this.setState({loading:false});
    //   this.setState({errorMessage:"Error processing file. Check the latest template format and make sure all required fields are filled."});
    // }



}

*/
