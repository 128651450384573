import React from "react";
import ContentSection from "./../../components/ContentSection";
import FeaturesSectionFull from "./../../components/FeaturesSectionFull";
import "./styles.scss";
// import CSVReader from 'react-csv-reader';
// import Dropzone from 'react-dropzone';
import MdSearch from 'react-ionicons/lib/MdSearch';
//import Snackbar from '@material-ui/core/Snackbar';
//import Slide from '@material-ui/core/Slide';
//import TextField from '@material-ui/core/TextField';
//import Button from '@material-ui/core/Button';

//import { CSVLink, CSVDownload } from "react-csv";
import firebase from 'firebase';
//import { ToastContainer, toast, Slide } from 'react-toastify';
//function CatalogAdmin(props) {

// import { makeStyles } from '@material-ui/core/styles';
// import Button from '@material-ui/core/Button';
// import Snackbar from '@material-ui/core/Snackbar';
// import IconButton from '@material-ui/core/IconButton';
//import CloseIcon from '@material-ui/icons/Close';

class UserAdminItem extends React.Component {




  constructor(props){
    super(props);


    this.state = {
      errorMessage:'',
      searchLimitValue:25,
      dateValue:'',
      dateFormatted:'',
      companyId:'',
      searchTerm:'',
      query: "",
      data: [],
      filteredData: [],
      totalData:[],
      name:'',
      phone:'',
      group:'',
      open: false,

    };
  }


  componentDidMount(){

    let group = this.props.location.state.group;
    let name = this.props.location.state.name;
    let phone = this.props.location.state.phone;

    this.setState({name:name});
    this.setState({phone:phone});
    this.setState({group:group});


    var today = new Date();
    var start = new Date();
    start.setHours(0,0,0,0);

    var end = new Date();
    end.setHours(23,59,59,999);

    var todayStartValue = Math.floor(start / 1000);
    var todayEndValue = Math.floor(end / 1000);


    var dd = today.getDate();
    var mm = today.getMonth()+1;
    var yyyy = today.getFullYear();
    if(dd<10){
        dd='0'+dd;
    }
    if(mm<10){
        mm='0'+mm;
    }
    var dateValue = yyyy.toString() + mm.toString() + dd.toString();
    var dateVal = yyyy.toString() + mm.toString();
    var dateToday = mm.toString() + '/' + dd.toString() + '/' + yyyy.toString();

    this.setState({dateValue:dateValue});
    this.setState({dateFormatted:dateToday});


    var setUser = '';
    firebase.auth().onAuthStateChanged((user) => {
      if (user){
        this.setState({isSignedIn:true});
        this.setState({email:user.email});

        this.getCompanyData()
        console.log(user);
      } else {
        this.setState({isSignedIn:false});

      }
    });

  }


  getCompanyData(){

    var user = firebase.auth().currentUser;
    console.log("OPEN USER " + user);

    if (user){
    firebase.database().ref('users/' + user.uid).once('value', (dataSnapshot) => {
        console.log("datasnap " + JSON.stringify(dataSnapshot));
        if (dataSnapshot.val().companyId!==undefined){
          this.setState({companyId: dataSnapshot.val().companyId});
        }
    }).then(() => {

        if (this.state.companyId!==undefined && this.state.companyId.trim()!==""){
          console.log("this.state.companyId " + this.state.companyId);
          var data = [];
           firebase.database().ref('company/' + this.state.companyId + '/groups/').once('value', (snap) => {
             snap.forEach((item) => {


               var name = '';
               if (item.hasChild("name")){ name = item.key; }


               data.push({
                 key:item.key,
                 name:name,
               });
             });

             this.setState({data:data});

             // const { query } = this.state;
             // const filteredData = data.filter(element => {
             //   // if (element.email.toLowerCase().includes(query.toLowerCase())){
             //   //   return element.email.toLowerCase().includes(query.toLowerCase());
             //   // } else if (element.name.toLowerCase().includes(query.toLowerCase())){
             //     return element.name.toLowerCase().includes(query.toLowerCase())
             //   //}
             //
             //
             // });
             //
             // this.setState({
             //   data,
             //   filteredData
             // });

           });
        }


      });
    }
  }

  handleSelectChange = option => {
      this.setState({group:option.target.value})
  }

  handleNameChange = event => {

     const name = event.target.value;
     this.setState({name:name});

   };

   handlePhoneChange = event => {
     // var subValue = event.target.value.replace('-','');
     // subValue = subValue.replace('-','');
     var subValue = event.target.value.split('-').join('');
     var newPosition = event.target.value.length-1;
     var nextPosition = event.target.value.length;
     var newValue = event.target.value.substring(newPosition, nextPosition);
     console.log("newPosition " + newPosition);
     console.log("nextPosition " + nextPosition);
     console.log("newValue " + newValue);
     console.log("this.state.phone.length " + this.state.phone.length);
     console.log("event.target.value.length " + event.target.value.length);
     //console.log("eval" + subValue);
      if (this.state.phone.length > event.target.value.length){
        this.setState({phone:event.target.value});
      } else if (parseInt(newValue)>=0) {
        console.log("xxxevent.target.value.length " + event.target.value.length);

        if (subValue.length<=10){
          var phone = event.target.value;
          if (subValue.length===3){
            phone += '-'
          }
          if (subValue.length===6){
            phone += '-'
          }
          console.log("xxxevent.target.value.length " + event.target.value.length);

          this.setState({phone:phone});
        }
      }


    };

    updateUser(){
      let {userId}  = this.props.location.state;
      console.log("props companyId " + this.state.companyId + "userId " + userId);

      console.log("update name  " + this.state.name + " phone " + this.state.phone +" group " + this.state.group);

      if (userId === null || userId === undefined || userId.toString().trim() === "" || this.state.companyId.toString().trim() === "" || this.state.companyId === undefined || this.state.companyId === null){
        alert("Missing data. Please reopen this page and retry.");
      } else {


        firebase.database().ref('company/' + this.state.companyId + '/users/' + userId).update({
          phone: this.state.phone,
          name: this.state.name,
          group:this.state.group,
        }).then(() => {
          alert("Updated Successfully.")
        });
      }
      //alert("Feature is still in progress.");
    }




  render(){

    const { handle } = this.props.match.params;
    const { email } = this.props.location.state;
    const { userId } = this.props.location.state;

    return (



        <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', marginBottom:'300px'}}>
          <p style={{margin:'20px', fontSize:'30px', fontWeight:'bold', }}>Edit User Info</p>



          <form noValidate autoComplete="off">

          <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>


          <div style={{width:'600px', marginTop:'20px'}}>
            <div style={{display:'flex', flexDirection:'row'}}>
            <label class="label">User ID</label><p style={{marginLeft:'10px'}}>{userId}</p>
            </div>
            <div style={{display:'flex', flexDirection:'row'}}>
            <label class="label">Email</label><p style={{marginLeft:'10px'}}>{email}</p>
            </div>
          </div>


          <input
          style={{width:'600px', marginTop:'20px'}}
            className="input is-medium"
            //type="tel"
            value={this.state.name}
            placeholder={"Name"}
            onChange={this.handleNameChange}
          />
          <input
          style={{width:'600px', marginTop:'20px'}}
            className="input is-medium"
            type="tel"
            value={this.state.phone}
            placeholder={"Phone"}
            onChange={this.handlePhoneChange}
          />


          <div style={{display:'flex', flexDirection:'row', width:'600px', marginTop:'10px'}}>
            <label style={{marginRight:'10px'}} class="label">Assigned Group</label>
              <div class="control">
              <div class="select">
                <select className="input is-small" onChange={this.handleSelectChange} value={this.state.group}>
                {this.state.data.map(i =>

                  <option>{i.name}</option>
                )}

                </select>
              </div>
            </div>
          </div>


            </div>


          </form>


            <div style={{width:'600px', display:'flex', flexDirection:'row', justifyContent:'flex-end'}}>
              <button onClick={() => this.updateUser()} className={"button"} style={{color:'black', backgroundColor:'#C4EEDE', width:'150px'}}>
                Update
              </button>
           </div>


        </div>


    );
  }

}

export default UserAdminItem;



// <TextField id="standard-secondary" label="Standard secondary" color="secondary" />
// <TextField
//   id="filled-secondary"
//   label="Filled secondary"
//   variant="filled"
//   color="secondary"
// />
