import React from "react";
import Section from "./../Section";
import SectionHeader from "./../SectionHeader";
import Clients from "./../Clients";
import "./styles.scss";

function ClientsSection(props) {
  return (
    <Section color={props.color} size={props.size}>
      <div className="container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          centered={true}
          size={3}
        />
        <Clients
          items={[
            // {
            //   name: "Airtable",
            //   image: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4b/Airtable_Logo.svg/163px-Airtable_Logo.svg.png",
            //   width: "150px"
            // },
            // {
            //   name: "Slack",
            //   image: "https://uploads.divjoy.com/logo-slack.svg",
            //   width: "120px"
            // },
            // {
            //   name: "Quickbooks",
            //   image: "https://abeon-hosting.com/images/intuit-logo-png-6.png",
            //   width: "140px",
            //
            // },
            // {
            //   name: "Mail Chimp",
            //   image: "https://www.privy.com/hs-fs/hubfs/Client%20Logos/Mailchimp%20Logo.png?width=400&name=Mailchimp%20Logo.png",
            //   width: "140px"
            // }
          ]}
        />
      </div>
    </Section>
  );
}

export default ClientsSection;
