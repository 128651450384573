import React from "react";
import ContentSection from "./../../components/ContentSection";
import FeaturesSectionFull from "./../../components/FeaturesSectionFull";
import { Link } from "./../../util/router.js";
import "./styles.scss";
// import CSVReader from 'react-csv-reader';
// import Dropzone from 'react-dropzone';
import MdSearch from 'react-ionicons/lib/MdSearch';
//import { CSVLink, CSVDownload } from "react-csv";
import firebase from 'firebase';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
//function CatalogAdmin(props) {
class OrderAdmin extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      errorMessage: '',
      dateValue: '',
      searchLimitValue: 25,
      dateFormatted: '',
      companyId: '',
      searchTerm: '',
      query: "",
      data: [],
      filteredData: [],
      totalData: [],
      selectedDate: ''
    };
  }


  componentDidMount() {

    var today = new Date();
    this.setState({ selectedDate: today });

    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    var dateValue = yyyy.toString() + mm.toString() + dd.toString();

    var dateToday = mm.toString() + '/' + dd.toString() + '/' + yyyy.toString();

    this.setState({ dateValue: dateValue });
    this.setState({ dateFormatted: dateToday });


    var setUser = '';
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.setState({ isSignedIn: true });
        this.setState({ email: user.email });

        this.getCompanyData()
        console.log(user);
      } else {
        this.setState({ isSignedIn: false });

      }
    });

  }


  getOrderData(dateString) {
    this.setState({ filteredData: [] });
    this.setState({ data: [] });
    this.setState({ totalData: [] });

    if (this.state.companyId !== undefined && this.state.companyId.trim() !== "") {
      console.log("this.state.companyId " + this.state.companyId);
      var data = [];
      //firebase.database().ref('company/' + this.props.companyId + '/fulfillments/').orderByChild("deliveryDate").equalTo(this.state.selectedStartDate).once('value', (orderSnapshot) => {
      firebase.database().ref('company/' + this.state.companyId + '/fulfillments/').orderByChild("deliveryDate").equalTo(dateString).once('value', (snap) => {
        snap.forEach((item) => {

          var orderedByCompanyName = '';
          if (item.hasChild("orderedByCompanyName")) { orderedByCompanyName = item.val().orderedByCompanyName; }

          var originalPaymentTotal = '';
          if (item.hasChild("paymentTotal")) { originalPaymentTotal = Number(item.val().paymentTotal).toFixed(2); }
          else if (item.hasChild("originalPaymentTotal")) { originalPaymentTotal = item.val().originalPaymentTotal; }

          var deliveryDate = '';
          if (item.hasChild("deliveryDate")) { deliveryDate = item.val().deliveryDate; }
          var status = '';
          if (item.hasChild("status")) { status = item.val().status; }
          var selectedRouteId = '';
          if (item.hasChild("selectedRouteId")) { selectedRouteId = item.val().selectedRouteId; }

          // items.push({key:arrayIndex, distance:milesDistance, internalOrder:true, name:companyAddress.val().name, isCashOnly:companyAddress.val().isCashOnly, additionalInfo:companyAddress.val().additionalInfo, phoneNumber:companyAddress.val().phoneNumber, orderedByCompany: order.val().orderedByCompany, orderId:order.key, addressLine: companyAddress.val().addressLine + ', ' + companyAddress.val().city, addressLine2: companyAddress.val().addressLine2, latlong:companyAddress.val().latlong  });
          var openState = false;
          data.push({
            key: item.key,
            itemId: item.key,
            name: orderedByCompanyName,
            originalPaymentTotal: originalPaymentTotal,
            deliveryDate: deliveryDate,
            openState: openState,
            status: status,
            selectedRouteId: selectedRouteId,
            dataItemJSON: item.toJSON()
          });
        });

        this.setState({ totalData: data });

        const { query } = this.state;
        const filteredData = data.filter(element => {
          // if (element.email.toLowerCase().includes(query.toLowerCase())){
          //   return element.email.toLowerCase().includes(query.toLowerCase());
          // } else if (element.name.toLowerCase().includes(query.toLowerCase())){
          return element.name.toLowerCase().includes(query.toLowerCase())
          //}


        });

        this.setState({
          data,
          filteredData
        });

      });
    }
  }

  getCompanyData() {

    var user = firebase.auth().currentUser;
    console.log("OPEN USER " + user);

    if (user) {
      firebase.database().ref('users/' + user.uid).once('value', (dataSnapshot) => {
        console.log("datasnap " + JSON.stringify(dataSnapshot));
        if (dataSnapshot.val().companyId !== undefined) {
          this.setState({ companyId: dataSnapshot.val().companyId });
        }
      }).then(() => {

        this.getOrderData(this.state.dateFormatted);

      });
    }
  }

  // search = (searchText) => {
  //   this.setState({searchTerm: searchText});
  //
  //   let filteredData = this.state.totalData.filter(function (item) {
  //     return item.email.toLowerCase().includes(searchText.toLowerCase());
  //   });
  //
  //
  //   console.log("FILTERED DATA " + JSON.stringify(filteredData));
  //   this.setState({filteredData: filteredData});
  //
  //   //this._updateFlatLists(filteredData);
  //
  // }


  handleInputChange = event => {
    const query = event.target.value;

    this.setState(prevState => {
      const filteredData = prevState.data.filter(element => {
        //return element.name.toLowerCase().includes(query.toLowerCase());
        if (element.name.toLowerCase().includes(query.toLowerCase())) {
          return element.name.toLowerCase().includes(query.toLowerCase());
        } else if (element.itemId.toLowerCase().includes(query.toLowerCase())) {
          return element.itemId.toLowerCase().includes(query.toLowerCase())
        }
        else if (element.status.toLowerCase().includes(query.toLowerCase())) {
          return element.status.toLowerCase().includes(query.toLowerCase())
        }

      });

      return {
        query,
        filteredData
      };
    });
  };


  handleDateChange = date => {


    var today = date;
    console.log("date " + date);
    this.setState({ selectedDate: today });

    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    var dateValue = yyyy.toString() + mm.toString() + dd.toString();

    var dateToday = mm.toString() + '/' + dd.toString() + '/' + yyyy.toString();

    this.setState({ dateValue: dateValue });
    this.setState({ dateFormatted: dateToday });

    this.getOrderData(dateToday);

  };

  openListItem(i) {
    //console.log("i.itemId 1 " + i.itemId);


    // for (var i = 0; i < this.state.filteredData.length; i++) {
    //   if (this.state.filteredData[i].itemId === i.itemId){
    //     console.log("i.itemId 2 " + i.itemId);
    //     if (i.openState){
    //       this.state.filteredData[i].openState = false;
    //
    //     } else {
    //       this.state.filteredData[i].openState = true;
    //
    //
    //     }
    //     //this.handleInputChange();
    //     //this.setState({filteredData: this.state.filteredData});
    //   }
    // }


  }

  render() {



    return (



      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginBottom: '300px' }}>
        <p style={{ margin: '20px', fontSize: '30px', fontWeight: 'bold', }}>Order Admin</p>

        <div style={{ display: 'flex', width: '1200px', marginBottom: '10px', marginTop: '10px', backgroundColor: '#fff', flexDirection: 'row', justifyContent: 'flex-start', borderRadius: '5px' }}>
          <p style={{ marginRight: '20px', fontWeight: 'bold', color: 'black' }}> For Delivery Date </p>
          <DatePicker
            className="input is-small"
            selected={this.state.selectedDate}
            onChange={this.handleDateChange}
          //style={{backgroundColor:'#eee', fontSize:'20px'}}
          //value={this.state.dateFormatted}
          //placeholderText={this.state.dateFormatted}
          />
        </div>

        <div className="searchForm">
          <form style={{ display: 'flex', width: '1200px', marginBottom: '10px', marginTop: '10px', backgroundColor: '#eee', flexDirection: 'row', justifyContent: 'flex-start', borderRadius: '5px' }}>
            <MdSearch style={{ justifyContent: 'center', alignSelf: 'center', alignItems: 'center', margin: '5px' }} fontSize="25px" color="gray" />
            <input
              id="searchInput"
              style={{ backgroundColor: '#eee', borderWidth: '0px', fontSize: '16px', padding: '5px', width: '1000px' }}
              autocomplete="off"
              placeholder="Search"
              value={this.state.query}
              onChange={this.handleInputChange}
            />
          </form>

          <div style={{ padding: '5px', width: '1200px' }}>
            <div style={{ display: 'flex', height: '30px', flexDirection: 'row', alignItems: 'center', marginTop: '20px', textDecoration: 'none', backgroundColor: 'none', color: 'black' }}>
              <p style={{ marginLeft: '50px', width: '300px', fontWeight: 'bold', color: 'black' }}>Customer</p><p style={{ marginLeft: '50px', width: '200px', fontWeight: 'bold', color: 'black' }}>Delivery Date</p><p style={{ marginLeft: '50px', width: '200px', fontWeight: 'bold', color: 'black' }}>Payment Total</p><p style={{ marginLeft: '50px', width: '200px', fontWeight: 'bold', color: 'black' }}>Status</p>
            </div>
          </div>


          <div style={{ padding: '5px', width: '1200px' }}>{this.state.filteredData.slice(0, this.state.searchLimitValue).map(i =>
            <Link to={{ pathname: "/orderAdminItem", state: { itemId: i.itemId, item: i, selectedDate: this.state.selectedDate } }}   >
              <a>

                <div id="userLink" style={{ display: 'flex', height: '30px', flexDirection: 'row', alignItems: 'center', marginTop: '20px', textDecoration: 'none', backgroundColor: 'none', color: 'black' }}>

                  <p style={{ marginLeft: '50px', width: '300px' }}>{i.name}</p><p style={{ marginLeft: '50px', width: '200px' }}>{i.deliveryDate}</p><p style={{ marginLeft: '50px', width: '200px' }}>${i.originalPaymentTotal}</p><p style={{ marginLeft: '50px', width: '200px' }}>{i.status}</p>

                </div>
              </a></Link>)}
          </div>

        </div>




      </div>


    );
  }

}

export default OrderAdmin;
