import React from "react";
import Section from "./../Section";
import SectionHeader from "./../SectionHeader";
import Features from "./../Features";
import "./styles.scss";

import synk_accounting_lightgreen from "../../assets/synk_accounting_lightgreen.svg";
import synk_customerinsights_lightgreen from "../../assets/synk_customerinsights_lightgreen.svg";
import synk_dashboard_lightgreen from "../../assets/synk_dashboard_lightgreen.svg";
import synk_integrate_lightgreen from "../../assets/synk_integrate_lightgreen.svg";
import synk_route_lightgreen from "../../assets/synk_route_lightgreen.svg";
import synk_sales_lightgreen from "../../assets/synk_sales_lightgreen.svg";
import synk_shopping_lightgreen from "../../assets/synk_shopping_lightgreen.svg";
import synk_target_lightgreen from "../../assets/synk_target_lightgreen.svg";
import synk_warehouse_lightgreen from "../../assets/synk_warehouse_lightgreen.svg";


function FeaturesSection(props) {
  return (
    <Section color={props.color} size={props.size}>
      <div className="container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          centered={true}
          size={3}
        />
        <Features
          items={[
            {
              title: "Business Development",
              description:
                "Create a targeted list of prospects enriched with phone and address data in minutes.",
                image: synk_target_lightgreen
                //image: "https://firebasestorage.googleapis.com/v0/b/osmo-a9de7.appspot.com/o/appGraphics%2Ffocus_on_target.svg?alt=media&token=01f4e2f0-38d2-4849-a68b-4c98f8e25309"
            },
            {
              title: "Sales Tracking",
              description:
                "Track and optimize sales for phone, email, direct mail, and field channels.",
              image: synk_sales_lightgreen
              //image:"https://firebasestorage.googleapis.com/v0/b/osmo-a9de7.appspot.com/o/appGraphics%2Fcustomer_success.svg?alt=media&token=0580e2fd-d018-4e1e-b6a6-ae96bf425017"
            },
            {
              title: "B2B eCommerce",
              description:
                "Place orders internally via call sheets or accept them directly from your customers.",
                image: synk_shopping_lightgreen
              //image: "https://firebasestorage.googleapis.com/v0/b/osmo-a9de7.appspot.com/o/appGraphics%2Fshopping_online.svg?alt=media&token=06519ab1-86e0-43d1-a263-f8056eeff5ff"
            },
            {
              title: "Route Optimization",
              description:
                "Get an automatically optimized list of stops for field and order delivery routes.",
                image: synk_route_lightgreen
              //image: "https://firebasestorage.googleapis.com/v0/b/osmo-a9de7.appspot.com/o/appGraphics%2Fnavigation.svg?alt=media&token=70f00848-135b-42eb-8811-f815fa6de89c"
            }
          ]}
        />
      </div>
    </Section>
  );
}

export default FeaturesSection;
