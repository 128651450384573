import React from "react";
import ContentSection from "./../../components/ContentSection";
import FeaturesSectionFull from "./../../components/FeaturesSectionFull";
import "./styles.scss";

function AboutPage(props) {
  return (
    <>
      <ContentSection
        color="primary"
        size="large"
        title="Software AND a Service"
        subtitle="Synk is a suite of apps designed specifically for food and beverage distributors. Common use cases are illustrated below."
      />
      <FeaturesSectionFull
        color="white"
        size="medium"
        title="Features"
        subtitle=""
      />
    </>
  );
}

export default AboutPage;


/*
<TeamBiosSection
  color="white"
  size="medium"
  title="Meet the Team"
  subtitle=""
/>

#C4EEDE
#8befd6

*/
