import React from "react";
import ContentSection from "./../../components/ContentSection";
import FeaturesSectionFull from "./../../components/FeaturesSectionFull";
import "./styles.scss";
// import CSVReader from 'react-csv-reader';
// import Dropzone from 'react-dropzone';
import MdSearch from 'react-ionicons/lib/MdSearch';
//import { CSVLink, CSVDownload } from "react-csv";
import firebase from 'firebase';
//function CatalogAdmin(props) {
class CustomerAdmin extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      errorMessage:'',
      loading:null,
      searchLimitValue:25,
      dateValue:'',
      dateFormatted:'',
      companyId:'',
      searchTerm:'',
      query: "",
      data: [],
      filteredData: [],
      totalData:[],
    };
  }


  componentDidMount(){

    var today = new Date();
    var start = new Date();
    start.setHours(0,0,0,0);

    var end = new Date();
    end.setHours(23,59,59,999);

    var todayStartValue = Math.floor(start / 1000);
    var todayEndValue = Math.floor(end / 1000);


    var dd = today.getDate();
    var mm = today.getMonth()+1;
    var yyyy = today.getFullYear();
    if(dd<10){
        dd='0'+dd;
    }
    if(mm<10){
        mm='0'+mm;
    }
    var dateValue = yyyy.toString() + mm.toString() + dd.toString();
    var dateVal = yyyy.toString() + mm.toString();
    var dateToday = mm.toString() + '/' + dd.toString() + '/' + yyyy.toString();

    this.setState({dateValue:dateValue});
    this.setState({dateFormatted:dateToday});


    var setUser = '';
    firebase.auth().onAuthStateChanged((user) => {
      if (user){
        this.setState({isSignedIn:true});
        this.setState({email:user.email});

        this.getCompanyData()
        console.log(user);
      } else {
        this.setState({isSignedIn:false});

      }
    });

  }


  getCompanyData(){
    this.setState({loading:true});
    var user = firebase.auth().currentUser;
    console.log("OPEN USER " + user);

    if (user){
    firebase.database().ref('users/' + user.uid).once('value', (dataSnapshot) => {
        console.log("datasnap " + JSON.stringify(dataSnapshot));
        if (dataSnapshot.val().companyId!==undefined){
          this.setState({companyId: dataSnapshot.val().companyId});
        }
    }).then(() => {

        if (this.state.companyId!==undefined && this.state.companyId.trim()!==""){
          console.log("this.state.companyId " + this.state.companyId);
          var data = [];
           firebase.database().ref('company/' + this.state.companyId + '/customers/').once('value', (snap) => {
             snap.forEach((user) => {

               var name = '';
               if (user.hasChild("name")){ name = user.val().name; }

               var isInternal = '';
               if (user.hasChild("isInternal")){ isInternal = user.val().isInternal; }

               var isProspect = '';
               if (user.hasChild("isProspect")){ isProspect = user.val().isProspect; }

               var addressLine = '';
               if (user.hasChild("addressLine")){ addressLine = user.val().addressLine; }

               // var additionalInfo = '';
               // if (user.hasChild("additionalInfo")){ additionalInfo = user.val().additionalInfo; }

               var city = '';
               if (user.hasChild("city")){ city = user.val().city; }

               var state = '';
               if (user.hasChild("state")){ state = user.val().state; }

               var zip5 = '';
               if (user.hasChild("zip5")){ state = user.val().zip5; }

               var lastDeliveryDate = '';
               if (user.hasChild("lastDeliveryDate")){ lastDeliveryDate = user.val().lastDeliveryDate; }



               var displayAddress = addressLine + ', ' + city + ', ' + state + ' ' + zip5;


               data.push({
                 key:user.key,
                 name:name,
                 displayAddress:displayAddress,
                 isInternal:isInternal,
                 isProspect:isProspect,
                 lastDeliveryDate:lastDeliveryDate,
                 //additionalInfo:additionalInfo
               });
             });

             this.setState({totalData:data});

             const { query } = this.state;
             const filteredData = data.filter(element => {
                 return element.name.toLowerCase().includes(query.toLowerCase())
             });

             this.setState({
               data,
               filteredData
             });
             this.setState({loading:false});
           }).catch((err) => {
             alert("Error retrieving customer data. Contact Support");
             this.setState({loading:false});
           });
        }
      });
    }
  }



  handleInputChange = event => {
     const query = event.target.value;

     this.setState(prevState => {
       const filteredData = prevState.data.filter(element => {
         //return element.name.toLowerCase().includes(query.toLowerCase());
         if (element.name.toLowerCase().includes(query.toLowerCase())){
           return element.name.toLowerCase().includes(query.toLowerCase());
         }
         else if (element.displayAddress.toLowerCase().includes(query.toLowerCase())){
           return element.displayAddress.toLowerCase().includes(query.toLowerCase())
         }
         else if (element.key.toLowerCase().includes(query.toLowerCase())){
           return element.key.toLowerCase().includes(query.toLowerCase())
         }
       });

       return {
         query,
         filteredData
       };
     });
   };

  render(){



    return (



        <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
          <p style={{margin:'20px', fontSize:'30px', fontWeight:'bold', }}>Customer Admin</p>









              {(this.state.loading===false) &&
                <div  className="searchForm">
                   <form  style={{display:'flex',  width:'1200px', marginBottom:'10px', marginTop:'10px', backgroundColor:'#eee', flexDirection:'row', justifyContent:'flex-start', borderRadius:'5px'}}>
                    <MdSearch style={{justifyContent:'center', alignSelf:'center', alignItems:'center', margin:'5px'}} fontSize="25px" color="gray" />
                     <input
                       id="searchInput"
                       style={{backgroundColor:'#eee', borderWidth:'0px', fontSize:'16px', padding:'5px', width:'1000px'}}
                       autocomplete="off"
                       placeholder="Search"
                       value={this.state.query}
                       onChange={this.handleInputChange}
                     />
                   </form>

                <div style={{padding:'5px', width:'1200px'}}>
                <div style={{display:'flex', height:'30px', flexDirection:'row', alignItems:'center', marginTop:'20px', textDecoration:'none', backgroundColor:'none', color:'black'}}>
                 <p style={{marginLeft:'50px', width:'300px', fontWeight:'bold', color:'black'}}>Name</p><p style={{ marginLeft:'50px', width:'200px', fontWeight:'bold', color:'black'}}>Address</p><p style={{marginLeft:'50px', width:'200px', fontWeight:'bold', color:'black'}}>Last Delivery</p><p style={{marginLeft:'50px', width:'200px', fontWeight:'bold', color:'black'}}>Customer ID</p>
                 </div>
                 </div>

                <div style={{padding:'5px', width:'1200px'}}>{this.state.filteredData.slice(0, this.state.searchLimitValue).map(i =>
                  <a >
                  <div id="userLink" style={{display:'flex', height:'30px', alignItems:'center', flexDirection:'row', marginTop:'20px', textDecoration:'none', backgroundColor:'none', color:'black'}}>
                   <p style={{marginLeft:'50px', width:'250px'}}>{i.name}</p><p style={{marginLeft:'50px', width:'300px'}}>{i.displayAddress}</p><p style={{marginLeft:'50px', width:'150px'}}>{i.lastDeliveryDate}</p><p style={{marginLeft:'50px', width:'200px'}}>{i.key}</p>
                   </div>
                   </a>)}
                   </div>
                   </div>


              }
              {(this.state.loading===true) &&
                <div style={{padding:'5px', width:'1200px'}}>

                  <div  style={{display:'flex', height:'30px', marginTop:'50px', alignItems:'center', flexDirection:'row', justifyContent:'center',  textDecoration:'none', backgroundColor:'none', color:'black'}}>
                   <p> Loading data... </p>
                   </div>

                   </div>
              }









        </div>


    );
  }

}

export default CustomerAdmin;
