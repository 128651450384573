import React, { useState, useEffect, useContext, createContext } from "react";
import queryString from "query-string";
import * as firebase from "firebase/app";
import "firebase/auth";

// Replace with your own Firebase credentials
// apiKey: "AIzaSyBkkFF0XhNZeWuDmOfEhsgdfX1VBG7WTas",
// authDomain: "divjoy-demo.firebaseapp.com",
// projectId: "divjoy-demo",
// appID: "divjoy-demo"
firebase.initializeApp({
  apiKey: "AIzaSyCTosRb5GyRUBkp3khDqi5moRkfoNEi-Lg",
  authDomain: "osmo-a9de7.firebaseapp.com",
  databaseURL: "https://osmo-a9de7.firebaseio.com",
  projectId: "osmo-a9de7",
  storageBucket: "osmo-a9de7.appspot.com",
  messagingSenderId: "1076685514475"
});

const authContext = createContext();

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... update when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [user, setUser] = useState(null);

  const signin = (email, password) => {
    return firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(response => {
        setUser(response.user);
        return response.user;
      });
  };

  const signup = (email, password) => {
    return firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(response => {
        setUser(response.user);
        return response.user;
      });
  };

  // const signup = (email) => {
  //   return firebase
  //     .database()
  //     .ref('system/emailNewUser/')
  //     .push({
  //       email: email,
  //     }).then(() =>{
  //       return "SUCCESS";
  //     });
  // };

  const signout = () => {
    return firebase
      .auth()
      .signOut()
      .then(() => {
        setUser(false);
      });
  };

  const sendPasswordResetEmail = email => {
    return firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        return true;
      });
  };

  const confirmPasswordReset = (password, code) => {
    // Get code from query string object
    const resetCode = code || getFromQueryString("oobCode");

    return firebase
      .auth()
      .confirmPasswordReset(resetCode, password)
      .then(() => {
        return true;
      });
  };


  const emailSubscriberRegistration = (email) => {
    return firebase
      .database()
      .ref('system/emailSubscribers/')
      .push({
        email: email,
      }).then(() =>{
        return "SUCCESS";
      });
  };


  // Subscribe to user on mount
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      if (user) {
        setUser(user);
      } else {
        setUser(false);
      }
    });

    // Subscription unsubscribe function
    return () => unsubscribe();
  }, []);

  return {
    user,
    signin,
    signup,
    signout,
    sendPasswordResetEmail,
    confirmPasswordReset
  };
}

const getFromQueryString = key => {
  return queryString.parse(window.location.search)[key];
};
