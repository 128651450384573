import * as firebase from "firebase/app";
import "firebase/database";


function subscribe(data) {
  // return fetch("/api/newsletter/subscribe", {
  //   method: "POST",
  //   headers: {
  //     "Content-Type": "application/json"
  //   },
  //   body: JSON.stringify(data)
  // }).then(r => r.json());

    return firebase
      .database()
      .ref('system/emailSubscribers/')
      .push({
        data,
      }).then(() =>{
        return "SUCCESS";
      });



}

export default { subscribe };
