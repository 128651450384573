import React, { useState } from "react";
import NavbarContainer from "./../NavbarContainer";
import { Link } from "./../../util/router.js";
import { useAuth } from "./../../util/auth.js";
import LogoAndroid from 'react-ionicons/lib/LogoAndroid';
import LogoApple from 'react-ionicons/lib/LogoApple';
import "./styles.scss";


function Navbar(props) {
  const auth = useAuth();
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <NavbarContainer spaced={props.spaced}
    color={props.color}
    
    //color="blue"
    //style={{backgroundColor:'#eee'}}
    >
      <div  className="container">
        <div className="navbar-brand">
          <div className="navbar-item">
            <Link to="/">
              <img className="image" src={"https://firebasestorage.googleapis.com/v0/b/osmo-a9de7.appspot.com/o/appGraphics%2FGroup.svg?alt=media&token=1b8acb8f-584e-4320-bd20-eaef567cf3a9"} alt="Logo"  />
            </Link>
          </div>
          <div
            className={"navbar-burger burger" + (menuOpen ? " is-active" : "")}
            onClick={() => setMenuOpen(!menuOpen)}
          >
            <span />
            <span />
            <span />
          </div>
        </div>
        <div className={"navbar-menu" + (menuOpen ? " is-active" : "")}>
          <div className="navbar-end">
            {auth.user && (
              <div className="navbar-item has-dropdown is-hoverable">
                <Link className="navbar-link" to="/">
                  Account
                </Link>
                <div className="navbar-dropdown is-boxed">
                  <Link className="navbar-item" to="/dashboard">
                    Dashboard
                  </Link>
                  <Link className="navbar-item" to="/account">
                    My Account
                  </Link>
                  <Link className="navbar-item" to="/support">
                    Support
                  </Link>
                  <Link
                    className="navbar-item"
                    to="/signout"
                    onClick={e => {
                      e.preventDefault();
                      auth.signout();
                    }}
                  >
                    Sign out
                  </Link>
                </div>
              </div>
            )}

            {!auth.user && (
              <div style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>

              <a
                href="https://play.google.com/store/apps/details?id=app.synk.www"
                target="_blank"
                className="navbar-item"
                rel="noopener noreferrer"
                style={{marginRight:'40px', marginTop:'0px'}}
              >
                  <LogoAndroid fontSize="25px" color="#000" />
              </a>


              <a
                href="https://apps.apple.com/us/app/synk-mobile-commerce/id1443651578?ls=1"
                target="_blank"
                className="navbar-item"
                rel="noopener noreferrer"
                style={{marginRight:'40px', marginTop:'0px', }}
              >
                  <LogoApple fontSize="25px" color="#000" />

              </a>

              <Link style={{fontWeight:'bold'}} className="navbar-item" to="/signin">
                Log In
              </Link>


              </div>
            )}
          </div>
        </div>
      </div>
    </NavbarContainer>
  );
}

export default Navbar;
