import React from "react";
import ContentSection from "./../../components/ContentSection";
import FeaturesSectionFull from "./../../components/FeaturesSectionFull";
import "./styles.scss";
import DatePicker from "react-datepicker";
// import CSVReader from 'react-csv-reader';
// import Dropzone from 'react-dropzone';
import MdSearch from 'react-ionicons/lib/MdSearch';
//import TextField from '@material-ui/core/TextField';
//import Button from '@material-ui/core/Button';

//import { CSVLink, CSVDownload } from "react-csv";
import firebase from 'firebase';
//function CatalogAdmin(props) {
class CatalogAdminItem extends React.Component {

  constructor(props){
    super(props);


    this.state = {
      errorMessage:'',
      searchLimitValue:25,
      dateValue:'',
      dateFormatted:'',
      companyId:'',
      searchTerm:'',
      query: "",
      data: [],
      filteredData: [],
      totalData:[],
      name:'',
      phone:'',
      group:'',
      routeName:'',
      item:[],
      status:'',
      itemName:'',
      itemImage:'',
      itemId:'',
      casePrice:'',
      caseSize:'',
      caseCost:'',
      isPiece:'',
      isHidden:'',
      hasPromo:'',
      promoPrice:'',
      caseHeight:'',
      caseWidth:'',
      caseDepth:'',
      itemAisle:'',
      itemBrand:'',
      itemCategory:'',
      itemDescription:'',
      itemImage:'',
      itemSupplier:'',
      itemWeight:'',
      keywords:'',
      palletSize:'',
      tierSize:'',
      sortId:'',
      supplierItemNumber:'',
      upc:'',
      upcType:'',
      isPieceTrue:false,
      isPieceFalse:false,
      isHiddenTrue:false,
      isHiddenFalse:false,
      isPromoTrue:false,
      isPromoFalse:false,
    };
  }


  componentDidMount(){

    let item = this.props.location.state.item;
    // let name = this.props.location.state.name;
    // let phone = this.props.location.state.phone;

    this.setState({item:item});
    // this.setState({itemName:item.itemName});
    // this.setState({itemImage:item.itemImage});

    this.setState({
      itemName:item.itemName,
      itemImage:item.itemImage,
      itemId:item.itemId,
      casePrice:item.casePrice,
      caseSize:item.caseSize,
      caseCost:item.caseCost,
      isPiece:item.isPiece,
      isHidden:item.isHidden,
      hasPromo:item.hasPromo,
      promoPrice:item.promoPrice,
      caseHeight:item.caseHeight,
      caseWidth:item.caseWidth,
      caseDepth:item.caseDepth,
      itemAisle:item.itemAisle,
      itemBrand:item.itemBrand,
      itemCategory:item.itemCategory,
      itemDescription:item.itemDescription,
      itemSupplier:item.itemSupplier,
      itemWeight:item.itemWeight,
      keywords:item.keywords,
      palletSize:item.palletSize,
      tierSize:item.tierSize,
      sortId:item.sortId,
      supplierItemNumber:item.supplierItemNumber,
      upc:item.upc,
      upcType:item.upcType,
    });
    //this.setState({status:item.status});
    // this.setState({phone:phone});
    // this.setState({group:group});
    if (item.isPiece.toString().toUpperCase() === "TRUE"){
      this.setState({isPieceTrue:true, isPieceFalse:false});
      //document.getElementById("promoCheck").checked = true;
    } else {
      this.setState({isPieceTrue:false, isPieceFalse:true});
      //document.getElementById("promoCheck").checked = false;
    }

    if (item.isHidden.toString().toUpperCase() === "TRUE"){
      this.setState({isHiddenTrue:true, isHiddenFalse:false});
    } else {
      this.setState({isHiddenTrue:false, isHiddenFalse:true});
    }

    if (item.hasPromo.toString().toUpperCase() === "TRUE"){
      console.log("is promo true");
      this.setState({isPromoTrue:true, isPromoFalse:false});
    } else {
      console.log("is promo false");
      this.setState({isPromoTrue:false, isPromoFalse:true});
    }

    var today = new Date();
    this.setState({selectedDate:today});
    var start = new Date();
    start.setHours(0,0,0,0);

    var end = new Date();
    end.setHours(23,59,59,999);

    var todayStartValue = Math.floor(start / 1000);
    var todayEndValue = Math.floor(end / 1000);


    var dd = today.getDate();
    var mm = today.getMonth()+1;
    var yyyy = today.getFullYear();
    if(dd<10){
        dd='0'+dd;
    }
    if(mm<10){
        mm='0'+mm;
    }
    var dateValue = yyyy.toString() + mm.toString() + dd.toString();
    var dateVal = yyyy.toString() + mm.toString();
    var dateToday = mm.toString() + '/' + dd.toString() + '/' + yyyy.toString();

    this.setState({dateValue:dateValue});
    this.setState({dateFormatted:dateToday});


    var setUser = '';
    firebase.auth().onAuthStateChanged((user) => {
      if (user){
        this.setState({isSignedIn:true});
        this.setState({email:user.email});

        this.getCompanyData()
        console.log(user);
      } else {
        this.setState({isSignedIn:false});

      }
    });

  }


  getCompanyData(){

    var user = firebase.auth().currentUser;
    console.log("OPEN USER " + user);

    if (user){
    firebase.database().ref('users/' + user.uid).once('value', (dataSnapshot) => {
        console.log("datasnap " + JSON.stringify(dataSnapshot));
        if (dataSnapshot.val().companyId!==undefined){
          this.setState({companyId: dataSnapshot.val().companyId});
        }
    }).then(() => {

        if (this.state.companyId!==undefined && this.state.companyId.trim()!==""){
          console.log("this.state.companyId " + this.state.companyId);
          var data = [];
           //firebase.database().ref('company/' + this.state.companyId + '/routes/').once('value', (snap) => {
        }


      });
    }
  }


  itemName = event => { this.setState({itemName:event.target.value}); };
  itemImage = event => { this.setState({itemImage:event.target.value}); };
  casePrice = event => { this.setState({casePrice:event.target.value}); };
  caseSize = event => { this.setState({caseSize:event.target.value}); };
  caseCost = event => { this.setState({caseCost:event.target.value}); };
  isPiece = event => { this.setState({isPiece:event.target.value}); };
  isHidden = event => { this.setState({isHidden:event.target.value}); };
  hasPromo = event => { this.setState({hasPromo:event.target.value}); };
  promoPrice = event => { this.setState({promoPrice:event.target.value}); };
  caseHeight = event => { this.setState({caseHeight:event.target.value}); };
  caseWidth = event => { this.setState({caseWidth:event.target.value}); };
  caseDepth = event => { this.setState({caseDepth:event.target.value}); };
  itemAisle = event => { this.setState({itemAisle:event.target.value}); };
  itemBrand = event => { this.setState({itemBrand:event.target.value}); };
  itemCategory = event => { this.setState({itemCategory:event.target.value}); };
  itemDescription = event => { this.setState({itemDescription:event.target.value}); };
  itemSupplier = event => { this.setState({itemSupplier:event.target.value}); };
  itemWeight = event => { this.setState({itemImage:event.target.value}); };
  keywords = event => { this.setState({keywords:event.target.value}); };
  palletSize = event => { this.setState({palletSize:event.target.value}); };
  tierSize = event => { this.setState({tierSize:event.target.value}); };
  supplierItemNumber = event => { this.setState({supplierItemNumber:event.target.value}); };
  upc = event => { this.setState({upc:event.target.value}); };
  upcType = event => { this.setState({upcType:event.target.value}); };




    updateItem(){
      //alert("Feature is still in progress.");

      console.log("this.state.itemId" + this.state.itemId);
      console.log("this.state.itemName" + this.state.itemName);
      console.log("this.state.companyId" + this.state.companyId);


    }


    setIsPromo(event) {
      console.log(event.target.value);
    }
    setIsPiece(event) {
      console.log(event.target.value);
    }
    setIsHidden(event) {
      console.log(event.target.value);
    }

  render(){

    return (



        <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', marginBottom:'300px'}}>
          <p style={{margin:'20px', fontSize:'30px', fontWeight:'bold', }}>Edit Item Info</p>



          <form noValidate autoComplete="off">

          <div style={{display:'flex', width:'800px', flexDirection:'row', justifyContent:'flex-start'}}>
          <div style={{display:'flex', width:'200px', marginTop:'20px', justifyContent:'center'}}>
            <img style={{marginRight:'50px', width:'150px', height:'150px'}} src={this.state.itemImage}  />
          </div>

          <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>

          <div style={{width:'600px', marginTop:'20px'}}>



            <div style={{marginTop:'10px', display:'flex', flexDirection:'row'}}>
            <label  style={{width:'200px'}} class="label">ITEM ID</label>
            <p style={{marginLeft:'10px'}} class="label">{this.state.item.itemId}</p>
            </div>

            <div style={{display:'flex', marginTop:'10px', alignItems:'center', flexDirection:'row'}}>
              <label  style={{width:'200px'}} class="label">ITEM NAME</label>
            <input
            style={{width:'400px', marginLeft:'10px'}}
              className="input is-medium"
              //type="tel"
              value={this.state.itemName}
              //placeholder={"Item Name"}
              onChange={this.itemName}
            />
            </div>

            <div style={{display:'flex', marginTop:'10px', alignItems:'center', flexDirection:'row'}}>
            <div style={{display:'flex', alignItems:'center', flexDirection:'row', width:'200px'}}>
              <label  class="label">ITEM IMAGE</label>
            </div>
            <input
            style={{width:'400px', marginLeft:'10px'}}
              className="input is-medium"
              //type="tel"
              value={this.state.itemImage}
              //placeholder={"Item Image"}
              onChange={this.itemImage}
            />
            </div>

            <div style={{display:'flex', marginTop:'10px', alignItems:'center', flexDirection:'row'}}>
              <label  style={{width:'200px'}} class="label">CASE COST</label>
            <input
            style={{width:'400px', marginLeft:'10px'}}
              className="input is-medium"
              //type="tel"
              value={this.state.caseCost}
              //placeholder={"Item Name"}
              onChange={this.caseCost}
            />
            </div>

            <div style={{display:'flex', marginTop:'10px', alignItems:'center', flexDirection:'row'}}>
              <label  style={{width:'200px'}} class="label">CASE PRICE</label>
            <input
            style={{width:'400px', marginLeft:'10px'}}
              className="input is-medium"
              //type="tel"
              value={this.state.casePrice}
              //placeholder={"Item Name"}
              onChange={this.casePrice}
            />
            </div>

            <div style={{display:'flex', marginTop:'10px', alignItems:'center', flexDirection:'row'}}>
              <label  style={{width:'200px'}} class="label">CASE SIZE</label>
            <input
            style={{width:'400px', marginLeft:'10px'}}
              className="input is-medium"
              //type="tel"
              value={this.state.caseSize}
              //placeholder={"Case Size"}
              onChange={this.caseSize}
            />
            </div>



            <div style={{display:'flex', marginTop:'10px', alignItems:'center', flexDirection:'row'}}>
              <label  style={{width:'200px'}} class="label">IS PIECE</label>
              <div onChange={this.setIsPiece.bind(this)}  style={{width:'400px'}} class="control">

              <label class="radio">
                <input checked={ this.state.isPiece.toString().toUpperCase() === "TRUE" }  style={{margin:'10px'}} value="TRUE" type="radio"  name="piece" />
                Yes
              </label>
              <label style={{marginLeft:'30px'}} class="radio">
                <input checked={ this.state.isPiece.toString().toUpperCase() === "FALSE" }  style={{margin:'10px'}} value="FALSE" type="radio" name="piece" />
                No
              </label>

              </div>
            </div>

            <div style={{display:'flex', marginTop:'10px', alignItems:'center', flexDirection:'row'}}>
              <label  style={{width:'200px'}} class="label">IS PROMO</label>
              <div onChange={this.setIsPromo.bind(this)}  style={{width:'400px'}} class="control">

              <label class="radio">
                <input checked={ this.state.hasPromo.toString().toUpperCase() === "TRUE" }  style={{margin:'10px'}} value="TRUE" type="radio"  name="promo" />
                Yes
              </label>
              <label style={{marginLeft:'30px'}} class="radio">
                <input checked={ this.state.hasPromo.toString().toUpperCase() === "FALSE" }  style={{margin:'10px'}} value="FALSE" type="radio" name="promo" />
                No
              </label>

              </div>
            </div>

            <div style={{display:'flex', marginTop:'10px', alignItems:'center', flexDirection:'row'}}>
              <label  style={{width:'200px'}} class="label">IS HIDDEN</label>
              <div onChange={this.setIsHidden.bind(this)}  style={{width:'400px'}} class="control">

              <label class="radio">
                <input checked={ this.state.isHidden.toString().toUpperCase() === "TRUE" }  style={{margin:'10px'}} value="TRUE" type="radio"  name="hidden" />
                Yes
              </label>
              <label style={{marginLeft:'30px'}} class="radio">
                <input checked={ this.state.isHidden.toString().toUpperCase() === "FALSE" }  style={{margin:'10px'}} value="FALSE" type="radio" name="hidden" />
                No
              </label>

              </div>
            </div>




            <div style={{display:'flex', marginTop:'10px', alignItems:'center', flexDirection:'row'}}>
              <label  style={{width:'200px'}} class="label">PROMO PRICE</label>
            <input
            style={{width:'400px', marginLeft:'10px'}}
              className="input is-medium"
              //type="tel"
              value={this.state.promoPrice}
              //placeholder={"Case Size"}
              onChange={this.promoPrice}
            />
            </div>

            <div style={{display:'flex', marginTop:'10px', alignItems:'center', flexDirection:'row'}}>
              <label  style={{width:'200px'}} class="label">CASE WIDTH</label>
            <input
            style={{width:'400px', marginLeft:'10px'}}
              className="input is-medium"
              //type="tel"
              value={this.state.caseWidth}
              //placeholder={"Case Size"}
              onChange={this.caseWidth}
            />
            </div>

            <div style={{display:'flex', marginTop:'10px', alignItems:'center', flexDirection:'row'}}>
              <label  style={{width:'200px'}} class="label">CASE HEIGHT</label>
            <input
            style={{width:'400px', marginLeft:'10px'}}
              className="input is-medium"
              //type="tel"
              value={this.state.caseHeight}
              //placeholder={"Case Size"}
              onChange={this.caseHeight}
            />
            </div>

            <div style={{display:'flex', marginTop:'10px', alignItems:'center', flexDirection:'row'}}>
              <label  style={{width:'200px'}} class="label">CASE DEPTH</label>
            <input
            style={{width:'400px', marginLeft:'10px'}}
              className="input is-medium"
              //type="tel"
              value={this.state.caseDepth}
              //placeholder={"Case Size"}
              onChange={this.caseDepth}
            />
            </div>


            <div style={{display:'flex', marginTop:'10px', alignItems:'center', flexDirection:'row'}}>
              <label  style={{width:'200px'}} class="label">ITEM AISLE</label>
            <input
            style={{width:'400px', marginLeft:'10px'}}
              className="input is-medium"
              //type="tel"
              value={this.state.itemAisle}
              //placeholder={"Case Size"}
              onChange={this.itemAisle}
            />
            </div>

            <div style={{display:'flex', marginTop:'10px', alignItems:'center', flexDirection:'row'}}>
              <label  style={{width:'200px'}} class="label">ITEM BRAND</label>
            <input
            style={{width:'400px', marginLeft:'10px'}}
              className="input is-medium"
              //type="tel"
              value={this.state.itemBrand}
              //placeholder={"Case Size"}
              onChange={this.itemBrand}
            />
            </div>

            <div style={{display:'flex', marginTop:'10px', alignItems:'center', flexDirection:'row'}}>
              <label  style={{width:'200px'}} class="label">ITEM CATEGORY</label>
            <input
            style={{width:'400px', marginLeft:'10px'}}
              className="input is-medium"
              //type="tel"
              value={this.state.itemCategory}
              //placeholder={"Case Size"}
              onChange={this.itemCategory}
            />
            </div>

            <div style={{display:'flex', marginTop:'10px', alignItems:'center', flexDirection:'row'}}>
              <label  style={{width:'200px'}} class="label">ITEM DESCRIPTION</label>
            <input
            style={{width:'400px', marginLeft:'10px'}}
              className="input is-medium"
              //type="tel"
              value={this.state.itemDescription}
              //placeholder={"Case Size"}
              onChange={this.itemDescription}
            />
            </div>

            <div style={{display:'flex', marginTop:'10px', alignItems:'center', flexDirection:'row'}}>
              <label  style={{width:'200px'}} class="label">ITEM SUPPLIER</label>
            <input
            style={{width:'400px', marginLeft:'10px'}}
              className="input is-medium"
              //type="tel"
              value={this.state.itemSupplier}
              //placeholder={"Case Size"}
              onChange={this.itemSupplier}
            />
            </div>


            <div style={{display:'flex', marginTop:'10px', alignItems:'center', flexDirection:'row'}}>
              <label  style={{width:'200px'}} class="label">ITEM WEIGHT</label>
            <input
            style={{width:'400px', marginLeft:'10px'}}
              className="input is-medium"
              //type="tel"
              value={this.state.itemWeight}
              //placeholder={"Case Size"}
              onChange={this.itemWeight}
            />
            </div>


            <div style={{display:'flex', marginTop:'10px', alignItems:'center', flexDirection:'row'}}>
              <label  style={{width:'200px'}} class="label">KEYWORDS</label>
            <input
            style={{width:'400px', marginLeft:'10px'}}
              className="input is-medium"
              //type="tel"
              value={this.state.keywords}
              //placeholder={"Case Size"}
              onChange={this.keywords}
            />
            </div>

            <div style={{display:'flex', marginTop:'10px', alignItems:'center', flexDirection:'row'}}>
              <label  style={{width:'200px'}} class="label">PALLET SIZE</label>
            <input
            style={{width:'400px', marginLeft:'10px'}}
              className="input is-medium"
              //type="tel"
              value={this.state.palletSize}
              //placeholder={"Case Size"}
              onChange={this.palletSize}
            />
            </div>

            <div style={{display:'flex', marginTop:'10px', alignItems:'center', flexDirection:'row'}}>
              <label  style={{width:'200px'}} class="label">TIER SIZE</label>
            <input
            style={{width:'400px', marginLeft:'10px'}}
              className="input is-medium"
              //type="tel"
              value={this.state.tierSize}
              //placeholder={"Case Size"}
              onChange={this.tierSize}
            />
            </div>

            <div style={{display:'flex', marginTop:'10px', alignItems:'center', flexDirection:'row'}}>
              <label  style={{width:'200px'}} class="label">SUPPLIER ITEM NUMBER</label>
            <input
            style={{width:'400px', marginLeft:'10px'}}
              className="input is-medium"
              //type="tel"
              value={this.state.supplierItemNumber}
              //placeholder={"Case Size"}
              onChange={this.supplierItemNumber}
            />
            </div>

            <div style={{display:'flex', marginTop:'10px', alignItems:'center', flexDirection:'row'}}>
              <label  style={{width:'200px'}} class="label">UPC</label>
            <input
            style={{width:'400px', marginLeft:'10px'}}
              className="input is-medium"
              //type="tel"
              value={this.state.upc}
              //placeholder={"Case Size"}
              onChange={this.upc}
            />
            </div>

            <div style={{display:'flex', marginTop:'10px', alignItems:'center', flexDirection:'row'}}>
              <label  style={{width:'200px'}} class="label">UPC TYPE</label>
            <input
            style={{width:'400px', marginLeft:'10px'}}
              className="input is-medium"
              //type="tel"
              value={this.state.upcType}
              //placeholder={"Case Size"}
              onChange={this.upcType}
            />
            </div>




          </div>




            </div>

</div>
          </form>


            <div style={{width:'800px', marginTop:'20px', display:'flex', flexDirection:'row', justifyContent:'flex-end'}}>
              <button onClick={() => this.updateItem()} className={"button"} style={{color:'black', backgroundColor:'#C4EEDE', width:'150px'}}>
                Update
              </button>
           </div>



        </div>


    );
  }




}

export default CatalogAdminItem;
