import React from "react";
import Section from "./../Section";
import SectionHeader from "./../SectionHeader";
import Features from "./../Features";
import "./styles.scss";

import synk_accounting_lightgreen from "../../assets/synk_accounting_lightgreen.svg";
import synk_customerinsights_lightgreen from "../../assets/synk_customerinsights_lightgreen.svg";
import synk_dashboard_lightgreen from "../../assets/synk_dashboard_lightgreen.svg";
import synk_integrate_lightgreen from "../../assets/synk_integrate_lightgreen.svg";
import synk_route_lightgreen from "../../assets/synk_route_lightgreen.svg";
import synk_sales_lightgreen from "../../assets/synk_sales_lightgreen.svg";
import synk_shopping_lightgreen from "../../assets/synk_shopping_lightgreen.svg";
import synk_target_lightgreen from "../../assets/synk_target_lightgreen.svg";
import synk_warehouse_lightgreen from "../../assets/synk_warehouse_lightgreen.svg";


function FeaturesSection(props) {
  return (
    <Section color={props.color} size={props.size}>
      <div className="container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          centered={true}
          size={3}
        />
        <Features
          items={[
            {
              title: "Executive Reporting",
              description:
                "Get actionable insights from real-time sales and operations data.",
                image: synk_dashboard_lightgreen
              //image: "https://firebasestorage.googleapis.com/v0/b/osmo-a9de7.appspot.com/o/appGraphics%2Fanalyze_on_screen.svg?alt=media&token=0bbad33e-9636-4ff3-9ca2-8d6c0675a603"
            },
            {
              title: "Business Development",
              description:
                "Create a targeted list of prospects enriched with phone and address data in minutes.",
                image: synk_target_lightgreen
              //image: "https://firebasestorage.googleapis.com/v0/b/osmo-a9de7.appspot.com/o/appGraphics%2Ffocus_on_target.svg?alt=media&token=01f4e2f0-38d2-4849-a68b-4c98f8e25309"
            },
            {
              title: "Partner Integration",
              description:
                "Securely and privately share prospecting and fulfillment data outside of your organization.",
                image: synk_integrate_lightgreen
              //image:"https://firebasestorage.googleapis.com/v0/b/osmo-a9de7.appspot.com/o/appGraphics%2Fproblem_solving.svg?alt=media&token=5c21ead8-9c59-42cc-9b3d-bbe39473034e"
            },

            {
              title: "Sales Tracking",
              description:
                "Track and optimize sales for phone, email, direct mail, and field channels.",
                image: synk_sales_lightgreen
              //image:"https://firebasestorage.googleapis.com/v0/b/osmo-a9de7.appspot.com/o/appGraphics%2Fcustomer_success.svg?alt=media&token=0580e2fd-d018-4e1e-b6a6-ae96bf425017"
            },

            {
              title: "B2B eCommerce",
              description:
                "Place orders internally via call sheets or accept them directly from your customers.",
                image: synk_shopping_lightgreen
              //image: "https://firebasestorage.googleapis.com/v0/b/osmo-a9de7.appspot.com/o/appGraphics%2Fshopping_online.svg?alt=media&token=06519ab1-86e0-43d1-a263-f8056eeff5ff"
            },
            {
              title: "Customer Insights",
              description:
                "Keep your customers in the loop so they never miss placing an order or receiving a delivery.",
                image: synk_customerinsights_lightgreen
              //image:"https://firebasestorage.googleapis.com/v0/b/osmo-a9de7.appspot.com/o/appGraphics%2Forder_confirmed_1.svg?alt=media&token=eb989ff3-fba1-4801-99c5-f720b36e15ab"
            },
            {
              title: "Warehouse Optimization",
              description:
                "Bring visibility to your warehouse and build location optimized load tickets.",
                image: synk_warehouse_lightgreen
              //image: "https://firebasestorage.googleapis.com/v0/b/osmo-a9de7.appspot.com/o/appGraphics%2Flogistics.svg?alt=media&token=f5873de8-8909-4714-860c-3f93167218c9"
            },
            {
              title: "Route Optimization",
              description:
                "Get an automatically optimized list of stops for field and order delivery routes.",
                image: synk_route_lightgreen
              //image: "https://firebasestorage.googleapis.com/v0/b/osmo-a9de7.appspot.com/o/appGraphics%2Fnavigation.svg?alt=media&token=70f00848-135b-42eb-8811-f815fa6de89c"
            },

            {
              title: "Accounting",
              description:
                "Manage expenses and get a real-time view of your cash flow and income.",
                image: synk_accounting_lightgreen
              //image: "https://firebasestorage.googleapis.com/v0/b/osmo-a9de7.appspot.com/o/appGraphics%2Ffinancial_analyst.svg?alt=media&token=d727120c-2aba-4875-94e0-5c9a453884c8"
            }
          ]}
        />
      </div>
    </Section>
  );
}

export default FeaturesSection;
