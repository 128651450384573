import React from "react";
import Section from "./../Section";
import SectionHeader from "./../SectionHeader";
//import { useRouter } from "./../../util/router.js";
import { Link } from "./../../util/router.js";
import MdList from 'react-ionicons/lib/MdList';
import MdPerson from 'react-ionicons/lib/MdPerson';
import MdCart from 'react-ionicons/lib/MdCart';
import MdCheckboxOutline from 'react-ionicons/lib/MdCheckboxOutline';
import MdCloudUpload from 'react-ionicons/lib/MdCloudUpload';
import MdContact from 'react-ionicons/lib/MdContact';
import MdPie from 'react-ionicons/lib/MdPie';
import MdAnalytics from 'react-ionicons/lib/MdAnalytics';
import MdCall from 'react-ionicons/lib/MdCall';
import "./styles.scss";
import firebase from 'firebase';
//import { Chart } from "react-google-charts";
// import {
//   BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
// } from 'recharts';

import {
  PieChart, Pie, Sector, Cell,
} from 'recharts';
//function DashboardSection(props) {

  class DashboardSection extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
          navDrawerOpen: false,
          isSignedIn:null,
          dateFormatted:'',
          dateValue:'',
          error:'',
          loading:false,
          email:'',
          password:'',
          user:[],
          isToggleOn:true,
          companyId:'',
          isUnassigned:null,
          isDistributor:null,
          hasAdmin:null,
          missedOrderThresholdCount:'',
          productUpdateCount:'',
          productAddedCount:'',
          notesCount:'',
          orderCountTotal:'',
          newCustomerAddedManually:'',
          noOrderCount:'',
          dailySalesAvgOrderSize:'',
          dailySales:'',
          customerAppRegister:'',
          externalOrderTotal:'',
          externalOrderCount:'',
          externalOrderAvg:'',
        };


      }


      componentDidMount(){

        var today = new Date();
        var start = new Date();
        start.setHours(0,0,0,0);

        var end = new Date();
        end.setHours(23,59,59,999);

        var todayStartValue = Math.floor(start / 1000);
        var todayEndValue = Math.floor(end / 1000);

        // if (this.props.filter==="Confirmed"){
        //   today.setDate(today.getDate() + 1);
        // }
        var dd = today.getDate();
        var mm = today.getMonth()+1; //January is 0!
        var yyyy = today.getFullYear();
        if(dd<10){
            dd='0'+dd;
        }
        if(mm<10){
            mm='0'+mm;
        }
        var dateValue = yyyy.toString() + mm.toString() + dd.toString();
        var dateVal = yyyy.toString() + mm.toString();
        var dateToday = mm.toString() + '/' + dd.toString() + '/' + yyyy.toString();

        this.setState({dateValue:dateValue});
        this.setState({dateFormatted:dateToday});


        var setUser = '';
        firebase.auth().onAuthStateChanged((user) => {
          if (user){
            this.setState({isSignedIn:true});
            this.setState({email:user.email});

            this.getCompanyData()
            console.log(user);
          } else {
            this.setState({isSignedIn:false});
            //console.log("not logged in");
          }
        });

      }

      getCompanyData(){

        var user = firebase.auth().currentUser;
        console.log("OPEN USER " + user);

        if (user){
        firebase.database().ref('users/' + user.uid).once('value', (dataSnapshot) => {
            console.log("datasnap " + JSON.stringify(dataSnapshot));
            if (dataSnapshot.val().companyId!==undefined){
              this.setState({companyId: dataSnapshot.val().companyId});
            }
        }).then(() => {
            if (this.state.companyId!==undefined && this.state.companyId.trim()!==""){
              firebase.database().ref('company/' + this.state.companyId + "/users/" + user.uid).once('value', (userSnapshot) => {


                console.log("userGroup" + userSnapshot.key);
                if (userSnapshot.val().group==="Admin"){
                  this.setState({hasAdmin:true});

                //   firebase.database().ref('company/' + this.state.companyId + '/companyDailyMetrics/' + this.state.dateValue +'/noOrderCount').on('value', (snap) => {
                //     this.setState({noOrderCount:snap.numChildren()});
                //   });
                //
                //
                // firebase.database().ref('company/' + this.state.companyId + '/companyDailyMetrics/' + this.state.dateValue +'/orderCountTotal').on('value', (snap) => {
                //     var dailySales = 0;
                //     this.setState({orderCountTotal:snap.numChildren()});
                //
                //     snap.forEach((sale) => {
                //       dailySales += parseFloat(sale.val().total);
                //       console.log("daily sales " + dailySales);
                //     });
                //
                //     this.setState({dailySales:dailySales});
                //
                //     var dailySalesAvgOrderSize = 0 ;
                //     if (snap.numChildren()>0){
                //       dailySalesAvgOrderSize = dailySales / parseInt(snap.numChildren());
                //     }
                //
                //     console.log("avg daily sales " + dailySales + '  ' + dailySalesAvgOrderSize);
                //     this.setState({dailySalesAvgOrderSize:dailySalesAvgOrderSize});
                // });


                }
                else if (userSnapshot.val().group==="Unassigned"){
                  this.setState({isUnassigned:true});
                }
                else {
                  this.setState({isUnassigned:false});
                   this.setState({userGroup:userSnapshot.val().group});
                }

                firebase.database().ref('company/' + this.state.companyId + "/companyInfo/isDistributor").once('value', (snap) => {
                  console.log("dist " + snap.val());
                  if (snap.val()===true){
                    this.setState({isDistributor:true})
                  } else {
                      this.setState({isDistributor:false})
                  }
                });



             });
            }
        });
      }
    }


      goToUserAdmin(){
        //var router = useRouter();
        //router.push("/userAdmin");
      }
      goToCatalogAdmin(){
        //var router = this.useRouter();
        //router.push("/catalogAdmin");
        //router.push("/terms");
      }
      goToOrderAdmin(){
        //var router = useRouter();
        //router.push("/orderAdmin");
      }



      render(){

  //       const data = [
  // {
  //   name: 'mon', phone: 40000, app: 2400,
  // },
  // {
  //   name: 'tue', phone: 30000, app: 1398,
  // },
  // {
  //   name: 'wed', phone: 20000, app: 9800,
  // },
  // {
  //   name: 'thu', phone: 27800, app: 3908,
  // },
  // {
  //   name: 'fri', phone: 18900, app: 4800,
  // },
  // {
  //   name: 'sat', phone: 23900, app: 3800,
  // },
  // {
  //   name: 'sun', phone: 34900, app: 4300,
  // },
//];
const data = [
  { name: 'Group A', value: 400 }, { name: 'Group B', value: 300 },
  { name: 'Group C', value: 300 }, { name: 'Group D', value: 200 },
  { name: 'Group E', value: 278 }, { name: 'Group F', value: 189 },
];


        return (
          <div>

          {(this.state.isSignedIn===false) &&
            <div className="container">
              <SectionHeader
                title={"You are not signed in."}
                subtitle={""}
                centered={true}
                size={3}
              />
            </div>
          }


        {(this.state.isSignedIn===true && this.state.isDistributor===true && this.state.hasAdmin===true) &&

          <div>
            <div className="container">
              <SectionHeader
                title={""}
                //subtitle={this.state.dateFormatted}
                centered={true}
                size={3}
              />
            </div>

            <div style={{backgroundColor:'#fff',padding:'20px', display:'flex', flexDirection:'row'}}>




            <div  style={{ marginTop:'0px', marginBottom:'100px', display:'flex', flexWrap:'wrap', overflow:'hidden', justifyContent:'center'}} className="container">


                  <Link to="/userAdmin">
                  <div style={{ float:'left', zIndex:0, display:'flex',width:'280px',  margin:'40px', height:'120px', backgroundColor:'#C4EEDE', borderRadius:'5px'}}>
                  <a  onClick={() => this.goToUserAdmin()} id="divButton" style={{width:'280px', height:'120px',}}>
                  <div   style={{display:'flex', float:'left', flexDirection:'row',  }}>

                    <div style={{ zIndex:2, position:'absolute', marginLeft:'-20px', marginTop:'-20px', justifyContent:'center', alignItems:'center', width:'80px', height:'80px', backgroundColor:'#000', borderRadius:'40px'}} >
                      <div style={{display:'flex',width:'80px', height:'80px', justifyContent:'center', alignItems:'center',}}>
                        <MdPerson style={{justifyContent:'center', alignSelf:'center', alignItems:'center'}} fontSize="60px" color="#fff" />
                      </div>
                    </div>

                    <div style={{ display:'flex', flexDirection:'column', justifyContent:'flex-start',  height:'60px',}}>
                    <p className="link" style={{marginLeft:'75px', marginTop:'10px',fontSize:'20px'}}>User Admin</p>
                    <div style={{ display:'flex', flexDirection:'row', width:'270px', justifyContent:'space-between'}}>
                        </div>
                    </div>

                  </div>
                  </a>
                  </div>
                  </Link>





                  <Link to="/orderAdmin">
                  <div style={{ float:'left', zIndex:0, display:'flex',width:'280px',  margin:'40px', height:'120px', backgroundColor:'#C4EEDE', borderRadius:'5px'}}>
                  <a  onClick={() => this.goToOrderAdmin()} id="divButton" style={{width:'280px', height:'120px',}}>
                  <div   style={{display:'flex', float:'left', flexDirection:'row',  }}>

                    <div style={{ zIndex:2, position:'absolute', marginLeft:'-20px', marginTop:'-20px', justifyContent:'center', alignItems:'center', width:'80px', height:'80px', backgroundColor:'#000', borderRadius:'40px'}} >
                      <div style={{display:'flex',width:'80px', height:'80px', justifyContent:'center', alignItems:'center',}}>
                        <MdCart style={{justifyContent:'center', alignSelf:'center', alignItems:'center'}} fontSize="60px" color="#fff" />
                      </div>
                    </div>

                      <div style={{ display:'flex', flexDirection:'column', justifyContent:'flex-start',  height:'60px',}}>
                      <p className="link" style={{marginLeft:'75px', marginTop:'10px',fontSize:'20px'}}>Order Admin</p>
                      <div style={{ display:'flex', flexDirection:'row', width:'270px',justifyContent:'space-between'}}>
                        <p className="link" style={{marginLeft:'75px', marginTop:'10px', fontWeight:'bold', fontSize:'20px'}}></p>
                        <p className="link" style={{marginLeft:'75px', marginTop:'10px', fontWeight:'bold', fontSize:'20px'}}></p>
                        </div>
                      </div>

                  </div>
                  </a>
                  </div>
                  </Link>






                  <Link to="/catalogAdmin">
                  <div style={{ float:'left', zIndex:0, display:'flex',width:'280px',  margin:'40px', height:'120px', backgroundColor:'#C4EEDE', borderRadius:'5px'}}>
                  <a  onClick={() => this.goToCatalogAdmin()} id="divButton" style={{width:'280px', height:'120px',}}>
                  <div   style={{display:'flex', float:'left', flexDirection:'row',  }}>

                    <div style={{ zIndex:2, position:'absolute', marginLeft:'-20px', marginTop:'-20px', justifyContent:'center', alignItems:'center', width:'80px', height:'80px', backgroundColor:'#000', borderRadius:'40px'}} >
                      <div style={{display:'flex',width:'80px', height:'80px', justifyContent:'center', alignItems:'center',}}>
                        <MdList style={{justifyContent:'center', alignSelf:'center', alignItems:'center'}} fontSize="60px" color="#fff" />
                      </div>
                    </div>

                    <div style={{ display:'flex', flexDirection:'column', justifyContent:'flex-start',  height:'60px',}}>
                    <p className="link" style={{marginLeft:'75px', marginTop:'10px',fontSize:'20px'}}>Catalog Admin</p>
                    <div style={{ display:'flex', flexDirection:'row', width:'270px', justifyContent:'space-between'}}>
                        </div>
                    </div>


                  </div>
                  </a>
                  </div>
                  </Link>


                  <Link to="/catalogUpload">
                  <div style={{ float:'left', zIndex:0, display:'flex',width:'280px',  margin:'40px', height:'120px', backgroundColor:'#C4EEDE', borderRadius:'5px'}}>
                  <a  onClick={() => this.goToCatalogAdmin()} id="divButton" style={{width:'280px', height:'120px',}}>
                  <div   style={{display:'flex', float:'left', flexDirection:'row',  }}>

                    <div style={{ zIndex:2, position:'absolute', marginLeft:'-20px', marginTop:'-20px', justifyContent:'center', alignItems:'center', width:'80px', height:'80px', backgroundColor:'#000', borderRadius:'40px'}} >
                      <div style={{display:'flex',width:'80px', height:'80px', justifyContent:'center', alignItems:'center',}}>
                        <MdCloudUpload style={{justifyContent:'center', alignSelf:'center', alignItems:'center'}} fontSize="60px" color="#fff" />
                      </div>
                    </div>

                    <div style={{ display:'flex', flexDirection:'column', justifyContent:'flex-start',  height:'60px',}}>
                    <p className="link" style={{marginLeft:'75px', marginTop:'10px',fontSize:'20px'}}>Catalog Upload</p>
                    <div style={{ display:'flex', flexDirection:'row', width:'270px', justifyContent:'space-between'}}>
                        </div>
                    </div>


                  </div>
                  </a>
                  </div>
                  </Link>


                  <Link>
                  <div style={{ float:'left', zIndex:0, display:'flex',width:'280px',  margin:'40px', height:'120px', backgroundColor:'#C4EEDE', borderRadius:'5px'}}>
                  <a  onClick={() => this.goToCatalogAdmin()} id="divButton" style={{width:'280px', height:'120px',}}>
                  <div   style={{display:'flex', float:'left', flexDirection:'row',  }}>

                    <div style={{ zIndex:2, position:'absolute', marginLeft:'-20px', marginTop:'-20px', justifyContent:'center', alignItems:'center', width:'80px', height:'80px', backgroundColor:'#000', borderRadius:'40px'}} >
                      <div style={{display:'flex',width:'80px', height:'80px', justifyContent:'center', alignItems:'center',}}>
                        <MdCall style={{justifyContent:'center', alignSelf:'center', alignItems:'center'}} fontSize="60px" color="#fff" />
                      </div>
                    </div>

                    <div style={{ display:'flex', flexDirection:'column', justifyContent:'flex-start',  height:'60px',}}>
                    <p className="link" style={{marginLeft:'75px', marginTop:'10px',fontSize:'20px'}}>Call Sheets</p>
                    <div style={{ display:'flex', flexDirection:'row', width:'270px', justifyContent:'space-between'}}>
                        </div>
                    </div>


                  </div>
                  </a>
                  </div>
                  </Link>


                  <Link to="/approvalAdmin">
                  <div style={{ float:'left', zIndex:0, display:'flex',width:'280px',  margin:'40px', height:'120px', backgroundColor:'#C4EEDE', borderRadius:'5px'}}>
                  <a  onClick={() => this.goToCatalogAdmin()} id="divButton" style={{width:'280px', height:'120px',}}>
                  <div   style={{display:'flex', float:'left', flexDirection:'row',  }}>

                    <div style={{ zIndex:2, position:'absolute', marginLeft:'-20px', marginTop:'-20px', justifyContent:'center', alignItems:'center', width:'80px', height:'80px', backgroundColor:'#000', borderRadius:'40px'}} >
                      <div style={{display:'flex',width:'80px', height:'80px', justifyContent:'center', alignItems:'center',}}>
                        <MdCheckboxOutline style={{justifyContent:'center', alignSelf:'center', alignItems:'center'}} fontSize="60px" color="#fff" />
                      </div>
                    </div>

                    <div style={{ display:'flex', flexDirection:'column', justifyContent:'flex-start',  height:'60px',}}>
                    <p className="link" style={{marginLeft:'75px', marginTop:'10px',fontSize:'20px'}}>Catalog Approval</p>
                    <div style={{ display:'flex', flexDirection:'row', width:'270px', justifyContent:'space-between'}}>
                        </div>
                    </div>


                  </div>
                  </a>
                  </div>
                  </Link>


                  <Link to="/customerAdmin">
                  <div style={{ float:'left', zIndex:0, display:'flex',width:'280px',  margin:'40px', height:'120px', backgroundColor:'#C4EEDE', borderRadius:'5px'}}>
                  <a  onClick={() => this.goToCatalogAdmin()} id="divButton" style={{width:'280px', height:'120px',}}>
                  <div   style={{display:'flex', float:'left', flexDirection:'row',  }}>

                    <div style={{ zIndex:2, position:'absolute', marginLeft:'-20px', marginTop:'-20px', justifyContent:'center', alignItems:'center', width:'80px', height:'80px', backgroundColor:'#000', borderRadius:'40px'}} >
                      <div style={{display:'flex',width:'80px', height:'80px', justifyContent:'center', alignItems:'center',}}>
                        <MdContact style={{justifyContent:'center', alignSelf:'center', alignItems:'center'}} fontSize="60px" color="#fff" />
                      </div>
                    </div>

                    <div style={{ display:'flex', flexDirection:'column', justifyContent:'flex-start',  height:'60px',}}>
                    <p className="link" style={{marginLeft:'75px', marginTop:'10px',fontSize:'20px'}}>Customer Admin</p>
                    <div style={{ display:'flex', flexDirection:'row', width:'270px', justifyContent:'space-between'}}>
                        </div>
                    </div>


                  </div>
                  </a>
                  </div>
                  </Link>

                  <Link to="/dailyMetrics" >
                  <div style={{ float:'left', zIndex:0, display:'flex',width:'280px',  margin:'40px', height:'120px', backgroundColor:'#C4EEDE', borderRadius:'5px'}}>
                  <a  onClick={() => this.goToCatalogAdmin()} id="divButton" style={{width:'280px', height:'120px',}}>
                  <div   style={{display:'flex', float:'left', flexDirection:'row',  }}>

                    <div style={{ zIndex:2, position:'absolute', marginLeft:'-20px', marginTop:'-20px', justifyContent:'center', alignItems:'center', width:'80px', height:'80px', backgroundColor:'#000', borderRadius:'40px'}} >
                      <div style={{display:'flex',width:'80px', height:'80px', justifyContent:'center', alignItems:'center',}}>
                        <MdPie style={{justifyContent:'center', alignSelf:'center', alignItems:'center'}} fontSize="60px" color="#fff" />
                      </div>
                    </div>

                    <div style={{ display:'flex', flexDirection:'column', justifyContent:'flex-start',  height:'60px',}}>
                    <p className="link" style={{marginLeft:'75px', marginTop:'10px',fontSize:'20px'}}>Daily Metrics</p>
                    <div style={{ display:'flex', flexDirection:'row', width:'270px', justifyContent:'space-between'}}>
                        </div>
                    </div>


                  </div>
                  </a>
                  </div>
                  </Link>

                  <Link >
                  <div style={{ float:'left', zIndex:0, display:'flex',width:'280px',  margin:'40px', height:'120px', backgroundColor:'#C4EEDE', borderRadius:'5px'}}>
                  <a  onClick={() => this.goToCatalogAdmin()} id="divButton" style={{width:'280px', height:'120px',}}>
                  <div   style={{display:'flex', float:'left', flexDirection:'row',  }}>

                    <div style={{ zIndex:2, position:'absolute', marginLeft:'-20px', marginTop:'-20px', justifyContent:'center', alignItems:'center', width:'80px', height:'80px', backgroundColor:'#000', borderRadius:'40px'}} >
                      <div style={{display:'flex',width:'80px', height:'80px', justifyContent:'center', alignItems:'center',}}>
                        <MdAnalytics style={{justifyContent:'center', alignSelf:'center', alignItems:'center'}} fontSize="60px" color="#fff" />
                      </div>
                    </div>

                    <div style={{ display:'flex', flexDirection:'column', justifyContent:'flex-start',  height:'60px',}}>
                    <p className="link" style={{marginLeft:'75px', marginTop:'10px',fontSize:'20px'}}>Trends</p>
                    <div style={{ display:'flex', flexDirection:'row', width:'270px', justifyContent:'space-between'}}>
                        </div>
                    </div>


                  </div>
                  </a>
                  </div>
                  </Link>





            </div>



            </div>


            </div>
          }

          </div>
        );
      }


}

export default DashboardSection;



// <div style={{ float:'left'}}>
//
// <PieChart width={350} height={350}>
//      <Pie dataKey="value" startAngle={180} endAngle={0} data={data} cx={200} cy={200} outerRadius={80} fill="#8884d8" label />
//    </PieChart>
//
//
//    <PieChart width={350} height={350}>
//         <Pie dataKey="value" startAngle={180} endAngle={0} data={data} cx={200} cy={200} outerRadius={80} fill="#8884d8" label />
//       </PieChart>
// </div>



// <div  style={{ marginTop:'20px', display:'flex', overflow:'hidden', justifyContent:'center'}} className="container">
//     <BarChart
//        width={500}
//        height={300}
//        data={data}
//        margin={{
//          top: 20, right: 30, left: 20, bottom: 5,
//        }}
//      >
//        <CartesianGrid strokeDasharray="3 3" />
//        <XAxis dataKey="name" />
//        <YAxis />
//        <Tooltip />
//        <Legend />
//
//        <Bar dataKey="phone" stackId="a" fill="#82ca9d" />
//        <Bar dataKey="app" stackId="a" fill="#8884d8" />
//      </BarChart>
//
//  </div>






// <Chart
//   width={'500px'}
//   height={'300px'}
//   chartType="PieChart"
//   loader={<div>Loading Chart</div>}
//   data={[
//     ['Task', 'Hours per Day'],
//     ['App', 11],
//     ['Driver', 2],
//     ['Call Sheet', 207],
//   ]}
//   options={{
//     title: 'Order Distribution',
//     slices: {
//        0: { color: '#004baf' },
//        1: { color: 'green' },
//        2: { color: 'purple' },
//      },
//   }}
//   rootProps={{ 'data-testid': '1' }}
// />


// <div >
//           <div style={{ zIndex:0, width:300, height:120, borderWidth:0, flexDirection:'row', justifyContent:'flex-start', margin:10}}>
//           <div style={{position:'absolute', zIndex:0, margin:10, width:280, height:100, backgroundColor:'#C4EEDE', borderWidth:0, justifyContent:'center', flexDirection:'column', shadowOffset: { width: 5, height: 5 },shadowColor: '#999',elevation: 7,shadowRadius: 5,shadowOpacity: 0.8}}>
//           <div style={[{ marginLeft:70, height:80, width:200, justifyContent:'flex-start', borderWidth:0, }]}>
//             <p style={[{ marginLeft:15, marginTop:10, fontSize:18, color:'black'}]}>Load Routes</p>
//             <p style={[{ marginLeft:15,  marginTop:5, fontSize:16, fontWeight:'bold', color:'black'}]}>xyz</p>
//           </div>
//           </div>
//           </div>
//           <div style={[{position:'absolute', zIndex:5, top:0, left:0, marginLeft:10, marginTop:10, height:80, width:80, borderRadius:60, borderWidth:0,  alignItems:'center', justifyContent:'center',backgroundColor:'#000'}]}>
//           <LogoNodejs onClick={() => alert('Hi!')} fontSize="60px" color="#43853d" />
//         </div>
//           </div>

/*



<div style={{  display:'flex',width:'280px',  margin:'30px', height:'120px', backgroundColor:'#C4EEDE', borderRadius:'5px'}}>
<a  onClick={() => console.log("user " + this.state.email)} id="divButton" style={{width:'280px', height:'120px',}}>
<div   style={{display:'flex', float:'left', flexDirection:'row',  }}>

  <div style={{ display:'flex', padding:'5px', justifyContent:'center', alignItems:'center', width:'60px', height:'60px', backgroundColor:'#000', borderRadius:'5px'}} >
    <MdCart fontSize="50px" color="#fff" />
  </div>

    <div style={{ display:'flex', height:'60px', alignItems:'center'}}>
    <p className="link" style={{marginLeft:'20px', fontSize:'16px'}}>Order Admin</p>
    </div>

</div>
</a>
</div>





      <div style={{  display:'flex',width:'280px',  margin:'30px', height:'120px', backgroundColor:'#C4EEDE', borderRadius:'5px'}}>
      <a   id="divButton" style={{width:'280px', height:'120px',}}>
      <div   style={{display:'flex', float:'left', flexDirection:'row',  }}>

        <div style={{ display:'flex', padding:'5px', justifyContent:'center', alignItems:'center', width:'60px', height:'60px', backgroundColor:'#000', borderRadius:'5px'}} >
          <MdList fontSize="50px" color="#fff" />
        </div>

          <div style={{ display:'flex', height:'60px', alignItems:'center'}}>
          <p className="link" style={{marginLeft:'20px', fontSize:'16px'}}>Catalog Admin</p>
          </div>

      </div>
      </a>
      </div>















<div style={{ marginTop:'20px', display:'flex', overflow:'hidden'}} className="container">

  <div style={{display:'inline-block',borderRadius:'3px', margin:'30px', width:'300px', height:'80px', backgroundColor:'#C4EEDE'}}>
    <p style={{margin:'10px', fontSize:'16px'}}>My Lists</p>
  </div>

  <div style={{display:'inline-block', float:'left', borderRadius:'3px', margin:'30px', width:'300px', height:'80px', backgroundColor:'#C4EEDE'}}>
  <p style={{margin:'10px', fontSize:'16px'}}>Edit Catalog</p>
  </div>
  <div style={{display:'inline-block',float:'left', borderRadius:'3px', margin:'30px', width:'300px', height:'80px', backgroundColor:'#C4EEDE'}}>
  <p style={{margin:'10px', fontSize:'16px'}}>Customers</p>
  </div>
  <div style={{display:'inline-block',float:'left', borderRadius:'3px', margin:'30px', width:'300px', height:'80px', backgroundColor:'#C4EEDE'}}>
  <p style={{margin:'10px', fontSize:'16px'}}>Reports</p>
  </div>
</div>
<div style={{ marginTop:'20px', display:'flex', overflow:'hidden'}} className="container">

  <div style={{display:'inline-block',borderRadius:'3px', margin:'30px', width:'300px', height:'80px', backgroundColor:'#C4EEDE'}}>
    <p style={{margin:'10px', fontSize:'16px'}}>My Lists</p>
  </div>

  <div style={{display:'inline-block', float:'left', borderRadius:'3px', margin:'30px', width:'300px', height:'80px', backgroundColor:'#C4EEDE'}}>
  <p style={{margin:'10px', fontSize:'16px'}}>Edit Catalog</p>
  </div>
  <div style={{display:'inline-block',float:'left', borderRadius:'3px', margin:'30px', width:'300px', height:'80px', backgroundColor:'#C4EEDE'}}>
  <p style={{margin:'10px', fontSize:'16px'}}>Customers</p>
  </div>
  <div style={{display:'inline-block',float:'left', borderRadius:'3px', margin:'30px', width:'300px', height:'80px', backgroundColor:'#C4EEDE'}}>
  <p style={{margin:'10px', fontSize:'16px'}}>Reports</p>
  </div>
</div>
<div style={{ marginTop:'20px', display:'flex', overflow:'hidden'}} className="container">

  <div style={{display:'inline-block',borderRadius:'3px', margin:'30px', width:'300px', height:'80px', backgroundColor:'#C4EEDE'}}>
    <p style={{margin:'10px', fontSize:'16px'}}>My Lists</p>
  </div>

  <div style={{display:'inline-block', float:'left', borderRadius:'3px', margin:'30px', width:'300px', height:'80px', backgroundColor:'#C4EEDE'}}>
  <p style={{margin:'10px', fontSize:'16px'}}>Edit Catalog</p>
  </div>
  <div style={{display:'inline-block',float:'left', borderRadius:'3px', margin:'30px', width:'300px', height:'80px', backgroundColor:'#C4EEDE'}}>
  <p style={{margin:'10px', fontSize:'16px'}}>Customers</p>
  </div>
  <div style={{display:'inline-block',float:'left', borderRadius:'3px', margin:'30px', width:'300px', height:'80px', backgroundColor:'#C4EEDE'}}>
  <p style={{margin:'10px', fontSize:'16px'}}>Reports</p>
  </div>
</div>









<div style={{ marginTop:'20px', display:'flex', overflow:'hidden'}} className="container">

  <div style={{display:'inline-block',borderRadius:'3px', margin:'30px', width:'300px', height:'80px', backgroundColor:'#C4EEDE'}}>

    <LogoNodejs onClick={() => alert('Hi!')} fontSize="60px" color="#43853d" />
    <p style={{margin:'10px', fontSize:'16px'}}>My Lists</p>
  </div>

  <div style={{display:'inline-block', float:'left', borderRadius:'3px', margin:'30px', width:'300px', height:'80px', backgroundColor:'#C4EEDE'}}>
  <p style={{margin:'10px', fontSize:'16px'}}>Edit Catalog</p>
  </div>
  <div style={{display:'inline-block',float:'left', borderRadius:'3px', margin:'30px', width:'300px', height:'80px', backgroundColor:'#C4EEDE'}}>
  <p style={{margin:'10px', fontSize:'16px'}}>Customers</p>
  </div>
  <div style={{display:'inline-block',float:'left', borderRadius:'3px', margin:'30px', width:'300px', height:'80px', backgroundColor:'#C4EEDE'}}>
  <p style={{margin:'10px', fontSize:'16px'}}>Reports</p>
  </div>
</div>


<aside style={{ width:'200px'}} class="menu">
  <p class="menu-label">
    General
  </p>
  <ul class="menu-list">
    <li><a class="is-active">Dashboard</a></li>
    <li><a>My Account</a></li>
    <li><a>Support</a></li>
  </ul>

</aside>
*/
