import React, { useEffect } from "react";
import DashboardSection from "./../../components/DashboardSection";
import { useAuth } from "./../../util/auth.js";
import { useRouter } from "./../../util/router.js";
import "./styles.scss";

function DashboardPage(props) {
  const auth = useAuth();
  const router = useRouter();

  // Redirect to signin
  // if not signed in.
  useEffect(() => {
    if (auth.user === false) {
      router.push("/signin");
    }
  }, [auth]);

  return (
    <div>
    <DashboardSection
      color="white"
      size="large"
      title="Thank you for registering. We will contact you shortly for a brief introduction and grant you access to our suite of tools."
      subtitle=""
    />

    </div>
  );
}

export default DashboardPage;
