import React from "react";
import ContentSection from "./../../components/ContentSection";
import FeaturesSectionFull from "./../../components/FeaturesSectionFull";
import "./styles.scss";
import DatePicker from "react-datepicker";
// import CSVReader from 'react-csv-reader';
// import Dropzone from 'react-dropzone';
import MdSearch from 'react-ionicons/lib/MdSearch';
//import TextField from '@material-ui/core/TextField';
//import Button from '@material-ui/core/Button';

//import { CSVLink, CSVDownload } from "react-csv";
import firebase from 'firebase';
import jsPDF from 'jspdf';
//function CatalogAdmin(props) {
class OrderAdminItem extends React.Component {

  constructor(props) {
    super(props);


    this.state = {
      errorMessage: '',
      searchLimitValue: 25,
      dateValue: '',
      dateFormatted: '',
      companyId: '',
      searchTerm: '',
      query: "",
      data: [],
      filteredData: [],
      totalData: [],
      name: '',
      phone: '',
      group: '',
      routeName: '',
      selectedDate: '',
      selectedDateValue: '',
      selectedDateFormatted: '',
      item: [],
      status: '',
      routeKey: '',
      companyRoutes: {},
      companyInfo: {},
      customerInfo: {}
    };
  }


  componentDidMount() {

    let item = this.props.location.state.item;
    let today = this.props.location.state.selectedDate;
    // let name = this.props.location.state.name;
    // let phone = this.props.location.state.phone;

    this.setState({ item: item });
    this.setState({ status: item.status });

    // this.setState({phone:phone});
    // this.setState({group:group});


    //var today = new Date();
    this.setState({ selectedDate: today });
    var start = new Date();
    start.setHours(0, 0, 0, 0);

    var end = new Date();
    end.setHours(23, 59, 59, 999);

    var todayStartValue = Math.floor(start / 1000);
    var todayEndValue = Math.floor(end / 1000);


    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    var dateValue = yyyy.toString() + mm.toString() + dd.toString();
    var dateVal = yyyy.toString() + mm.toString();
    var dateToday = mm.toString() + '/' + dd.toString() + '/' + yyyy.toString();

    this.setState({ selectedDateValue: dateValue });
    //this.setState({dateFormatted:dateToday});
    this.setState({ selectedDateFormatted: dateToday });
    //this.setState({dateFormatted:dateToday});

    var setUser = '';
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.setState({ isSignedIn: true });
        this.setState({ email: user.email });

        this.getCompanyData()
        console.log(user);
      } else {
        this.setState({ isSignedIn: false });

      }
    });

  }


  getCompanyData() {

    var user = firebase.auth().currentUser;
    console.log("OPEN USER " + user);

    if (user) {
      firebase.database().ref('users/' + user.uid).once('value', (dataSnapshot) => {
        console.log("datasnap " + JSON.stringify(dataSnapshot));
        if (dataSnapshot.val().companyId !== undefined) {
          this.setState({ companyId: dataSnapshot.val().companyId });
        }
      }).then(() => {
        //let passedItem = this.props.location.state.item;
        if (this.state.companyId !== undefined && this.state.companyId.trim() !== "") {
          console.log("this.state.companyId " + this.state.companyId);
          firebase.database().ref('company/' + this.state.companyId + '/companyInfo').once('value', (snap) => {
            this.setState({ companyInfo: snap.toJSON() });
          });
          const { item } = this.state;
          firebase.database().ref('company/' + this.state.companyId + '/customers/' + item.dataItemJSON.orderedByCompany).once('value', (snap) => {
            this.setState({ customerInfo: snap.toJSON() });
          });

          var data = [];
          firebase.database().ref('company/' + this.state.companyId + '/routes/').once('value', (snap) => {
            snap.forEach((item) => {


              var name = '';
              if (item.hasChild("routeName")) { name = item.val().routeName; }
              if (item.key === this.state.item.selectedRouteId) {
                this.setState({
                  routeName: name,
                  routekey: item.key,
                  companyRoutes: item.toJSON()
                });
              }

              data.push({
                key: item.key,
                name: name,
              });
            });

            this.setState({ data: data });

            // const { query } = this.state;
            // const filteredData = data.filter(element => {
            //   // if (element.email.toLowerCase().includes(query.toLowerCase())){
            //   //   return element.email.toLowerCase().includes(query.toLowerCase());
            //   // } else if (element.name.toLowerCase().includes(query.toLowerCase())){
            //     return element.name.toLowerCase().includes(query.toLowerCase())
            //   //}
            //
            //
            // });
            //
            // this.setState({
            //   data,
            //   filteredData
            // });

          });
        }


      });
    }
  }


  handleNameChange = event => {

    const name = event.target.value;
    this.setState({ name: name });

  };

  handlePhoneChange = event => {
    // var subValue = event.target.value.replace('-','');
    // subValue = subValue.replace('-','');
    var subValue = event.target.value.split('-').join('');
    var newPosition = event.target.value.length - 1;
    var nextPosition = event.target.value.length;
    var newValue = event.target.value.substring(newPosition, nextPosition);
    console.log("newPosition " + newPosition);
    console.log("nextPosition " + nextPosition);
    console.log("newValue " + newValue);
    console.log("this.state.phone.length " + this.state.phone.length);
    console.log("event.target.value.length " + event.target.value.length);
    //console.log("eval" + subValue);
    if (this.state.phone.length > event.target.value.length) {
      this.setState({ phone: event.target.value });
    } else if (parseInt(newValue) > 0) {
      console.log("xxxevent.target.value.length " + event.target.value.length);

      if (subValue.length <= 10) {
        var phone = event.target.value;
        if (subValue.length === 3) {
          phone += '-'
        }
        if (subValue.length === 6) {
          phone += '-'
        }
        console.log("xxxevent.target.value.length " + event.target.value.length);

        this.setState({ phone: phone });
      }
    }


  };

  updateOrder() {
    var date = this.state.selectedDate;
    var day = date.getDay();
    var dayFormatted = '';
    if (day === 0) {
      dayFormatted = 'sunday';
    }
    else if (day === 1) {
      dayFormatted = 'monday';
    }
    else if (day === 2) {
      dayFormatted = 'tuesday';
    }
    else if (day === 3) {
      dayFormatted = 'wednesday';
    }
    else if (day === 4) {
      dayFormatted = 'thursday';
    }
    else if (day === 5) {
      dayFormatted = 'friday';
    }
    else if (day === 6) {
      dayFormatted = 'saturday';
    }

    var routeKey = '';
    for (var i = 0; i < this.state.data.length; i++) {
      if (this.state.data[i].name === this.state.routeName) {
        routeKey = this.state.data[i].key;
      }
    }
    // console.log("selectedRoute " + dayFormatted + ' ' + routeKey);
    // console.log("selectedRouteDay " + dayFormatted);
    // console.log("selectedRouteId" + ' ' + routeKey);
    // console.log("deliveryDate" + ' ' + this.state.selectedDateFormatted);
    // console.log("dateValue" + ' ' + this.state.selectedDateValue);
    var selectedRouteString = dayFormatted + ' ' + routeKey;
    if (this.state.item.key.toString().trim() === "" || this.state.item.key === null || this.state.item.key === undefined || routeKey.toString().trim() === "" || this.state.companyId.toString().trim() === "" || this.state.companyId === null || this.state.companyId === undefined || this.state.selectedDateFormatted.toString().trim() === "") {
      alert("Invalid data. Please reopen page and retry.");
    } else {

      firebase.database().ref('company/' + this.state.companyId + '/fulfillments/' + this.state.item.key).update({
        deliveryDate: this.state.selectedDateFormatted,
        dateValue: this.state.selectedDateValue,
        selectedRoute: selectedRouteString,
        selectedRouteDay: dayFormatted,
        selectedRouteId: routeKey,
      }).then(() => {
        alert("Updated Successfully.")
      });

    }
    // let userId  = this.props.location.state;
    // console.log("companyId " + this.state.companyId + "userId " + userId);
    //
    // console.log("update info  " + this.state.companyId + "userId " + userId);
    //alert("Feature is still in progress.");
  }


  printReceipt() {
    var doc = new jsPDF();
    const { item, routeName, companyInfo, customerInfo } = this.state;
    // Convert HTML to PDF
    let productList = [];
    Object.keys(item.dataItemJSON.order).forEach(function (key, index) {
      let val = item.dataItemJSON.order[key];
      let casePrice = Number(val.casePrice);
      let caseSize = Number(val.caseSize);
      let count = Number(val.count);
      let caseTotalSize = (caseSize * count) + Number(val.pieceCount);
      let price = val.isPiece ? (casePrice * caseTotalSize) : (casePrice * count)
      productList.push(`<li style="font-size:4px;white-space: nowrap;">${val.pieceCount}x  <span style="margin-right: 2px;">PIECES</span>, ${count}x CASES <span style="margin-left: 2px;">of</span> ${val.caseSize}  @ ${" "} ${val.itemName} SUM: ${Number(price).toFixed(2)}</li>`)
    });

    var invoice = item.key.substr(-6);
    let content = `
    <div style="padding:10px;width:100%;">
       <p style="font-size:7px;white-space: nowrap;">Jz dist dba Best choice dairy</p>
       <p style="font-size:4px;white-space: nowrap;">17 Middle Country Rd, Middle Island NY</p>
       <p style="font-size:4px;white-space: nowrap;">917-693-5235</p>
       <p style="font-size:4px;white-space: nowrap;">631-926-5806</p>
       <p style="font-size:4px;white-space: nowrap;">========================================</p>
       <p style="font-size:4px;white-space: nowrap;">${item.name}</p>
       <p style="font-size:4px; white-space: nowrap;">
       ${customerInfo.addressLine} ${customerInfo.addressLine2} ${customerInfo.city}, ${customerInfo.state} ${customerInfo.zip5}
     </p>
     <p style="font-size:4px;white-space: nowrap;margin-bottom:5px;">Invoice # ${invoice || ""}</p>
       <p style="font-size:4px;white-space: nowrap;">Items</p>
       <ul>
        ${productList.join(' ')}
       </ul>  
       <p style="font-size:4px;white-space: nowrap;">========================================</p>
       <p style="font-size:4px;white-space: nowrap;"><span style="margin-right: 2px;">ORDER</span> <span style="margin-right: 2px;">TOTAL</span>${item.originalPaymentTotal}</p>
       <p style="font-size:4px;white-space: nowrap;"><span style="margin-right: 2px;">DUE</span><span style="margin-right: 2px;">TODAY</span> $${item.originalPaymentTotal}</p>
       <p style="font-size:4px;white-space: nowrap;">${item.dataItemJSON.paymentMethod || ''}</p>
       <p style="font-size:4px;white-space: nowrap;">Delivery Date ${item.dataItemJSON.deliveryDate}</p>
    </div>
  `
    doc.html(content, {
      callback: (doc) => {
        // Save the doc or display it in a new window
        doc.save(`${item.name} (Receipt).pdf`);
      }
    });
  }

  render() {

    return (



      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginBottom: '300px' }}>
        <p style={{ margin: '20px', fontSize: '30px', fontWeight: 'bold', }}>Edit Order Info</p>



        <form noValidate autoComplete="off">

          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>


            <div style={{ width: '600px', marginTop: '20px' }}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <label style={{ width: '250px' }} class="label">Customer</label><p style={{ marginLeft: '10px' }}>{this.state.item.name}</p>
              </div>
              <div style={{ marginTop: '10px', display: 'flex', flexDirection: 'row' }}>
                <label style={{ width: '250px' }} class="label">Order ID</label><p style={{ marginLeft: '10px' }}>{this.state.item.key}</p>
              </div>
              <div style={{ marginTop: '10px', display: 'flex', flexDirection: 'row' }}>
                <label style={{ width: '250px' }} class="label">Total</label><p style={{ marginLeft: '10px' }}>${this.state.item.originalPaymentTotal}</p>
              </div>
            </div>


            {(this.state.status.toString().toLowerCase() === "confirmed") &&
              <div style={{ display: 'flex', width: '600px', marginTop: '10px', backgroundColor: '#fff', flexDirection: 'row', justifyContent: 'flex-start', borderRadius: '5px' }}>
                <label style={{ marginRight: '10px', width: '250px' }} class="label">Delivery Date</label>

                <DatePicker
                  className="input is-small"
                  style={{ marginLeft: '10px' }}
                  selected={this.state.selectedDate}
                  onChange={this.handleDateChange}
                //style={{backgroundColor:'#eee', fontSize:'20px'}}
                //value={this.state.dateFormatted}
                //placeholderText={this.state.dateFormatted}
                />

              </div>
            }
            {(this.state.status.toString().toLowerCase() !== "confirmed") &&
              <div style={{ display: 'flex', width: '600px', marginTop: '10px', backgroundColor: '#fff', flexDirection: 'row', justifyContent: 'flex-start', borderRadius: '5px' }}>
                <label style={{ marginRight: '10px', width: '250px' }} class="label">Delivery Date</label>
                <p style={{ marginRight: '10px' }}>{this.state.selectedDateFormatted}</p>

              </div>
            }

            {(this.state.status.toString().toLowerCase() === "confirmed") &&
              <div style={{ display: 'flex', flexDirection: 'row', width: '600px', marginTop: '10px' }}>
                <label style={{ marginRight: '10px', width: '250px' }} class="label">Assigned Route</label>

                <div class="control">
                  <div class="select">
                    <select className="input is-small" onChange={this.handleSelectChange} value={this.state.routeName}>
                      {this.state.data.map(i =>

                        <option>{i.name}</option>
                      )}

                    </select>
                  </div>
                </div>
              </div>
            }

            {(this.state.status.toString().toLowerCase() !== "confirmed") &&
              <div style={{ display: 'flex', flexDirection: 'row', width: '600px', marginTop: '10px' }}>
                <label style={{ marginRight: '10px', width: '250px' }} class="label">Assigned Route</label>
                <p style={{ marginRight: '10px' }}>{this.state.routeName}</p>

              </div>
            }

            <div style={{ display: 'flex', flexDirection: 'row', width: '600px', marginTop: '10px' }}>
              <label style={{ marginRight: '10px', width: '250px' }} class="label">Status:</label>
              <p style={{ marginRight: '10px' }}>{this.state.status}</p>
            </div>


          </div>


        </form>


        <div style={{ width: '600px', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
          {(this.state.status.toString().toLowerCase() === "confirmed") &&
            <button onClick={() => this.updateOrder()} className={"button"} style={{ color: 'black', backgroundColor: '#C4EEDE', width: '150px', marginRight: 10 }}>
              Update
            </button>
          }
          <button onClick={() => this.printReceipt()} className={"button"} style={{ color: 'black', backgroundColor: '#C4EEDE', width: '150px' }}>
            Print Receipt
          </button>
        </div>





      </div>


    );
  }

  handleStatusChange = option => {
    this.setState({ status: option.target.value });
  }

  handleSelectChange = option => {
    this.setState({ routeName: option.target.value });
    console.log("option " + option.target.value);
    //this.setState({routeKey:option.target.key});
  }

  handleDateChange = date => {


    var today = date;
    console.log("date " + date);
    this.setState({ selectedDate: today });

    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    var dateValue = yyyy.toString() + mm.toString() + dd.toString();

    var dateToday = mm.toString() + '/' + dd.toString() + '/' + yyyy.toString();

    this.setState({ selectedDateValue: dateValue });
    this.setState({ selectedDateFormatted: dateToday });

    //this.getOrderData(dateToday);

  };


}

export default OrderAdminItem;



// <TextField id="standard-secondary" label="Standard secondary" color="secondary" />
// <TextField
//   id="filled-secondary"
//   label="Filled secondary"
//   variant="filled"
//   color="secondary"
// />


/*

<div class="control">
  <div class="select">
    <select className="input is-small" onChange={this.handleStatusChange} value={this.state.status}>

      <option>Under Review</option>
      <option>Confirmed</option>
      <option>Complete</option>
      <option>Rejected</option>


    </select>
  </div>
</div>

*/
