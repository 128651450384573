import React from "react";
import Section from "./../Section";
import SectionHeader from "./../SectionHeader";
import Faq from "./../Faq";
import "./styles.scss";

function FaqSection(props) {
  return (
    <Section color={props.color} size={props.size}>
      <div className="container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          centered={true}
          size={3}
        />
        <Faq
          items={[
            {
              question: "How long does Synk take to set up?",
              answer:
                "Set up only takes minutes and we will load all your customer, product, and route data in the system for you. There is little to no effort required on your part."
            },
            {
              question: "Is there a sign up fee?",
              answer:
                "There are no sign up fees or commitments/contracts. You can cancel any month which holds us accountable to making a system you love using."
            },
            {
              question: "Can my company still accept orders over the phone?",
              answer:
                "Yes, we have tools for phone and field sales so you can improve those existing channels. You can accept orders the same way you do currently with the option of allowing your customers to order through their mobile devices. Customers ordering through mobile have been shown to increase their order size significantly due to added convenience, automated reminders, and the ability to discover the full catalog."
            },
            {
              question: "Does Synk work on any device?",
              answer:
                "Yes, Synk is available through the Web, Android and iOS."
            },
          ]}
        />
      </div>
    </Section>
  );
}

export default FaqSection;
