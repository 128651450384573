import React from "react";
import ContentSection from "./../../components/ContentSection";
import FeaturesSectionFull from "./../../components/FeaturesSectionFull";
import "./styles.scss";
// import CSVReader from 'react-csv-reader';
// import Dropzone from 'react-dropzone';
import MdSearch from 'react-ionicons/lib/MdSearch';
//import TextField from '@material-ui/core/TextField';
//import Button from '@material-ui/core/Button';

//import { CSVLink, CSVDownload } from "react-csv";
import firebase from 'firebase';
// import {
//   BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
// } from 'recharts';
import {
  PieChart, Pie, Sector, Cell,
} from 'recharts';
import {
  ScatterChart, Scatter, XAxis, YAxis, ZAxis, Tooltip,
  Legend,
} from 'recharts';


const data01 = [
  { hour: '12a', index: 1, value: 170 },
  { hour: '1a', index: 1, value: 180 },
  { hour: '2a', index: 1, value: 150 },
  { hour: '3a', index: 1, value: 120 },
  { hour: '4a', index: 1, value: 200 },
  { hour: '5a', index: 1, value: 300 },
  { hour: '6a', index: 1, value: 400 },
  { hour: '7a', index: 1, value: 200 },
  { hour: '8a', index: 1, value: 100 },
  { hour: '9a', index: 1, value: 150 },
  { hour: '10a', index: 1, value: 160 },
  { hour: '11a', index: 1, value: 170 },
  { hour: '12a', index: 1, value: 180 },
  { hour: '1p', index: 1, value: 144 },
  { hour: '2p', index: 1, value: 166 },
  { hour: '3p', index: 1, value: 145 },
  { hour: '4p', index: 1, value: 150 },
  { hour: '5p', index: 1, value: 170 },
  { hour: '6p', index: 1, value: 180 },
  { hour: '7p', index: 1, value: 165 },
  { hour: '8p', index: 1, value: 130 },
  { hour: '9p', index: 1, value: 140 },
  { hour: '10p', index: 1, value: 170 },
  { hour: '11p', index: 1, value: 180 },
];

const data02 = [
  { hour: '12a', index: 1, value: 160 },
  { hour: '1a', index: 1, value: 180 },
  { hour: '2a', index: 1, value: 150 },
  { hour: '3a', index: 1, value: 120 },
  { hour: '4a', index: 1, value: 200 },
  { hour: '5a', index: 1, value: 300 },
  { hour: '6a', index: 1, value: 100 },
  { hour: '7a', index: 1, value: 200 },
  { hour: '8a', index: 1, value: 100 },
  { hour: '9a', index: 1, value: 150 },
  { hour: '10a', index: 1, value: 160 },
  { hour: '11a', index: 1, value: 160 },
  { hour: '12a', index: 1, value: 180 },
  { hour: '1p', index: 1, value: 144 },
  { hour: '2p', index: 1, value: 166 },
  { hour: '3p', index: 1, value: 145 },
  { hour: '4p', index: 1, value: 150 },
  { hour: '5p', index: 1, value: 160 },
  { hour: '6p', index: 1, value: 180 },
  { hour: '7p', index: 1, value: 165 },
  { hour: '8p', index: 1, value: 130 },
  { hour: '9p', index: 1, value: 140 },
  { hour: '10p', index: 1, value: 160 },
  { hour: '11p', index: 1, value: 180 },
];

const parseDomain = () => [
  0,
  Math.max(
    Math.max.apply(null, data01.map(entry => entry.value)),
    Math.max.apply(null, data02.map(entry => entry.value))
  ),
];


class DailyMetrics extends React.Component {

  constructor(props){
    super(props);


    this.state = {
      errorMessage:'',
      searchLimitValue:25,
      dateValue:'',
      dateFormatted:'',
      companyId:'',
      searchTerm:'',
      query: "",
      data: [],
      filteredData: [],
      totalData:[],
      name:'',
      phone:'',
      group:'',
      noOrderCount:'',
      orderCountTotal:'',
      dailySales:0,
      dailySalesAvgOrderSize:0,
      orderDataChart:[],
    };
  }


  componentDidMount(){


    var today = new Date();
    var start = new Date();
    start.setHours(0,0,0,0);

    var end = new Date();
    end.setHours(23,59,59,999);

    var todayStartValue = Math.floor(start / 1000);
    var todayEndValue = Math.floor(end / 1000);


    var dd = today.getDate();
    var mm = today.getMonth()+1;
    var yyyy = today.getFullYear();
    if(dd<10){
        dd='0'+dd;
    }
    if(mm<10){
        mm='0'+mm;
    }
    var dateValue = yyyy.toString() + mm.toString() + dd.toString();
    var dateVal = yyyy.toString() + mm.toString();
    var dateToday = mm.toString() + '/' + dd.toString() + '/' + yyyy.toString();

    this.setState({dateValue:dateValue});
    this.setState({dateFormatted:dateToday});


    var setUser = '';
    firebase.auth().onAuthStateChanged((user) => {
      if (user){
        this.setState({isSignedIn:true});
        this.setState({email:user.email});

        this.getCompanyData()
        console.log(user);
      } else {
        this.setState({isSignedIn:false});

      }
    });

  }


  getCompanyData(){

    var user = firebase.auth().currentUser;
    console.log("OPEN USER " + user);

    if (user){
    firebase.database().ref('users/' + user.uid).once('value', (dataSnapshot) => {
        console.log("datasnap " + JSON.stringify(dataSnapshot));
        if (dataSnapshot.val().companyId!==undefined){
          this.setState({companyId: dataSnapshot.val().companyId});
        }
    }).then(() => {
        var orderDataChart = [];
//         const data = [
//   { name: 'Group A', value: 400 }, { name: 'Group B', value: 300 },
//   { name: 'Group C', value: 300 }, { name: 'Group D', value: 200 },
//   { name: 'Group E', value: 278 }, { name: 'Group F', value: 189 },
// ];

        if (this.state.companyId!==undefined && this.state.companyId.trim()!==""){
          console.log("this.state.companyId " + this.state.companyId);
          var data = [];
          // orderDataChart.push({name:'Orders', value:88 });
          // this.setState({orderDataChart:orderDataChart});

            firebase.database().ref('company/' + this.state.companyId + '/companyDailyMetrics/' + this.state.dateValue +'/noOrderCount').once('value', (snap) => {
              this.setState({noOrderCount:snap.numChildren()});

            }).then(() => {
              firebase.database().ref('company/' + this.state.companyId + '/companyDailyMetrics/' + this.state.dateValue +'/orderCountTotal').once('value', (snap) => {
                  var dailySales = 0;
                  this.setState({orderCountTotal:snap.numChildren()});


                  snap.forEach((sale) => {
                    dailySales += parseFloat(sale.val().total);
                    console.log("daily sales " + dailySales);
                  });

                  this.setState({dailySales:dailySales});

                  var dailySalesAvgOrderSize = 0 ;
                  if (snap.numChildren()>0){
                    dailySalesAvgOrderSize = dailySales / parseInt(snap.numChildren());
                  }

                  console.log("avg daily sales " + dailySales + '  ' + dailySalesAvgOrderSize);
                  this.setState({dailySalesAvgOrderSize:dailySalesAvgOrderSize});
              }).then(() => {
                orderDataChart.push({name:'Missed', value:this.state.noOrderCount, color:'green'}, {name:'Ordered', value:this.state.orderCountTotal});
                this.setState({orderDataChart:orderDataChart});
              });
            });





        }


      });
    }
  }

  handleSelectChange = option => {
      this.setState({group:option.target.value})
  }

  handleNameChange = event => {

     const name = event.target.value;
     this.setState({name:name});

   };

   handlePhoneChange = event => {
     // var subValue = event.target.value.replace('-','');
     // subValue = subValue.replace('-','');
     var subValue = event.target.value.split('-').join('');
     var newPosition = event.target.value.length-1;
     var nextPosition = event.target.value.length;
     var newValue = event.target.value.substring(newPosition, nextPosition);
     console.log("newPosition " + newPosition);
     console.log("nextPosition " + nextPosition);
     console.log("newValue " + newValue);
     console.log("this.state.phone.length " + this.state.phone.length);
     console.log("event.target.value.length " + event.target.value.length);
     //console.log("eval" + subValue);
      if (this.state.phone.length > event.target.value.length){
        this.setState({phone:event.target.value});
      } else if (parseInt(newValue)>0) {
        console.log("xxxevent.target.value.length " + event.target.value.length);

        if (subValue.length<=10){
          var phone = event.target.value;
          if (subValue.length===3){
            phone += '-'
          }
          if (subValue.length===6){
            phone += '-'
          }
          console.log("xxxevent.target.value.length " + event.target.value.length);

          this.setState({phone:phone});
        }
      }


    };

    updateUser(){
      alert("Feature is still in progress.");
    }

    renderTooltip = (props) => {
      const { active, payload } = props;

      if (active && payload && payload.length) {
        const data = payload[0] && payload[0].payload;

        return (
          <div style={{
            backgroundColor: '#fff', border: '1px solid #999', margin: 0, padding: 10,
          }}
          >
            <p>{data.hour}</p>
            <p>
              <span>value: </span>
              {data.value}
            </p>
          </div>
        );
      }

      return null;
    }


  render(){

    const domain = parseDomain();
    const range = [16, 225];

    return (



        <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', marginBottom:'300px'}}>


                  <p style={{margin:'20px', fontSize:'30px', fontWeight:'bold', }}></p>


                          <div class="tile is-ancestor">
                            <div class="tile is-vertical is-8">
                              <div class="tile">
                                <div class="tile is-parent is-vertical">
                                  <article class="tile is-child notification is-primary">
                                    <p class="title">Sales Review</p>
                                    <p class="subtitle"></p>
                                    <div style={{display:'flex', flexDirection:'row'}}>
                                    <label style={{color:'#fff', fontWeight:'bold'}}>Daily Sales</label><p style={{marginLeft:'10px', fontWeight:'bold'}}>${parseFloat(this.state.dailySales).toFixed(2)}</p>
                                    </div>
                                    <div style={{display:'flex', flexDirection:'row'}}>
                                    <label  style={{color:'#fff', fontWeight:'bold'}}>Avg. Order</label><p style={{marginLeft:'10px', fontWeight:'bold'}}>${parseFloat(this.state.dailySalesAvgOrderSize).toFixed(2)}</p>
                                    </div>
                                  </article>
                                  <article class="tile is-child notification is-warning">
                                    <p class="title">App Metrics</p>
                                    <p class="subtitle"></p>
                                  </article>
                                </div>
                                <div class="tile is-parent">
                                  <article class="tile is-child notification is-info">
                                    <p class="title">Engagement</p>
                                    <p class="subtitle"></p>

                                  </article>
                                </div>
                              </div>
                              <div class="tile is-parent">
                                <article style={{backgroundColor:'#000'}} class="tile is-child notification is-danger">
                                  <p class="title">Order Success</p>
                                  <p class="subtitle"></p>

                                  <div class="content" >
                                    <div style={{display:'flex', flexDirection:'row'}}>
                                      <label style={{color:'#fff', fontWeight:'bold'}}>Order Count</label><p style={{marginLeft:'10px'}}>{this.state.orderCountTotal}</p>
                                    </div>
                                    <div style={{display:'flex', flexDirection:'row'}}>
                                    <label class="label" style={{color:'#fff', fontWeight:'bold'}} >No Orders</label><p style={{marginLeft:'10px'}}>{this.state.noOrderCount}</p>
                                    </div>


                                  </div>
                                </article>
                              </div>
                            </div>
                            <div  class="tile is-parent" >
                              <article class="tile is-child notification is-success" style={{backgroundColor:'#CEE'}}>
                                <div class="content">
                                  <p class="title" style={{color:'black'}}>Employee Activity</p>
                                  <p class="subtitle" style={{color:'black'}}></p>
                                  <div class="content">

                                  </div>
                                </div>
                              </article>
                            </div>
                          </div>



          <form noValidate autoComplete="off">
          <div style={{width:'600px', borderWidth:0, borderColor:'black', borderStyle:'solid', display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'flex-start'}}>
          <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>


          <div style={{width:'200px', marginTop:'20px'}}>






          </div>

            </div>


            </div>
          </form>



          <div>

          </div>

    </div>


    );
  }

}

export default DailyMetrics;



// <TextField id="standard-secondary" label="Standard secondary" color="secondary" />
// <TextField
//   id="filled-secondary"
//   label="Filled secondary"
//   variant="filled"
//   color="secondary"
// />

// <PieChart style={{ borderWidth:0, margin:'10px', borderColor:'black', alignItems:'center', borderStyle:'solid'}} width={300} height={200}>
//   <Pie dataKey="value" startAngle={180} endAngle={0} data={this.state.orderDataChart} cx={200} cy={200} outerRadius={80} fill="#8884d8" label />
// </PieChart>


/*
<ScatterChart
  width={800}
  height={60}
  margin={{
    top: 10, right: 0, bottom: 0, left: 0,
  }}
>
  <XAxis type="category" dataKey="hour" interval={0} tick={{ fontSize: 0 }} tickLine={{ transform: 'translate(0, -6)' }} />
  <YAxis type="number" dataKey="index" name="sunday" height={10} width={80} tick={false} tickLine={false} axisLine={false} label={{ value: 'Sunday', position: 'insideRight' }} />
  <ZAxis type="number" dataKey="value" domain={domain} range={range} />
  <Tooltip cursor={{ strokeDasharray: '3 3' }} wrapperStyle={{ zIndex: 100 }} content={this.renderTooltip} />
  <Scatter data={data01} fill="#8884d8" />
</ScatterChart>

<ScatterChart
  width={800}
  height={60}
  margin={{
    top: 10, right: 0, bottom: 0, left: 0,
  }}
>
  <XAxis type="category" dataKey="hour" name="hour" interval={0} tick={{ fontSize: 0 }} tickLine={{ transform: 'translate(0, -6)' }} />
  <YAxis type="number" dataKey="index" height={10} width={80} tick={false} tickLine={false} axisLine={false} label={{ value: 'Monday', position: 'insideRight' }} />
  <ZAxis type="number" dataKey="value" domain={domain} range={range} />
  <Tooltip cursor={{ strokeDasharray: '3 3' }} wrapperStyle={{ zIndex: 100 }} content={this.renderTooltip} />
  <Scatter data={data02} fill="#8884d8" />
</ScatterChart>

<ScatterChart
  width={800}
  height={60}
  margin={{
    top: 10, right: 0, bottom: 0, left: 0,
  }}
>
  <XAxis type="category" dataKey="hour" name="hour" interval={0} tick={{ fontSize: 0 }} tickLine={{ transform: 'translate(0, -6)' }} />
  <YAxis type="number" dataKey="index" height={10} width={80} tick={false} tickLine={false} axisLine={false} label={{ value: 'Tuesday', position: 'insideRight' }} />
  <ZAxis type="number" dataKey="value" domain={domain} range={range} />
  <Tooltip cursor={{ strokeDasharray: '3 3' }} wrapperStyle={{ zIndex: 100 }} content={this.renderTooltip} />
  <Scatter data={data01} fill="#8884d8" />
</ScatterChart>

<ScatterChart
  width={800}
  height={60}
  margin={{
    top: 10, right: 0, bottom: 0, left: 0,
  }}
>
  <XAxis type="category" dataKey="hour" name="hour" interval={0} tick={{ fontSize: 0 }} tickLine={{ transform: 'translate(0, -6)' }} />
  <YAxis type="number" dataKey="index" height={10} width={80} tick={false} tickLine={false} axisLine={false} label={{ value: 'Wednesday', position: 'insideRight' }} />
  <ZAxis type="number" dataKey="value" domain={domain} range={range} />
  <Tooltip cursor={{ strokeDasharray: '3 3' }} wrapperStyle={{ zIndex: 100 }} content={this.renderTooltip} />
  <Scatter data={data02} fill="#8884d8" />
</ScatterChart>

<ScatterChart
  width={800}
  height={60}
  margin={{
    top: 10, right: 0, bottom: 0, left: 0,
  }}
>
  <XAxis type="category" dataKey="hour" name="hour" interval={0} tick={{ fontSize: 0 }} tickLine={{ transform: 'translate(0, -6)' }} />
  <YAxis type="number" dataKey="index" height={10} width={80} tick={false} tickLine={false} axisLine={false} label={{ value: 'Thursday', position: 'insideRight' }} />
  <ZAxis type="number" dataKey="value" domain={domain} range={range} />
  <Tooltip cursor={{ strokeDasharray: '3 3' }} wrapperStyle={{ zIndex: 100 }} content={this.renderTooltip} />
  <Scatter data={data01} fill="#8884d8" />
</ScatterChart>

<ScatterChart
  width={800}
  height={60}
  margin={{
    top: 10, right: 0, bottom: 0, left: 0,
  }}
>
  <XAxis type="category" dataKey="hour" name="hour" interval={0} tick={{ fontSize: 0 }} tickLine={{ transform: 'translate(0, -6)' }} />
  <YAxis type="number" dataKey="index" height={10} width={80} tick={false} tickLine={false} axisLine={false} label={{ value: 'Friday', position: 'insideRight' }} />
  <ZAxis type="number" dataKey="value" domain={domain} range={range} />
  <Tooltip cursor={{ strokeDasharray: '3 3' }} wrapperStyle={{ zIndex: 100 }} content={this.renderTooltip} />
  <Scatter data={data02} fill="#8884d8" />
</ScatterChart>

<ScatterChart
  width={800}
  height={60}
  margin={{
    top: 10, right: 0, bottom: 0, left: 0,
  }}
>
  <XAxis type="category" dataKey="hour" name="hour" interval={0} tickLine={{ transform: 'translate(0, -6)' }} />
  <YAxis type="number" dataKey="index" height={10} width={80} tick={false} tickLine={false} axisLine={false} label={{ value: 'Saturday', position: 'insideRight' }} />
  <ZAxis type="number" dataKey="value" domain={domain} range={range} />
  <Tooltip cursor={{ strokeDasharray: '3 3' }} wrapperStyle={{ zIndex: 100 }} content={this.renderTooltip} />
  <Scatter data={data01} fill="#8884d8" />
</ScatterChart>
*/
