import React from "react";
import HeroSection from "./../../components/HeroSection";
import ClientsSection from "./../../components/ClientsSection";
import FeaturesSection from "./../../components/FeaturesSection";
import TestimonialsSection from "./../../components/TestimonialsSection";
import NewsletterSection from "./../../components/NewsletterSection";

import { useRouter } from "./../../util/router.js";
import synk_warehouse_lightgreen from "../../assets/synk_warehouse_lightgreen.svg";
import "./styles.scss";

function HomePage(props) {
  const router = useRouter();

  return (
    <>


      <HeroSection
        color="white"
        size="small"
        title="The Future of B2B Sales & Operations"
        subtitle="Try our completely free CRM designed specifically for wholesale distributors. We'll help you build unstoppable relationships with your customers."
        buttonText="Schedule a Demo"
        //image="https://static-2.gumroad.com/res/gumroad/1211634803146/asset_previews/796da8c56361a2976319a2287af73bc4/retina/drawkit-full-stack-man-colour-800px.png"
        //image="https://firebasestorage.googleapis.com/v0/b/osmo-a9de7.appspot.com/o/appGraphics%2Flogistics.svg?alt=media&token=f5873de8-8909-4714-860c-3f93167218c9"
        image={synk_warehouse_lightgreen}
        buttonOnClick={() => {
          //router.push("/pricing");
          window.open("https://www.calendly.com/synk/15min");
        }}
      />


      <ClientsSection color="light" size="normal" title="'Synk gives my company an endless funnel of quality prospects and innovative ways to reach them. I can't believe it's free.'" subtitle="Eddie Zastenik | Beverage Distributor" />
      <FeaturesSection
        color="white"
        size="medium"
        title="Features"
        subtitle=""
      />

      <TestimonialsSection
        color="light"
        size="medium"
        title="Here's what our users are saying"
        subtitle=""
      />
      <NewsletterSection
        color="white"
        size="medium"
        title="Stay in the loop!"
        subtitle="Receive our latest feature updates"
        buttonText="Subscribe"
        inputPlaceholder="Enter your email"
        subscribedMessage="Thank you for subscribing!"
      />
    </>
  );
}

export default HomePage;
