import React from "react";
import ContentSection from "./../../components/ContentSection";
import FeaturesSectionFull from "./../../components/FeaturesSectionFull";
import "./styles.scss";
import { Link } from "./../../util/router.js";
// import CSVReader from 'react-csv-reader';
// import Dropzone from 'react-dropzone';
import MdSearch from 'react-ionicons/lib/MdSearch';
//import { CSVLink, CSVDownload } from "react-csv";
import firebase from 'firebase';
//function CatalogAdmin(props) {
class ApprovalAdmin extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      errorMessage:'',
      searchLimitValue:25,
      dateValue:'',
      dateFormatted:'',
      companyId:'',
      searchTerm:'',
      query: "",
      data: [],
      filteredData: [],
      totalData:[],
    };
  }


  componentDidMount(){

    var today = new Date();
    var start = new Date();
    start.setHours(0,0,0,0);

    var end = new Date();
    end.setHours(23,59,59,999);

    var todayStartValue = Math.floor(start / 1000);
    var todayEndValue = Math.floor(end / 1000);


    var dd = today.getDate();
    var mm = today.getMonth()+1;
    var yyyy = today.getFullYear();
    if(dd<10){
        dd='0'+dd;
    }
    if(mm<10){
        mm='0'+mm;
    }
    var dateValue = yyyy.toString() + mm.toString() + dd.toString();
    var dateVal = yyyy.toString() + mm.toString();
    var dateToday = mm.toString() + '/' + dd.toString() + '/' + yyyy.toString();

    this.setState({dateValue:dateValue});
    this.setState({dateFormatted:dateToday});


    var setUser = '';
    firebase.auth().onAuthStateChanged((user) => {
      if (user){
        this.setState({isSignedIn:true});
        this.setState({email:user.email});

        this.getCompanyData()
        console.log(user);
      } else {
        this.setState({isSignedIn:false});

      }
    });

  }


  getCompanyData(){

    var user = firebase.auth().currentUser;
    console.log("OPEN USER " + user);

    if (user){
    firebase.database().ref('users/' + user.uid).once('value', (dataSnapshot) => {
        console.log("datasnap " + JSON.stringify(dataSnapshot));
        if (dataSnapshot.val().companyId!==undefined){
          this.setState({companyId: dataSnapshot.val().companyId});
        }
    }).then(() => {

        if (this.state.companyId!==undefined && this.state.companyId.trim()!==""){
          console.log("this.state.companyId " + this.state.companyId);
          var data = [];
           firebase.database().ref('company/' + this.state.companyId + '/catalogStaging/').once('value', (snap) => {
             snap.forEach((item) => {

               var uploadedBy = '';
               if (item.hasChild("uploadedBy")){ uploadedBy = item.val().uploadedBy; }

               var timestamp = '';
               if (item.hasChild("timestamp")){ timestamp = item.val().timestamp; }

               var action = '';
               if (item.hasChild("action")){ action = item.val().action; }

               var message = '';
               if (item.hasChild("message")){ message = item.val().message; }

               var catalog = '';
               if (item.hasChild("catalog")){ catalog = item.val().catalog; }

               data.push({
                 key:item.key,
                 email:uploadedBy,
                 timestamp:timestamp,
                 action:action,
                 message:message,
                 catalog:catalog
               });
             });

             this.setState({totalData:data});

             const { query } = this.state;
             const filteredData = data.filter(element => {
               // if (element.email.toLowerCase().includes(query.toLowerCase())){
               //   return element.email.toLowerCase().includes(query.toLowerCase());
               // } else if (element.name.toLowerCase().includes(query.toLowerCase())){
                 return element.email.toLowerCase().includes(query.toLowerCase())
               //}


             });

             this.setState({
               data,
               filteredData
             });

           });
        }
      });
    }
  }

  // search = (searchText) => {
  //   this.setState({searchTerm: searchText});
  //
  //   let filteredData = this.state.totalData.filter(function (item) {
  //     return item.email.toLowerCase().includes(searchText.toLowerCase());
  //   });
  //
  //
  //   console.log("FILTERED DATA " + JSON.stringify(filteredData));
  //   this.setState({filteredData: filteredData});
  //
  //   //this._updateFlatLists(filteredData);
  //
  // }


  handleInputChange = event => {
     const query = event.target.value;

     this.setState(prevState => {
       const filteredData = prevState.data.filter(element => {
         //return element.name.toLowerCase().includes(query.toLowerCase());
         if (element.email.toLowerCase().includes(query.toLowerCase())){
           return element.email.toLowerCase().includes(query.toLowerCase());
         } else if (element.timestamp.toLowerCase().includes(query.toLowerCase())){
           return element.timestamp.toLowerCase().includes(query.toLowerCase())
         }

       });

       return {
         query,
         filteredData
       };
     });
   };

  render(){



    return (



        <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
          <p style={{margin:'20px', fontSize:'30px', fontWeight:'bold', }}>Catalog Approval</p>

          <div  className="searchForm">
             <form  style={{display:'flex',  width:'1200px', marginBottom:'10px', marginTop:'10px', backgroundColor:'#eee', flexDirection:'row', justifyContent:'flex-start', borderRadius:'5px'}}>
              <MdSearch style={{justifyContent:'center', alignSelf:'center', alignItems:'center', margin:'5px'}} fontSize="25px" color="gray" />
               <input
                 id="searchInput"
                 style={{backgroundColor:'#eee', borderWidth:'0px', fontSize:'16px', padding:'5px', width:'1000px'}}
                 autocomplete="off"
                 placeholder="Search"
                 value={this.state.query}
                 onChange={this.handleInputChange}
               />
             </form>

             <div style={{padding:'5px', width:'1200px'}}>
             <div style={{display:'flex', height:'30px', flexDirection:'row', alignItems:'center', marginTop:'20px', textDecoration:'none', backgroundColor:'none', color:'black'}}>
              <p style={{marginLeft:'50px', width:'300px', fontWeight:'bold', color:'black'}}>Description</p><p style={{marginLeft:'50px', width:'200px', fontWeight:'bold', color:'black'}}>Type</p><p style={{marginLeft:'50px', width:'200px', fontWeight:'bold', color:'black'}}>Uploaded By</p><p style={{ marginLeft:'50px', width:'200px', fontWeight:'bold', color:'black'}}>Date</p>
              </div>
              </div>

             <div style={{padding:'5px', width:'1200px'}}>{this.state.filteredData.slice(0, this.state.searchLimitValue).map(i =>
                <Link to={{pathname:"/approvalAdminItem", state:{ item:i } }}><a >
                <div id="userLink" style={{display:'flex',  flexDirection:'column', justifyContent:'flex-start', marginTop:'10px', marginBottom:'10px', textDecoration:'none', backgroundColor:'none', color:'black'}}>
                <div style={{display:'flex', height:'30px', alignItems:'center', flexDirection:'row', marginTop:'5px', marginBottom:'5px', textDecoration:'none', backgroundColor:'none', color:'black'}}>
                  <p style={{marginLeft:'50px', width:'300px'}}>{i.message}</p><p style={{marginLeft:'50px', width:'200px', }}>{i.action}</p><p style={{marginLeft:'50px', width:'200px'}}>{i.email}</p><p style={{marginLeft:'50px', width:'200px'}}>{i.timestamp.substr(0,24)}</p>
                </div>
                </div>
                </a></Link>)}
                </div>

           </div>




        </div>


    );
  }

}

export default ApprovalAdmin;
