import React from "react";
import Navbar from "./../../components/Navbar";
import HomePage from "./../home";
import AboutPage from "./../about";
import TermsPage from "./../terms";
import FaqPage from "./../faq";
import PricingPage from "./../pricing";
import ContactPage from "./../contact";
import DashboardPage from "./../dashboard";
import CustomerAdminPage from "./../customeradmin";
import ApprovalAdminPage from "./../approvaladmin";
import ApprovalAdminItemPage from "./../approvaladminitem";
import DailyMetricsPage from "./../dailymetrics";
import CatalogAdminPage from "./../catalogadmin";
import CatalogAdminItemPage from "./../catalogadminitem";
import CatalogUploadPage from "./../catalogupload";
import OrderAdminPage from "./../orderadmin";
import OrderAdminItemPage from "./../orderadminitem";
import UserAdminPage from "./../useradmin";
import UserAdminItemPage from "./../useradminitem";
import SigninPage from "./../signin";
import SignupPage from "./../signup";
import PostPage from "./../post";
import ForgotpassPage from "./../forgotpass";
import ChangepassPage from "./../changepass";
import { Switch, Route, Router } from "./../../util/router.js";
import Footer from "./../../components/Footer";
import analytics from "./../../util/analytics.js";
import { ProvideAuth } from "./../../util/auth.js";
import "./styles.scss";

function App(props) {
  return (
    <ProvideAuth>
      <Router>
        <>
          <Navbar
            color="white"
            //spaced={true}
            //logo="https://uploads.divjoy.com/logo.svg"
            logo="https://firebasestorage.googleapis.com/v0/b/osmo-a9de7.appspot.com/o/appGraphics%2Flogo5.svg?alt=media&token=0eb78cbd-3a3e-484c-a182-0ac988991c33"
          />

          <Switch>
            <Route exact path="/" component={HomePage} />

            <Route exact path="/about" component={AboutPage} />

            <Route exact path="/faq" component={FaqPage} />

            <Route exact path="/terms" component={TermsPage} />

            <Route exact path="/pricing" component={PricingPage} />

            <Route exact path="/contact" component={ContactPage} />

            <Route exact path="/dashboard" component={DashboardPage} />

            <Route exact path="/approvalAdmin" component={ApprovalAdminPage} />
            <Route exact path="/approvalAdminItem" component={ApprovalAdminItemPage} />

            <Route exact path="/orderAdmin" component={OrderAdminPage} />
            <Route exact path="/orderAdminItem" component={OrderAdminItemPage} />
            <Route exact path="/catalogAdmin" component={CatalogAdminPage} />
            <Route exact path="/catalogAdminItem" component={CatalogAdminItemPage} />

            <Route exact path="/catalogUpload" component={CatalogUploadPage} />
            <Route exact path="/customerAdmin" component={CustomerAdminPage} />
            <Route exact path="/dailyMetrics" component={DailyMetricsPage} />
            <Route exact path="/userAdmin" component={UserAdminPage} />
            <Route exact path="/userAdminItem" component={UserAdminItemPage} />


            <Route exact path="/signin" component={SigninPage} />

            <Route exact path="/signup" component={SignupPage} />

            <Route exact path="/forgotpass" component={ForgotpassPage} />



            <Route
              component={({ location }) => {


                if (location.pathname.substring(1)){

                }

                return (
                  <div
                    style={{
                      padding: "50px",
                      width: "100%",
                      textAlign: "center"
                    }}
                  >


                    The page <code>{location.pathname}</code> could not be
                    found.
                  </div>
                );
              }}
            />
          </Switch>

          <Footer
            color="light"
            size="normal"
            logo="https://uploads.divjoy.com/logo.svg"
            copyright="© 2019 Company"
          />
        </>
      </Router>
    </ProvideAuth>
  );
}

export default App;
//<Route component={PostPage} />
